<template>
    <div class="flex flex-col w-full">
        <v-data-table
            ref="filterTable"
            single-select
            item-key="id"
            :headers="headers"
            :items="tableData"
            :items-per-page.sync="itemsPerPage"
            :footer-props="footerProps"
            class="a-table w-full"
            data-cy="clientList"
            :sort-by.sync="sortBy"
            :sort-desc.sync="sortDesc"
            @click:row="rowClickHandler"
        >
            <template class="" v-slot:item.interactionDate="{ item }">
                {{getFormattedDate(item.interactionDate)}}
            </template>
            <template class="" v-slot:item.interactionTime="{ item }">
                {{item.interactionTime}}
            </template>
            <template class="" v-slot:item.type="{ item }">
                {{getText(item.type, 'types')}}
            </template>
            <template class="" v-slot:item.method="{ item }">
                {{getText(item.method, 'methods')}}
            </template>
            <template class="" v-slot:item.duration="{ item }">
                {{getText(item.duration, 'durations')}}
            </template>
            <template class="" v-slot:item.createdById="{ item }">
                <span class="capitalize">{{getNameById(item.createdById)}}</span>
            </template>
            <template class="" v-slot:item.created="{ item }">
                {{getFormattedDate(item.created)}}
            </template>
            <!-- TODO: when upgrading, need to change below to this: [`item.editRecord`] -->
            <template class="" v-slot:item.editRecord="{ item }">
                <div class="flex items-center">
                    <v-tooltip bottom v-if="item.treatmentEvents && item.treatmentEvents.length">
                        <template v-slot:activator="{ on, attrs }">
                            <div
                                v-bind="attrs" v-on="on"
                                class="w-20 text-right"
                            >{{ item.treatmentEvents.length }}
                                <fa-icon icon="star" class="text-yellow-bright ml-1" />
                            </div>
                        </template>
                        <span>{{ item.treatmentEvents.length }} Treatment Event(s)</span>
                    </v-tooltip>
                    <div v-else class="w-20">&nbsp;</div>
                    <v-tooltip bottom v-if="item.behaviourIncidents && item.behaviourIncidents.length">
                        <template v-slot:activator="{ on, attrs }">
                            <div
                                v-bind="attrs" v-on="on"
                                class="w-20 text-right"
                            >{{ item.behaviourIncidents.length }}
                                <fa-icon icon="exclamation-triangle" class="text-orange ml-1" />
                            </div>
                        </template>
                        <span>{{ item.behaviourIncidents.length }} Behaviour incident(s)</span>
                    </v-tooltip>
                    <div v-else class="w-20">&nbsp;</div>
                    <div class="flex w-full justify-end">
                        <fa-icon icon="chevron-right" class="text-grey-2 mx-2" />
                    </div>
                </div>
            </template>
        </v-data-table>
    </div>
</template>

<script>
import HttpMixin from '@/components/shared/mixins/httpMixin'
import MessageDialog from '@/components/shared/mixins/messageDialog'
import BackendHelpers from '@/components/shared/mixins/backendHelpers'
import StatusBadge from '@/components/partials/StatusBadge'
import { mapState } from 'vuex'
import { format, parseISO } from 'date-fns'
import { ClientHelpers } from '@/components/shared/mixins/clientMixins'

export default {
    name: 'CaseNoteTable',
    mixins: [HttpMixin, MessageDialog, BackendHelpers, ClientHelpers],
    components: {StatusBadge},
    props: {
        tableData: {
            type: Array,
            default: () => []
        },
        searchTerm: {
            type: String,
            default: ''
        },
        filter: {
            type: Object,
            default: () => {}
        },
        summary: {
            type: Boolean,
            default: false
        },
        users: {
            type: Array,
            default: () => []
        }
    },
    data() {
        return {
            emptyText: 'No case notes found for the search criteria',
            accreditationStatus: [
                {value: 'OPEN', label: 'OPEN', text: 'OPEN'},
                {value: 'CLOSED', label: 'CLOSED', text: 'CLOSED'},
            ],
            duration: 3000,
            type: '',
            message: '',
            itemsPerPage: 20,
            footerProps: {
                'items-per-page-options': [10, 20, 30, 40]
            },
            headers: [
                { text: 'Interaction Date', align: 'left', value: 'interactionDate', class: 'interaction-date--header' },
                { text: 'Time', align: 'left', value: 'interactionTime', class: 'interactionTime--header' },
                { text: 'Type', align: 'left', value: 'type', class: 'type--header' },
                { text: 'Method', align: 'left', value: 'method', class: 'method--header' },
                { text: 'Duration', align: 'left', value: 'duration', class: 'duration--header' },
                { text: 'Added by', align: 'left', value: 'createdById', class: 'added-by--header' },
                { text: 'Added date', align: 'left', value: 'created', class: 'added-date--header' },
                { text: '', align: 'left', value: 'editRecord', fixed: true},
            ],
            sortBy: ['interactionDate'],
            sortDesc: [true]
        }
    },
    methods: {
        rowClickHandler(item) {
            this.$root.$emit('create-case-note', item)
        },
        filterChangeHandler(filters) {
            // first we get the key and there should only be one at this stage
            let filter = {...this.filter}, key = Object.keys(filters)[0]
            if (filters[key] && filters[key].length === 0) {
                if (filter.columnFilters[key] != null) {
                    filter.columnFilters[key] = null
                }
            } else {
                filter.columnFilters = {...this.filter.columnFilters, ...filters}
            }
            this.$emit('filter-change', ...[filter, true])
        },
        isRowLocked(row) {
            // if we don't have the user yet then just return false
            if (!this.$auth.user) return true

            return row.status === 'PAYMENT_PENDING' && !this.isAdmin
        },
        rowClassName({row}) {
            if (this.isRowLocked(row)) {
                return 'locked-row'
            }
            return 'cursor-pointer'
        },
        getText(id, listName) {
            const foundObj = this[listName]?.find(t => t.value === id)
            return foundObj ? foundObj.text : ''
        },
        getNameById(id) {
            const user = this.users.find(t => t.userId === id)
            return this.getFullName(user)
        },
        getStatusColour(status) {
            switch(status) {
                case 'OPEN':
                    return 'text-green'
                case 'PENDING':
                    return 'text-yellow'
                default:
                    return 'text-grey'
            }
        },
        getFormattedDate(date) {
            if (!date) return ''
            return format(parseISO(date), 'dd/MM/yyyy')
        }
    },
    computed: {
        ...mapState({
            staticChoices: state => state.app.staticChoices
        }),
        types() {
            return this.staticChoices['caseNote.type'] || []
        },
        methods() {
            return this.staticChoices['caseNote.method'] || []
        },
        durations() {
            return this.staticChoices['caseNote.duration'] || []
        },
        states() {
            let states = this.staticChoices['generic_address.state'] || []
            if (states && states.length > 0) {
                states = states.map(s => {
                    return {
                        label: s.label,
                        value: s.value,
                        text: s.value
                    }
                })
            }
            return states
        },
    },
}
</script>
