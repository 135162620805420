<template>
    <div class="m-auto w-screen-xs h-screen-xxs pt-2 pb-4">
        <h1 class="text-black mb-2 text-xl lg:text-2xl text-center">Invite Young Person</h1>
        <div>
            <div class="text-center text-sm mb-3">
                Would you like to invite this young person to the TSU member portal?
            </div>
            <div class="flex flex-row justify-center py-3">
                <v-form class="a-contact-form w-240" ref="form" v-model="isValid">
                    <v-text-field
                        ref="email"
                        v-model="inviteUserForm.inviteEmail"
                        label="Email"
                    ></v-text-field>
                </v-form>
            </div>
            <div class="flex flex-row justify-center mt-4">
                <v-btn depressed class="mr-4 w-40" @click="cancelHandler">Cancel</v-btn>
                <v-btn
                    depressed
                    :disabled="!isValid || sendingInvite"
                    color="success"
                    class="v-btn--flat inline w-200"
                    @click="submitHandler"
                    >{{ sendInviteBtn }}</v-btn
                >
            </div>
        </div>
    </div>
</template>

<script>
import {ref, computed, watch, onMounted} from '@vue/composition-api'
import {storeToRefs} from 'pinia'
import {useUserClientStore} from '@/stores/useUserClientStore'
import {useModalStore} from '@/stores/useModalStore'
import {userService} from '@/services/user.service'

export default {
    name: 'ModalInviteYoungPersion',
    setup() {
        const isValid = ref(true)
        const email = ref('')
        const sendingInvite = ref(false)

        const userClientStore = useUserClientStore()
        const {inviteUserForm, confirmInvite, currentClient} = storeToRefs(userClientStore)
        const {inviteUser} = userClientStore
        const modalStore = useModalStore()
        const {closeModal} = modalStore
        const {data: componentData} = storeToRefs(modalStore)

        const sendInviteBtn = computed(() => (sendingInvite.value ? 'Sending...' : 'Send Invite'))

        const inviteEmail = computed(
            () => currentClient.value?.user?.email || currentClient.value?.email
        )

        const submitHandler = async () => {
            console.log('Invite sent')
            try {
                sendingInvite.value = true
                // TODO: need to update the clients email
                await userService.updateUserByClientId(currentClient.value.clientId, {
                    email: inviteUserForm.value.inviteEmail
                })
                if (componentData.value?.isInvited) {
                    await userService.reinviteUser(currentClient.value.clientId)
                } else {
                    await userService.inviteUser(currentClient.value.clientId)
                }
                // inviteUserForm.value = ''
                closeModal()
            } catch (error) {
                // TODO: show the error
                console.error('Error inviting user', error)
            } finally {
                sendingInvite.value = false
            }
        }

        const cancelHandler = () => {
            console.log('Invite cancelled')
            closeModal()
        }

        onMounted(() => {
            email.value = inviteEmail.value
        })
        watch(
            inviteEmail,
            (value) => {
                inviteUserForm.value.inviteEmail =
                    currentClient.value?.user?.email || currentClient.value?.email
            },
            {immediate: true}
        )

        return {
            isValid,
            email,
            inviteUserForm,
            sendingInvite,
            sendInviteBtn,
            submitHandler,
            cancelHandler
        }
    }
}
</script>
