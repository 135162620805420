<template>
    <div class="m-auto w-screen-xs p-4 px-20">
        <h1 class="text-black mb-2 text-xl lg:text-2xl text-center">Delete Stuni Workshop</h1>
        <div class="text-black text-sm my-5 m-auto flex flex-row w-240 justify-between">
            <div>{{ workshopDate }}</div>
            <div>{{ workshopTime }}</div>
            <div>{{ workshopType}}</div>
        </div>
        <div class="text-center mb-8">Are you sure you would like to delete this workshop?</div>

        <div class="flex flex-row justify-center mt-4">
            <v-btn
                depressed
                class="mr-4
                w-40"
                data-cy="cancelHandler"
                @click="cancelHandler"
            >Cancel</v-btn>
            <v-btn
                depressed
                color="primary"
                class="v-btn--flat inline w-40"
                :disabled="deletingWorkshop"
                @click="submitHandler"
            >{{ confirmBtnTxt }}</v-btn>
        </div>
    </div>
</template>

<script>
import { ref, onMounted, computed } from '@vue/composition-api'
import MessageDialog from '@/components/shared/mixins/messageDialog'
import { useMessageStore } from '@/stores/useMessageStore'
import { storeToRefs } from 'pinia'
import { useModalStore } from '@/stores/useModalStore'
import { useWorkshopStore } from '@/stores/useWorkshopStore'
import { workshopService } from '@/services/workshop.service'


export default {
    components: {},
    mixins: [MessageDialog],
    name: 'ModalConfirmDeleteWorkshop',
    setup(_, { root }) {
        const $message = root.$message
        const modalStore = useModalStore()
        const { closeModal } = modalStore
        const { data } = storeToRefs(modalStore)
        const workshopStore = useWorkshopStore()
        const { updateWorkshopDeleted } = workshopStore
        const messageStore = useMessageStore()
        const { message, type } = storeToRefs(messageStore)
        const { showMessage, resetMessage } = messageStore
        const deletingWorkshop = ref(false)

        const workshopDate = computed(() => data.value?.workshop?.date)
        const workshopTime = computed(() => data.value?.workshop?.time)
        const workshopType = computed(() => data.value?.workshop?.type)

        const confirmBtnTxt = computed(() => deletingWorkshop.value ? 'Please wait...' : 'Confirm')
        const cancelHandler = () => {
            closeModal()
        }

        const submitHandler = async() => {
            try {
                deletingWorkshop.value = true
                await workshopService.deleteWorkshop(data.value.workshop.workshopId)
                message.value = 'Workshop deleted!'
                type.value = 'success'
                updateWorkshopDeleted(true)
                setTimeout(() => {
                    closeModal()
                }, 1000)
            } catch (error) {
                message.value = 'Delete workshop failed.'
                type.value = 'error'
                updateWorkshopDeleted(false)
            } finally {
                deletingWorkshop.value = false
                showMessage({duration: 5000}, $message)
            }
        }

        onMounted(() => {
            resetMessage()
            // if (workshopDeleted.value) {
            //     updateWorkshopDeleted(false)
            // }
        })

        return {
            workshopDate,
            workshopTime,
            workshopType,
            confirmBtnTxt,
            deletingWorkshop,
            cancelHandler,
            submitHandler
        }
    }
}
</script>
