<template>
    <div>
        <component
            :is="renderForm"
            :model="isValid"
            ref="formInput"
            class="c-editable-cell with-details"
        >
            <EditableDatetimePicker
                ref="datetime"
                dense
                single-line
                class="p-0 m-0"
                :label="label"
                :type="type"
                :format="format"
                v-model="datetime"
                :rules="isRequired ? rules : []"
            />
        </component>
        <a
            v-show="!editActive"
            class="justify-start py-1 m-0 hover:text-grey-1 text-grey-2 leading-normal border border-transparent border-l-0 border-r-0 border-t-0"
            :class="{'w-4/5': !datetime, fieldEmpty: !datetime, requiredField: this.isRequired}"
            @click="cellClickHandler"
        >
            {{ computedDatetime }}
        </a>
    </div>
</template>

<script>
import EditableDatetimePicker from '@/components/partials/EditableDatetimePicker'
import {format, isValid, parse, parseISO} from 'date-fns'

export default {
    name: 'DatetimePickerCell',
    components: {EditableDatetimePicker},
    props: {
        value: {
            type: [Date, String],
            default: () => {}
        },
        defaultDatetime: {
            type: [Date, String],
            default: () => {}
        },
        cellId: {
            type: [Number, String],
            default: null
        },
        editActive: {
            type: Boolean,
            default: null
        },
        isRequired: {
            type: Boolean,
            default: false
        },
        placeholder: {
            type: String,
            default: ''
        },
        hint: {
            type: String,
            default: ''
        },
        showHint: {
            type: Boolean,
            default: false
        },
        label: {
            type: String,
            default: ''
        },
        rules: {
            type: Array,
            default: () => []
        },
        readOnly: {
            type: Boolean,
            default: false
        },
        type: {
            type: String,
            default: 'time'
        },
        format: {
            type: String,
            default: 'HH:mm'
        }
    },
    data() {
        return {
            isValid: true,
            focused: false,
            datetime: null
        }
    },
    mounted() {
        this.datetime = this.value
    },
    methods: {
        cellClickHandler() {
            this.focused = true
            this.$emit('edit-row', this.cellId)
        },
        clearValidate() {
            this.isValid = true
        },
        validate() {
            this.isValid = this.$refs.formInput.validate()
            return this.isValid
        }
    },
    computed: {
        renderForm() {
            if (this.editActive) return 'v-form'
            return ''
        },
        computedDatetime() {
            if (!this.datetime) return null
            const datetime = parse(this.datetime, this.format, new Date())
            return isValid(datetime) ? format(datetime, this.format) : ''
        }
    },
    watch: {
        value() {
            this.datetime = this.value
        },
        datetime() {
            this.$emit('input', this.datetime)
        }
    }
}
</script>
