<template>
    <div class="a-modal__add-case-note m-auto w-full md:max-w-screen-sm lg:max-w-960 xl:max-w-1250">
        <h1 class="text-black mb-2 text-xl lg:text-2xl text-center">Treatment Typee</h1>
        <v-form
            ref="addCaseNoteForm"
            class="px-4 "
            v-model="valid"
        >
            <div class="flex flex-row justify-center mt-4">
                <v-btn
                    depressed
                    class="mr-4 w-40"
                    @click="cancelHandler"
                    data-cy="cancelHandler"
                >Cancel</v-btn>
                <v-btn
                    depressed
                    color="success"
                    @click="submitHandler"
                    class="v-btn--flat inline w-40"
                >Save</v-btn>
            </div>
        </v-form>
    </div>
</template>

<script>
export default {
    name: 'ModalTreatmentType',
    data() {
        return {
            defaultTreatmentType: {},
            valid: true
        }
    },
    methods: {
        cancelHandler() {
            this.$store.commit('MODAL_IS_VISIBLE', false)
        },
        submitHandler() {
            // commit mutation
        },
        save(date) {
            // this.$refs.dateMenu.save(date)
        }
    },
    computed: {
        treatmentTypeForm: {
            get() {
                return this.defaultTreatmentType
            },
            set(treatmentTypeForm) {
                console.log(treatmentTypeForm)
            }
        }
    }
}
</script>

