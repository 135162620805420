const StuniStatus = Object.freeze([
    'PENDING_APPROVAL',
    'INVITED',
    'PENDING_INVITATION',
    'ACTIVE',
    'INACTIVE',
    'DEACTIVATED',
    'REJECTED',
    'BLOCKED'
].reduce((map, status) => {
    map[status] = status
    return map
}, {}))

const StuniAction = Object.freeze([
    'APPROVE_AS_CLIENT',
    'APPROVE_AS_GUEST',
    'REACTIVATE',
    'DEACTIVATE',
    'REINVITE',
    'REJECT',
    'MARK_AS_PENDING_APPROVAL',
    'UPDATE_INFO',
    'UNLOCK',
    'CHANGE_PASSWORD',
    'MANAGE_CLIENT',
    'LINK_TO_STUNI'
].reduce((map, action) => {
    map[action] = action
    return map
}, {}))

const STUNI_STATUS_ACTIONS = [
    [
        StuniStatus.PENDING_APPROVAL,
        [StuniAction.APPROVE_AS_CLIENT, StuniAction.APPROVE_AS_GUEST, StuniAction.REJECT, StuniAction.UPDATE_INFO]
    ],
    [
        StuniStatus.INVITED,
        [StuniAction.REINVITE, StuniAction.MANAGE_CLIENT, StuniAction.UPDATE_INFO]
    ],
    [
        StuniStatus.PENDING_INVITATION,
        [StuniAction.REINVITE, StuniAction.MANAGE_CLIENT, StuniAction.UPDATE_INFO]
    ],
    [
        StuniStatus.ACTIVE,
        [
            StuniAction.DEACTIVATE,
            StuniAction.MANAGE_CLIENT,
            StuniAction.UPDATE_INFO,
            StuniAction.CHANGE_PASSWORD
        ]
    ],
    [
        StuniStatus.INACTIVE,
        [StuniAction.REACTIVATE, StuniAction.MANAGE_CLIENT, StuniAction.UPDATE_INFO]
    ],
    [StuniStatus.DEACTIVATED, [StuniAction.REACTIVATE, StuniAction.UPDATE_INFO]],
    [StuniStatus.REJECTED, [StuniAction.MARK_AS_PENDING_APPROVAL, StuniAction.UPDATE_INFO]],
    [StuniStatus.BLOCKED, [StuniAction.UNLOCK]]
]

const stuniStatusActionsMap = STUNI_STATUS_ACTIONS.reduce((map, [status, actions]) => {
    map[status] = actions
    return map
}, {})

const getStuniValidActions = (status) => {
    if (!status) return []
    
    return stuniStatusActionsMap[status] ?? []
}


const StuniHelper = {
    methods: {
        getStuniValidActions,
        getStatusTitle(status) {
            const statusObj = this.stuniStatuses.find(({value}) => value === status)

            return statusObj ? statusObj.text : ''
        },
        getStatusClass(status) {
            const statusClassObj = this.stuniStatusClasses.find(({value}) => value === status)

            return statusClassObj ? statusClassObj.text : ''
        },
        approveAsClientStuni(userId, action) {
            this.$store.commit('MODAL_IS_VISIBLE', true)
            this.$store.commit('MODAL_DATA', {
                variables: { userId },
                action,
                keepOpen: true
            })
            this.$store.commit('MODAL_COMPONENT_NAME', 'ModalConfirmStuniApproveAsClient')
        },
        approveAsGuestStuni(userId, action) {
            this.$store.commit('MODAL_IS_VISIBLE', true)
            this.$store.commit('MODAL_DATA', {
                variables: { userId },
                action,
                keepOpen: true
            })
            this.$store.commit('MODAL_COMPONENT_NAME', 'ModalConfirmStuniApproveAsGuest')
        },
        rejectStuni(userId, action) {
            this.$store.commit('MODAL_IS_VISIBLE', true)
            this.$store.commit('MODAL_DATA', {
                variables: { userId },
                action,
                keepOpen: true
            })
            this.$store.commit('MODAL_COMPONENT_NAME', 'ModalConfirmStuniReject')
        },
        markAsPendingApprovalStuni(userId, action) {
            this.$store.commit('MODAL_IS_VISIBLE', true)
            this.$store.commit('MODAL_DATA', {
                variables: { userId },
                action,
                keepOpen: true
            })
            this.$store.commit('MODAL_COMPONENT_NAME', 'ModalConfirmStuniMarkAsPendingApproval')
        }
    },
    computed: {
        stuniStatuses() {
            return this.staticChoices['stuni.statuses']
        },
        stuniStatusClasses() {
            return this.staticChoices['stuni.statusClasses']
        },
        stuniRoles() {
            return this.staticChoices['stuni.roles']
        },
        stuniGenders() {
            return this.staticChoices['clientDetail.gender']
        },
        StuniStatus() {
            return StuniStatus
        },
        StuniAction() {
            return StuniAction
        },

    }
}

export {StuniHelper, StuniStatus, StuniAction, getStuniValidActions, stuniStatusActionsMap}
