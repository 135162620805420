<template>
    <div class="m-auto w-screen-sm">
        <h1 class="text-black mb-2 text-xl lg:text-2xl text-center">Add new {{ formSetName }}</h1>
        <v-form ref="newEpisodeForm" class="px-4" v-model="isValid" @submit.prevent="submitHandler">
            <div>
                <EditableDatePicker
                    v-model="dueDate"
                    label="Due Date"
                    min="2010-01-01"
                    :rules="[required]"
                />
            </div>
            <div class="flex flex-row justify-center mt-4">
                <v-btn
                    depressed
                    class="mr-4 w-40"
                    data-cy="cancelHandler"
                    @click="cancelHandler"
                >Cancel</v-btn>
                <v-btn
                    depressed
                    type="submit"
                    color="primary"
                    class="v-btn--flat inline w-40"
                >Add Form</v-btn>
            </div>
        </v-form>
    </div>
</template>

<script>
import {mapState} from 'vuex'
import EditableDatePicker from '@/components/shared/EditableDatePicker'
import {FormRules} from '@/components/shared/mixins/formMixins'
import {format} from 'date-fns'

export default {
    name: 'ModalCloseEpisode',
    components: {EditableDatePicker},
    mixins: [FormRules],
    data() {
        return {
            isValid: true,
            dueDate: format(new Date(), 'yyyy-MM-dd')
        }
    },
    methods: {
        validate() {
            return this.$refs.newEpisodeForm.validate()
        },
        closeModal() {
            this.$store.commit('MODAL_IS_VISIBLE', false)
        },
        cancelHandler() {
            this.closeModal()
        },
        submitHandler() {
            this.isValid = this.validate()
            if (!this.isValid) return

            this.$root.$emit('submit-new-episode-form', {
                episodeId: this.episodeId,
                formSetId: this.formSetId,
                dueDate: this.dueDate,
                formAnswerId: null,
                formTitle: this.formSetName
            })
            this.closeModal()
        }
    },
    computed: {
        ...mapState({
            staticChoices: state => state.app.staticChoices,
            data: state => state.app.modal.componentData
        }),
        episodeId() {
            return this.data?.episodeId
        },
        formSetId() {
            return this.data?.formSetId
        },
        formSetName() {
            return this.data?.formSetName
        }
    }
}
</script>
