import {
    MODAL_IS_VISIBLE,
    MODAL_DATA,
    MODAL_COMPONENT_NAME,
    UPDATE_LOGIN_EMAIL,
    SET_STAFF_TOTAL,
    SET_STAFF_FIRST_VIEW,
    SET_DOCUMENTS_FIRST_VIEW,
    SET_ACCREDITATION_FIRST_VIEW,
    SET_CLIENT_ID,
    SET_ASSIGNED_TO_ME,
    SET_STATIC_OBJECT,
    SET_DOCS_TOTAL,
    SET_LIST_FILTER,
    SET_PERMISSIONS,
    SET_ROUTE_TO,
    SET_ROUTE_FROM,
    SET_SITE_SECTIONS,
    SET_EPISODE_TYPES,
    SET_LOCATIONS,
    SET_LOCATION_ITEMS,
    SET_LOGGED_IN_USER,
    SET_SEARCH_TEXT,
    SET_CURRENT_EPISODE,
    SET_FORM_TO_SAVE,
    SET_ANSWER_SET,
    SET_MESSAGE_RESULT,
    SET_CURRENT_USER,
    SET_FORM_TYPE,
    SET_USER_ID_TO_LINK,
    SET_EPISODE_FORM_TO_UPDATE, SET_EPISODE_FORM_ID_TO_DELETE,
    SET_EPISODE_FORMS_FOR_COPY
} from '@/store/base/mutationTypes'

export default {
    [MODAL_IS_VISIBLE](state, isVisible) {
        state.modal.isVisible = isVisible
    },
    [MODAL_DATA](state, componentData) {
        state.modal.componentData = componentData
    },
    [MODAL_COMPONENT_NAME](state, componentName) {
        state.modal.componentName = componentName
    },
    [UPDATE_LOGIN_EMAIL](state, email) {
        state.loginEmail = email
    },
    [SET_STAFF_TOTAL](state, staffTotal) {
        state.staffTotal = staffTotal
    },
    [SET_STAFF_FIRST_VIEW](state, isFirstView) {
        state.isStaffFirstView = isFirstView
    },
    [SET_DOCUMENTS_FIRST_VIEW](state, isFirstView) {
        state.isDocumentsFirstView = isFirstView
    },
    [SET_ACCREDITATION_FIRST_VIEW](state, isFirstView) {
        state.isAccreditationFirstView = isFirstView
    },
    [SET_CLIENT_ID](state, clientId) {
        state.clientId = clientId
    },
    [SET_ASSIGNED_TO_ME](state, assignedToMe) {
        state.assignedToMe = assignedToMe
    },
    [SET_STATIC_OBJECT](state, staticChoices) {
        state.staticChoices = staticChoices
    },
    [SET_SITE_SECTIONS](state, siteSections) {
        state.siteSections = siteSections
    },
    [SET_EPISODE_TYPES](state, episodeTypes) {
        state.episodeTypes = episodeTypes
    },
    [SET_LOCATIONS](state, locations) {
        state.locations = locations
    },
    [SET_LOCATION_ITEMS](state, locationItems) {
        state.locationItems = locationItems
    },
    [SET_DOCS_TOTAL](state, docsTotal) {
        state.docsTotal = docsTotal
    },
    [SET_LIST_FILTER](state, listFilter) {
        state.listFilter = listFilter
    },
    [SET_PERMISSIONS](state, permissions) {
        state.permissions = permissions
    },
    [SET_ROUTE_TO](state, routeTo) {
        state.routeTo = routeTo
    },
    [SET_ROUTE_FROM](state, routeFrom) {
        state.routeFrom = routeFrom
    },
    [SET_LOGGED_IN_USER](state, user) {
        state.loggedInUser = user
    },
    [SET_SEARCH_TEXT](state, searchText) {
        state.searchText = searchText
    },
    [SET_CURRENT_EPISODE](state, episode) {
        state.currentEpisode = episode
    },
    [SET_FORM_TO_SAVE](state, form) {
        state.formToSave = form
    },
    [SET_ANSWER_SET](state, answerSet) {
        state.answerSet = answerSet
    },
    [SET_MESSAGE_RESULT](state, messageResult) {
        state.messageResult = messageResult
    },
    [SET_CURRENT_USER](state, currentUser) {
        state.currentUser = currentUser
    },
    [SET_FORM_TYPE](state, formType) {
        state.formType = formType
    },
    [SET_USER_ID_TO_LINK](state, userIdToLink) {
        state.userIdToLink = userIdToLink
    },
    [SET_EPISODE_FORM_TO_UPDATE](state, episodeFormToUpdate) {
        state.episodeFormToUpdate = episodeFormToUpdate
    },
    [SET_EPISODE_FORM_ID_TO_DELETE](state, episodeFormIdToDelete) {
        state.episodeFormIdToDelete = episodeFormIdToDelete
    },
    [SET_EPISODE_FORMS_FOR_COPY](state, episodeFormsForCopy) {
        state.episodeFormsForCopy = episodeFormsForCopy
    },
}
