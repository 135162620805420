export const downloadFile = (resp) => {
    const fileUrl = URL.createObjectURL(resp.data)
    const fileLink = document.createElement('a')
    const contentDisposition = resp.headers['content-disposition']
    const defaultFileName = 'report.pdf'
    let filename = ''
    if (contentDisposition) {
        // the filename should be the second argument here, if it's not
        try {
            filename = contentDisposition ? contentDisposition.split('=')[1] : defaultFileName || ''
        } catch (e) {
            console.log('did not return the filename in the content-disposition header')
            filename = defaultFileName || ''
        }
    } else {
        filename = defaultFileName || ''
    }
    fileLink.href = fileUrl
    fileLink.setAttribute('target', '_blank')
    fileLink.setAttribute('download', filename)
    document.body.appendChild(fileLink)
    fileLink.click()
}