import {getInstance} from './index'
import camelcaseKeys from 'camelcase-keys'
import store from '@/store/index'

export const authGuard = async (to, from, next) => {
    const authService = getInstance()

    if (!(await authService.initiateLoginProcess())) {
        return next({
            name: 'network-error',
            params: {
                message: authService.error?.message,
                redirectPath: to
            }
        })
    }

    const fn = async () => {
        // If the user is authenticated, continue with the route
        if (authService.isAuthenticated) {
            await store.dispatch('setPermissions', {vm: authService})

            console.log('======= authService.$can does it exist?', authService.$can)
            // checking to see if user can see this route, if not then we go to the tsu-app page
            if (to.name !== 'tsu-app' && !authService?.$can('view', 'dashboard'))
                return next({name: 'tsu-app'})
            if (
                (to.name === 'user-list' || to.name === 'user-details') &&
                !authService?.$can('view', 'staff_management')
            ) {
                return next({name: 'dashboard'})
            }
            if (to.name === 'clinical' && !authService?.$can('view', 'dasha')) {
                return next({name: 'dashboard'})
            }

            if (to.name !== 'tsu-app') {
                if (!authService.isUserInfoLoaded) {
                    return next({
                        name: 'network-error',
                        params: {
                            message: authService.error?.message,
                            redirectPath: to
                        }
                    })
                }

                // let's check that we have the static choices too
                await store.dispatch('fetchStaticChoices')
                await store.dispatch('fetchLocations')
                await store.dispatch('fetchSiteSections')
                await store.dispatch('fetchEpisodeTypes')
                await store.dispatch('setFormTypes', {tenantId: authService.tenantId})
            }

            return next()
        }

        // Otherwise, log in
        let searchParams = {}
        to.fullPath.replace(/[?&]+([^=&]+)=([^&]*)/gi, (string, key, value) => {
            searchParams[key] = decodeURI(value)
        })
        searchParams = camelcaseKeys(searchParams, {deep: true})

        if (searchParams?.error) {
            console.log(searchParams?.error)

            authService.logout({
                returnTo: window.location.origin
            })
        } else {
            authService.loginWithRedirect({appState: {targetUrl: to.fullPath}})
        }
    }

    // If loading has already finished, check our auth state using `fn()`
    if (!authService.loading) {
        return await fn()
    }

    // Watch for the loading property to change before we check isAuthenticated
    authService.$watch('loading', async (loading) => {
        if (loading === false) {
            return await fn()
        }
    })
}
