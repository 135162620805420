<template>
    <v-app>
        <div v-if="!isInMaintenance">
            <v-banner
                class="z10 bg-red-light"
                single-line
                sticky
                elevation="3"
                transition="slide-y-transition"
                v-if="isNotConnected || connectionIsSlow"
            >
                <fa-icon icon="ban" class="mr-1 group-hover:text-primary-light" />
                {{ bannerText }}
                <template v-slot:actions="{ dismiss }">
                    <v-btn text color="primary" @click="dismiss">Dismiss</v-btn>
                </template>
            </v-banner>
            <div :class="{ hidden: !loading }">
                <Loader class="mt-12 w-20 h-20" />
            </div>
            <BrowserToSmall v-if="!showTsuLinks" :show="showBrowserTooSmall" />
            <div
                v-if="!showTsuLinks && !loading"
                class="hidden"
                :class="{ browserTooSmallOverlay: !showTsuLinks, 'too-small': !showTsuLinks }"
            ></div>
            <NavigationMenu v-if="loggedIn" v-model="drawer" />
            <v-main class="h-full" :class="{ 'too-small': !showTsuLinks }">
                <div class="flex flex-col h-full">
                    <div>
                        <GlobalHeader
                            v-if="loggedIn"
                            :hide-menu="hideMenus"
                            :hide-profile="hideProfile"
                            v-model="drawer"
                            @toggle-drawer="setDrawer"
                        />
                    </div>
                    <router-view />
                    <GlobalFooter v-if="loggedIn" class="mt-auto" />
                </div>
            </v-main>
            <component :is="modalActive" class="absolute" />
        </div>
        <div v-else>
            <router-view />
        </div>
    </v-app>
</template>

<script>
import { ref, onBeforeMount, onBeforeUnmount, computed } from '@vue/composition-api'
import { storeToRefs } from 'pinia'
import { createNamespacedHelpers } from 'vuex-composition-helpers'
import Modal from '@/components/shared/Modal'
import NavigationMenu from '@/components/shared/NavigationMenu'
import GlobalHeader from '@/components/shared/GlobalHeader'
import GlobalFooter from '@/components/shared/GlobalFooter'
import MessageDialog from '@/components/shared/mixins/messageDialog'
import { mapState } from 'vuex'
import { DEBUG, IDLE_TIME, RTT_3G, SHOW_MAINTENANCE } from '@/utils/constants'
import Loader from '@/assets/loader.svg'
import BrowserToSmall from '@/components/shared/BrowserToSmall'
import { useModalStore } from '@/stores/useModalStore'
import { getInstance } from '@/auth'

const authService = getInstance()
const { useGetters } = createNamespacedHelpers('')

export default {
    name: 'App',
    components: {
        BrowserToSmall,
        Modal,
        NavigationMenu,
        GlobalHeader,
        GlobalFooter,
        Loader
    },
    mixins: [MessageDialog],
    data() {
        return {
            loading: true,
            drawer: null,
            message: null,
            type: null,
            submitDebounce: null,
            debounceTimer: 1000,
            role: '',
            detectedActiveTime: IDLE_TIME,
            isInMaintenance: !!SHOW_MAINTENANCE
        }
    },
    created() {
        this.activateActivityTracker()
        setInterval(() => {
            this.detectedActiveTime -= 1000
            if (this.detectedActiveTime < 1) {
                console.log('======== lgging the user out')
                this.inactiveUserAction()
            }
        }, 1000)
    },
    methods: {
        setDrawer(val) {
            this.drawer = val
        },
        activateActivityTracker() {
            // window.addEventListener('mousemove', this.resetUserActivityTimeout)
            window.addEventListener('focus', this.resetUserActivityTimeout)
            window.addEventListener('mouse', this.resetUserActivityTimeout)
            window.addEventListener('touch', this.resetUserActivityTimeout)
            window.addEventListener('inputs', this.resetUserActivityTimeout)
            window.addEventListener('scroll', this.resetUserActivityTimeout)
            window.addEventListener('keydown', this.resetUserActivityTimeout)
            window.addEventListener('resize', this.resetUserActivityTimeout)
        },
        resetUserActivityTimeout() {
            console.log('======== restting the idle timeout')
            this.detectedActiveTime = IDLE_TIME
        },
        inactiveUserAction() {
            this.$auth.logout({
                returnTo: window.location.origin
            })
        }
    },
    computed: {
        ...mapState({
            loggedInUser: (state) => state.app.loggedInUser,
            messageResult: (state) => state.app.messageResult
        }),
        // modalActive() {
        //     return this.$store.state.app.modal.isVisible ? 'Modal' : ''
        // },
        loggedIn() {
            console.log(
                '======== checking if the user is logged in',
                authService?.$can('view', 'dashboard'),
                this.$auth.isLoggedIn()
            )
            return (
                this.$auth.isLoggedIn() ||
                this.$route.name === 'network-error' ||
                this.$route.name === 'tsu-app'
            )
        },
        hideMenus() {
            return this.$route.name === 'network-error' || this.$route.name === 'tsu-app'
        },
        hideProfile() {
            //only hide the profile if we have a student
            return !DEBUG && (!this.loggedInUser?.role || this.$ability.cannot('view', 'dashboard'))
        },
        showTsuLinks() {
            return this.$route.name === 'tsu-app' && this.role === 'student'
        },
        showAllPages() {
            return this.$route.name && this.$route.name !== 'tsu-app' && this.role !== 'student'
        },
        isStudent() {
            return this.role === 'student'
        },
        haveRouteName() {
            return this.$route.name
        },
        showBrowserTooSmall() {
            return this.haveRouteName && this.role && !this.loading
        }
    },
    watch: {
        messageResult() {
            if (this.messageResult && Object.keys(this.messageResult).length) {
                this.message = this.messageResult.message
                this.type = this.messageResult.type
                this.showMessage({ duration: 5000 })
                this.$store.commit('SET_MESSAGE_RESULT', null)
            }
        },
        '$auth.role': {
            handler() {
                if (this.$auth?.role) {
                    this.role = this.$auth?.role
                    this.loading = false
                }
            },
            deep: true
        }
    },
    setup() {
        const isNotConnected = ref(false)
        const connectionIsSlow = ref(false)
        const modalStore = useModalStore()

        const { modalIsVisible } = useGetters(['modalIsVisible'])
        const { isVisible: piniaIsVisible } = storeToRefs(modalStore)
        const connection = ref({ ...navigator.connection })

        const modalActive = computed(() =>
            piniaIsVisible.value || modalIsVisible.value ? 'Modal' : ''
        )
        const bannerText = computed(() => {
            return connectionIsSlow.value
                ? 'Slow internet connection'
                : isNotConnected.value
                ? 'No internet connection'
                : 'You are back online'
        })
        const setIsOnline = () => {
            isNotConnected.value = false
        }
        const setIsOffline = () => {
            isNotConnected.value = true
        }

        const setConnection = (val) => {
            connectionIsSlow.value = val?.target?.rtt > RTT_3G
        }

        onBeforeMount(() => {
            console.log('======== navigator window', navigator.connection, window)
            window.addEventListener('offline', setIsOffline)
            window.addEventListener('online', setIsOnline)
            if (navigator.connection) navigator.connection.addEventListener('change', setConnection)
        })

        onBeforeUnmount(() => {
            window.removeEventListener('offline', setIsOffline)
            window.removeEventListener('online', setIsOnline)
            if (navigator.connection)
                navigator.connection.removeEventListener('change', setConnection)
        })

        return {
            isNotConnected,
            connectionIsSlow,
            bannerText,
            modalActive
        }
    }
}
</script>
