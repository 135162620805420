<template>
    <div class="m-auto w-screen-sm">
        <h1 class="text-black mb-2 text-xl lg:text-2xl text-center">Link young person to Stuni App</h1>
        <div>
            <div class="text-center">
                Are you sure you want to link {{ name }}
            </div>
            <div class="flex flex-row justify-center mt-4">
                <v-btn
                    depressed
                    class="mr-4 w-40"
                    data-cy="cancelHandler"
                    @click="cancel"
                >Cancel</v-btn>
                <v-btn
                    depressed
                    color="primary"
                    class="v-btn--flat inline w-200"
                    :disabled="!user.userId"
                    @click="submit"
                >Link</v-btn>
            </div>
        </div>
    </div>
</template>

<script>
import { computed, ref } from '@vue/composition-api'
import { createNamespacedHelpers } from 'vuex-composition-helpers'
import { cancelHandler, closeModal } from '@/modules/modals'

const { useState } = createNamespacedHelpers('app')
const { useActions } = createNamespacedHelpers('')

export default {
        name: 'ModalConfirmLinkToStuni',
    emits: ['link-student'],
    setup(_, { root, emit }) {
        const store = root.$store
        const { setUserIdToLink } = useActions(['setUserIdToLink'])
        const { modal: {value: { componentData: { user } } } } = useState(['modal'])

        const name = computed(() => {
            return `${user.firstName || ''} ${user.lastName || ''}`
        })

        const cancel = () => {
            cancelHandler(store)
        }

        const submit = () => {
            setUserIdToLink(user.userId)
            closeModal(store)
        }

        return {
            name,
            user,
            cancel,
            submit
        }
    }
}
</script>
