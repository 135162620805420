<template>
    <div class="m-auto w-screen-sm">
        <h1 class="text-black mb-2 text-xl lg:text-2xl text-center">Program Details</h1>
        <v-form
            ref="programDetailForm"
            class="px-4"
            v-model="isValid"
        >
            <div class="w-full">
                <v-select
                    label="Site - Section"
                ></v-select>
            </div>
            <div class="grid grid-cols-7 gap-x-4 mb-0 w-full">
                <div class="col-span-2">
                     <v-menu
                        ref="dateMenu"
                        v-model="dateMenu"
                        :close-on-content-click="false"
                        transition="scale-transition"
                        offset-y
                    >
                        <template v-slot:activator="{ on, attrs }">
                            <v-text-field
                                ref="date"
                                v-model="programDetailForm.date"
                                label="Start Date"
                                readonly
                                v-bind="attrs"
                                v-on="on"
                            ></v-text-field>
                        </template>
                        <v-date-picker
                            color="bg-primary-light"
                            ref="datePicker"
                            v-model="programDetailForm.date"
                            min="2010-01-01"
                            @change="save"
                        ></v-date-picker>
                    </v-menu>
                </div>
                <div class="col-span-2">
                     <v-menu
                        ref="dateMenu"
                        v-model="dateMenu"
                        :close-on-content-click="false"
                        transition="scale-transition"
                        offset-y
                    >
                        <template v-slot:activator="{ on, attrs }">
                            <v-text-field
                                ref="date"
                                v-model="programDetailForm.date"
                                label="End Date"
                                readonly
                                v-bind="attrs"
                                v-on="on"
                            ></v-text-field>
                        </template>
                        <v-date-picker
                            color="bg-primary-light"
                            ref="datePicker"
                            v-model="programDetailForm.date"
                            min="2010-01-01"
                            @change="save"
                        ></v-date-picker>
                    </v-menu>
                </div>
                <div class="col-span-3">
                    <v-select
                        v-model="programDetailForm.outcome"
                        label="Outcome"
                        :items="outcomes"
                    ></v-select>
                </div>
            </div>
            <div class="grid grid-cols-2 gap-x-4 mb-0 w-full">
                <div class="col-span-1">
                    <v-select
                        label="Worker"
                    ></v-select>
                </div>
                <div class="col-span-1">
                    <!--Andrew can u please make this a number input?I couldnt find it from looking at the docs :/ -->
                    <v-text-field
                        class="col-span-3"
                        label="Days Away"
                        :rules="[numbersOnly]"
                        @keydown="numbersOnlyEvent"
                    ></v-text-field>
                </div>
            </div>

            <div class="flex flex-row justify-center mt-4">
                <v-btn depressed class="mr-4 w-40" @click="cancelHandler" data-cy="cancelHandler">Cancel</v-btn>
                <v-btn depressed color="primary" @click="submitHandler"
                       class="v-btn--flat inline bg-primary-lightest w-40">Save
                </v-btn>
            </div>
        </v-form>
    </div>
</template>

<script>
import { mapState } from 'vuex'
import { FormRules, FormEvents } from '@/components/shared/mixins/formMixins'

export default {
    name: 'ModalProgramDetails',
    mixins: [ FormRules, FormEvents ],
    data() {
        return {
            isValid: true,
            dateMenu: false,
            defaultProgramDetail: {},
        }
    },
    methods: {
        cancelHandler() {
            this.$store.commit('MODAL_IS_VISIBLE', false)
        },
        submitHandler() {
            // commit mutation
        },
        save (date) {
            this.$refs.dateMenu.save(date)
        },
    },
    computed: {
        ...mapState({
            staticChoices: state => state.app.staticChoices
        }),
        outcomes() {
            return this.staticChoices['programDetail.outcome'] || []
        },
        methods() {
            return this.staticChoices['caseNote.method'] || []
        },
        programDetailForm: {
            get() {
                return this.defaultProgramDetail
            },
            set(programDetailForm) {
                console.log('programDetailForm', programDetailForm)
            }
        }
    }
}
</script>
