<template>
    <div class="m-auto w-screen-sm">
        <h1 class="text-black mb-2 text-xl lg:text-2xl text-center">Set to Assessed Unsuitable</h1>
        
        <v-form
            ref="unsuitableEpisodeForm"
            class="px-4"
            v-model="isValid"
        >
            <div class="text-center">
                This changes the episode status to <span class="text-yellow-dark font-bold">Assessed Unsuitable</span>.
                <p class="mb-2">Are you sure you want to change the status?</p>
            </div>
            
            <!--I need to check with noffs if itll make the Program End date NULL or not - though think its best if we dont that set in stone logic like that -->

            <div class="flex flex-row justify-center mt-4">
                <v-btn
                    depressed
                    class="mr-4 w-40"
                    data-cy="cancelHandler"
                    @click="cancelHandler"
                >Cancel</v-btn>
                <v-btn
                    depressed
                    color="primary"
                    class="v-btn--flat inline w-200"
                    @click="submitHandler"
                >Yes</v-btn>
            </div>
        </v-form>
    </div>
</template>

<script>
import { mapState } from 'vuex'
import EditableDatePicker from '@/components/shared/EditableDatePicker'
import MessageDialog from '@/components/shared/mixins/messageDialog'
import { FormRules } from '@/components/shared/mixins/formMixins'
import ModalHelper from '@/components/shared/mixins/modalMixin'

export default {
    name: 'ModalAssessedUnsuitable',
    components: { EditableDatePicker },
    mixins: [ ModalHelper, MessageDialog, FormRules ],
    data() {
        return {
            defaultUnsuitableEpisode: {},
            isValid: true,
            date: null,
        }
    },
    methods: {
        validate() {
            return this.$refs.unsuitableEpisodeForm.validate()
        },
        submitHandler() {
            // commit mutation
            this.isValid = this.validate()
            if (this.isValid) {
                this.unsuitableEpisodeForm.status = 'UNSUITABLE'
                this.$root.$emit('submit-assess-episode-unsuitable', { messageText: 'Episode status has been set to ASSESSED UNSUITABLE', episode: { episodeId: this.episode.episodeId, ...this.unsuitableEpisodeForm } })
                this.closeModal()
            }
        },
    },
    computed: {
        ...mapState({
            staticChoices: state => state.app.staticChoices,
            data: state => state.app.modal.componentData
        }),
        unsuitableEpisodeForm: {
            get() {
                return this.defaultUnsuitableEpisode
            },
            set(unsuitableEpisodeForm) {
                console.log(unsuitableEpisodeForm)
            }
        },
        episode() {
            return this.data && this.data.episode ? this.data.episode : {}
        }
    },
}
</script>
