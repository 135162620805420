import { ref, reactive, toRefs } from '@vue/composition-api'
import { defineStore } from 'pinia'

export const useCampusCheckinsStore = defineStore('campusCheckins', () => {
    const state = reactive({
        checkinsDeleted: false
    })

    const updateCampusCheckinsDeleted = (val) => {
        state.checkinsDeleted = val
    }

    return {
        ...toRefs(state),
        updateCampusCheckinsDeleted
    }
})