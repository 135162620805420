const UpdateClient = {
    methods: {
        initComponent() {
            this.$apollo.queries.client.refetch()
        },
        setClientId(clientId) {
            console.log('clientId change to', clientId)
            this.clientId = clientId
            this.$store.commit('SET_CLIENT_ID', this.clientId)
            this.initComponent()
        }
    }
}

const ClientHelpers = {
    methods: {
        getFullName(user) {
            if (!user || (!user.firstName && !user.name)) return ''
            return user && user.firstName ? `${user.firstName} ${user.lastName}` : user.name ? user.name : ''
        },
        getClientName() {
            return this.client ? `${this.client.firstName} ${this.client.lastName}` : ''
        },
        getNameForClient(client) {
            // user might just type in some text for searching, so just return it
            if (typeof client === 'string') return client
            return client ? `${client.firstName} ${client.lastName}` : ''
        },
        getUserIdCompareExp(searchId, loggedInUserId) {
            let searchCompare = null
            let assignedToMeCompare = null
            if (searchId) searchCompare = {clientId: {_eq: searchId}}
            if (this.assignedToMe) assignedToMeCompare = {clientId: {_eq: loggedInUserId}}

            if (searchCompare && assignedToMeCompare) return {_or: [searchCompare, assignedToMeCompare]}
            else if (searchCompare) return searchCompare
            else if (assignedToMeCompare) return assignedToMeCompare

            return {}
        }
    },
    computed: {
        clientName() {
            return this.client ? `${this.client.firstName} ${this.client.lastName}` : ''
        },
        workers() {
            return this.users?.map(user => ({
                value: user.userId,
                text: this.getFullName(user),
                name: user.name,
                abbr: user.abbr,
                locationProgramIds: user.locationPrograms?.length ? user.locationPrograms?.map(({ locationProgram: { locationProgramId }}) => locationProgramId) : []
            }))
        },
        workersByLoggedInUser() {
            if (!this.workers?.length) return []

            if (!this.loggedInUser?.locationProgramIds?.length) return []

            return this.workers.filter(worker =>
                worker.locationProgramIds.some(locationProgramId =>
                    this.loggedInUser.locationProgramIds.includes(locationProgramId)
                )
            )
        },
    }
}

const ContactHelpers = {
    methods: {
        mapPhoneFields(data, keys) {
            // need to move this function to a mixin
            if (!data || !keys)  return

            let key = keys.shift()
            do {
                if (data[key]) {
                    const numberParts = data[key] ? data[key].split(' ') : null
                    data[key] = {
                        countryCode: 'AU',
                        phone: '',
                    }
                    if (numberParts) {
                        data[key] = {
                            ...data[key],
                            ...{ countryCode: numberParts[0], phone: numberParts.slice(1).join(' ') }
                        }
                    }
                } else if(data[key] === '') {
                    data[key] = {
                        countryCode: 'AU',
                        phone: '',
                    }
                }
                key = keys.shift()
            } while (key != null)
        }
    }
}

export {
    UpdateClient,
    ClientHelpers,
    ContactHelpers
}
