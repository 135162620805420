<template>
    <span 
        :class="statusClass" 
        class="text-xs lg:text-xs uppercase px-1 py-1 rounded font-bold"
    >{{ statusName }}</span>
</template>

<script>
export default {
    name: 'StatusBadge',
    props: {
        statusType: {
            type: String,
            default: ''
        },
        statusList: {
            type: Array,
            default: () => []
        }
    },
    computed: {
        statusName() {
            if (this.statusList.length) {
                const status = this.statusList.find(sl => sl.value.toLowerCase() === this.statusType.toLowerCase())
                return status !== undefined && status !== null ? status.label : ''
            } else {
                return this.statusType
            }
        },
        //Episode statuses?
        statusClass() {
            if (this.statusName.toLowerCase() === 'referred' || this.statusName.toLowerCase() === 'open') {
                return ['text-light-blue']
            } else if (this.statusName.toLowerCase() === 'program open' || this.statusName.toLowerCase() === 'approved' || this.statusName.toLowerCase() === 'closed') {
                return ['text-green']
            } else if (this.statusName.toLowerCase() === 'program closed') {
                return ['text-red']
            } else if (this.statusName.toLowerCase() === 'assessed pending') {
                return ['text-orange']
            } else if (this.statusName.toLowerCase() === 'assessed suitable') {
                return ['text-yellow']
            } else if (this.statusName.toLowerCase() === 'assessed unsuitable') {
                return ['text-red']
            } else {
                return []
            }
        }
    }
}
</script>
