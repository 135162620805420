<template>
    <div class="m-auto w-screen-sm">
        <h1 class="text-black mb-2 text-xl lg:text-2xl text-center">Client Picture</h1>
        <v-form
            ref="clientPictureForm"
            class="px-4"
            v-model="clientPictureForm"
        >
            <div>
                <!-- pls only accept image file tpyes (JPG, PNG?)-->
               <v-file-input
                 label="Image File"
                 filled
                 prepend-icon="mdi-camera"
               ></v-file-input>
            </div>
            <div class="flex flex-row justify-center mt-4">
                <v-btn
                    depressed
                    class="mr-4 w-40"
                    @click="cancelHandler"
                    data-cy="cancelHandler"
                >Cancel</v-btn>
                <v-btn
                    depressed
                    color="primary"
                    @click="submitHandler"
                    class="v-btn--flat inline w-40"
                >Upload &amp; Save</v-btn>
            </div>
        </v-form>
    </div>
</template>

<script>
import { mapState } from 'vuex'

export default {
    name: 'ModalClientProfile',
    data() {
        return {
            dateMenu: false,
        }
    },
    methods: {
        cancelHandler() {
            this.$store.commit('MODAL_IS_VISIBLE', false)
        },
        submitHandler() {
            // commit mutation
        },
        save(date) {
            this.$refs.dateMenu.save(date)
        }
    },
    computed: {
        ...mapState({
            staticChoices: state => state.app.staticChoices
        }),
        clientPictureForm() {
            return this.defaultAddCaseNote
        }
    }
}
</script>
