<template>
    <div
        class="a-modal fixed inset-0 flex flex-col z-50 items-center justify-center active"
        :class="additionalClass"
        tabindex="0"
        v-if="isVisible"
    >
        <div
            class="a-modal__content top-0 lg:top-auto absolute bg-white-pure rounded-lg p-6 l-box-shadow"
        >
            <component :is="computedComponentName" class="" />
        </div>

        <div
            class="a-modal__screen-bg w-full h-full inset-0"
            @click="bgClickHandler"
            @keyup.escape="bgClickHandler"
            @keyup.enter="bgClickHandler"
        ></div>
    </div>
</template>

<script>
import {useActions, useGetters} from 'vuex-composition-helpers'
import {computed} from '@vue/composition-api'
import { storeToRefs } from 'pinia'
import {useModalStore} from '@/stores/useModalStore'
import ModalDefault from '@/components/partials/ModalDefault'
import ModalAddCaseNote from '@/components/partials/ModalAddCaseNote'
import ModalProgramDetail from '@/components/partials/ModalProgramDetail'
import ModalCloseEpisode from '@/components/partials/ModalCloseEpisode'
import ModalReopenEpisode from '@/components/partials/ModalReopenEpisode'
import ModalOpenEpisode from '@/components/partials/ModalOpenEpisode'
import ModalLevelDetails from '@/components/partials/ModalLevelDetails'
import ModalNewEpisode from '@/components/partials/ModalNewEpisode'
import ModalTreatmentType from '@/components/partials/ModalTreatmentType'
import ModalConfirmAddClient from '@/components/partials/ModalConfirmAddClient'
import ModalConfirmDeleteDocument from '@/components/partials/ModalConfirmDeleteDocument'
import ModalClientProfile from '@/components/partials/ModalClientProfile'
import ModalSetToReferred from '@/components/partials/ModalSetToReferred'
import ModalAssessedSuitable from '@/components/partials/ModalAssessedSuitable'
import ModalAssessedUnsuitable from '@/components/partials/ModalAssessedUnsuitable'
import ModalAssessedPending from '@/components/partials/ModalAssessedPending'
import ModalNewEpisodeForm from '@/components/partials/ModalNewEpisodeForm'
import ModalInviteUser from '@/components/user-management/ModalInviteUser'
import ModalConfirmUserActivate from '@/components/partials/ModalConfirmUserActivate'
import ModalConfirmUserDeactivate from '@/components/partials/ModalConfirmUserDeactivate'
import ModalConfirmStuniApproveAsClient from '@/components/partials/ModalConfirmStuniApproveAsClient'
import ModalConfirmStuniApproveAsGuest from '@/components/partials/ModalConfirmStuniApproveAsGuest'
import ModalConfirmStuniMarkAsPendingApproval from '@/components/partials/ModalConfirmStuniMarkAsPendingApproval'
import ModalConfirmStuniReject from '@/components/partials/ModalConfirmStuniReject'
import ModalConfirmReinvite from '@/components/partials/ModalConfirmReinvite'
import ModalConfirmLinkToStuni from '@/components/partials/ModalConfirmLinkToStuni'
import ModalPleaseWait from '@/components/partials/ModalPleaseWait'
import ModalDidNotCommence from '@/components/partials/ModalSetToDidNotCommence'
import ModalEditEpisodeForm from '@/components/partials/ModalEditEpisodeForm'
import ModalDeleteEpisodeForm from '@/components/partials/ModalDeleteEpisodeForm'
import ModalMoveOrCopyEpisode from '@/components/partials/ModalMoveOrCopyEpisode'
import ModalShowScore from '@/components/partials/ModalShowScore.vue'
import ModalConfirmDeleteEpisode from '@/components/partials/ModalConfirmDeleteEpisode.vue'
import ModalConfirmDeleteClient from '@/components/partials/ModalConfirmDeleteClient.vue'
import ModalExportCaseNotes from '@/components/partials/ModalExportCaseNotes.vue'
import ModalInviteYoungPerson from '@/components/partials/ModalInviteYoungPerson.vue'
import ModalAddOrUsePoints from '@/components/partials/ModalAddOrUsePoints.vue'
import ModalConfirmDeleteWorkshop from '@/components/partials/ModalConfirmDeleteWorkshop.vue'
import ModalConfirmDeleteCheckin from '@/components/partials/ModalConfirmDeleteCheckin.vue'
import ModalConfirmDeletePoints from '@/components/partials/ModalConfirmDeletePoints.vue'


export default {
    name: 'Modal',
    components: {
        ModalDefault,
        ModalAddCaseNote,
        ModalProgramDetail,
        ModalCloseEpisode,
        ModalOpenEpisode,
        ModalReopenEpisode,
        ModalLevelDetails,
        ModalNewEpisode,
        ModalTreatmentType,
        ModalConfirmAddClient,
        ModalConfirmDeleteDocument,
        ModalClientProfile,
        ModalSetToReferred,
        ModalAssessedSuitable,
        ModalAssessedUnsuitable,
        ModalNewEpisodeForm,
        ModalAssessedPending,
        ModalInviteUser,
        ModalConfirmUserActivate,
        ModalConfirmUserDeactivate,
        ModalConfirmLinkToStuni,
        ModalConfirmStuniApproveAsClient,
        ModalConfirmStuniApproveAsGuest,
        ModalConfirmStuniMarkAsPendingApproval,
        ModalConfirmStuniReject,
        ModalConfirmReinvite,
        ModalPleaseWait,
        ModalDidNotCommence,
        ModalEditEpisodeForm,
        ModalDeleteEpisodeForm,
        ModalMoveOrCopyEpisode,
        ModalShowScore,
        ModalConfirmDeleteEpisode,
        ModalConfirmDeleteClient,
        ModalExportCaseNotes,
        ModalInviteYoungPerson,
        ModalAddOrUsePoints,
        ModalConfirmDeleteWorkshop,
        ModalConfirmDeleteCheckin,
        ModalConfirmDeletePoints
    },
    setup() {
        const modalStore = useModalStore()
        const {closeModal: piniaHideModal} = modalStore
        const { isVisible: piniaIsVisible, componentName: piniaComponentName } = storeToRefs(modalStore)
        const {
            modalIsVisible,
            componentData: data,
            componentName
        } = useGetters(['modalIsVisible', 'componentData', 'componentName'])
        const {closeModal} = useActions(['closeModal'])
        const isVisible = computed(() => piniaIsVisible.value || modalIsVisible.value)
        const additionalClass = computed(() => {
            if ([piniaComponentName.value, componentName.value].indexOf('ModalAgreement') > -1) return 'large-modal'
            return ''
        })

        const computedComponentName = computed(
            () => piniaComponentName.value || componentName.value
        )

        const bgClickHandler = () => {
            if (!data.value?.keepOpen) {
                closeModal()
                piniaHideModal()
            }
        }

        return {
            isVisible,
            data,
            computedComponentName,
            additionalClass,
            bgClickHandler
        }
    }
}
</script>
