<template>
    <div class="profile-display flex flex-row items-center pr-4">
        <el-dropdown
            data-cy="profileDropdown"
            trigger="click"
            @command="handleCommand"
        >
            <span class="el-dropdown-link">
                <i class="text-grey-2 el-icon-arrow-down el-icon--right float-right pt-2"></i>
                <div
                    v-if="!hideName"
                    class="inline-block relative float-right pt-1"
                >
                    <div class="text-sm text-grey-2 capitalize">{{ name }}</div>
                </div>
                <div class="bg-primary-light w-8 h-8 rounded-full mr-3 flex items-center justify-center inline-block relative float-right tablet-hide">
                    <div class="text-center text-white font-display font-bold tracking-wider uppercase text-sm pt-0 pl-0">{{ avatar }}</div>
                </div>
            </span>
            <el-dropdown-menu slot="dropdown" class="profile-display-menu">
                <!-- <el-dropdown-item
                    command="account-settings"
                    data-cy="accountSettingsDropdownItem"
                >
                    <fa-icon icon="cog" class="text-grey-2 mr-2" /><span class="text-grey-2">Account Settings</span>
                </el-dropdown-item> -->
                <el-dropdown-item
                    v-if="false"
                    command="change-password"
                    data-cy="changePasswordDropdownItem"
                >
                    <fa-icon icon="lock" class="text-grey-2 mr-2" /><span class="text-grey-2">Change Password</span>
                </el-dropdown-item>
                <el-dropdown-item
                    command="logout"
                    :divided="false"
                    data-cy="logoutDropdownItem"
                >
                    <fa-icon icon="sign-out-alt" class="text-grey-2 mr-2" /><span class="text-grey-2">Sign out</span>
                </el-dropdown-item>
            </el-dropdown-menu>
        </el-dropdown>
    </div>
</template>

<script>
import ProfileFns from '@/components/shared/mixins/profileFns'
import { mapState } from 'vuex'

export default {
    name: 'ProfileDisplay',
    mixins: [ProfileFns],
    props: {
        hideName: {
            type: Boolean,
            default: false
        }
    },
    data() {
        return {
            user: {},
            backendOffersUrl: 'offer/surveyor/',
            backendAdminUrl: 'admin-mnqa/',
        }
    },
    computed: {
        ...mapState({
            loggedInUser: (state) => state.app.loggedInUser,
        }),
        name() {
            return `${this.loggedInUser.firstName} ${this.loggedInUser.lastName}`
        },
    },
    methods: {
        handleCommand(command) {
            switch (command) {
                case 'account-settings':
                    this.goToAccountSettings()
                    break
                case 'change-password':
                    this.goToChangePassword()
                    break
                case 'logout':
                    this.logoutUser()
                    break
            }
        },
        logoutUser() {
            this.$auth.logout({
                returnTo: window.location.origin
            })
        },
        goToAccountSettings() {
            this.$router.push({name: 'account-settings'})
        },
        goToChangePassword() {
            this.$router.push({name: 'change-password'})
        },
    },
}
</script>
