<template>
    <div class="m-auto w-screen-sm">
        <h1 class="text-black mb-2 text-xl lg:text-2xl text-center">Copy / Move Episode</h1>
        <v-form ref="moveOrCopyEpisodeForm" class="px-4" v-model="isValid">
            <div class="grid grid-cols-2 gap-4 w-full">
                <div class="col-span-1">
                    <h4 class="font-display font-semibold text-left text-lg mb-4">From:</h4>
                    <div class="mb-4">
                        <div class="text-black text-xs uppercase font-bold">Young Person</div>
                        <div>{{ data.name }}</div>
                    </div>
                    <div class="mb-4">
                        <div class="text-black text-xs uppercase font-bold">Site Section</div>
                        <div>{{ currSiteSection.text }}</div>
                    </div>
                    <div class="mb-4">
                        <div class="text-black text-xs uppercase font-bold">Episode</div>
                        <div
                            class="w-full justify-startitems-center text-sm font-normal text-grey-1 text-left"
                        >
                            <div class="mr-2">{{ getDateRange(episode) }}</div>
                            <div class="w-20 uppercase font-bold" :class="getStatusColour(episode)">
                                {{ this.episode.status }}
                            </div>
                            <div class="mr-2">
                                {{ getSiteSection(episode) }}
                            </div>
                            <div>{{ getWorker(episode) }}</div>
                        </div>
                    </div>
                </div>
                <div class="col-span-1">
                    <h4 class="font-display font-semibold text-left text-lg mb-4">To:</h4>
                    <v-autocomplete
                        label="Young Person"
                        v-model="moveOrCopyEpisodeForm.clientId"
                        :items="clients"
                        :rules="[required]"
                        @change="clientChangeHandler"
                    ></v-autocomplete>

                    <v-autocomplete
                        label="Site - Section"
                        v-model="moveOrCopyEpisodeForm.locationProgramId"
                        :items="sortItems(siteSectionsByLocation)"
                        :rules="[required]"
                        @change="locationProgramChangeHandler"
                    ></v-autocomplete>

                    <v-select
                        v-model="moveOrCopyEpisodeForm.type"
                        label="Episode Type"
                        :items="typesByLocationProgram"
                        :rules="[required]"
                    ></v-select>
                </div>
            </div>
            <div class="flex flex-row justify-center mt-4">
                <v-btn depressed class="mr-4 w-40" @click="cancelHandler" data-cy="cancelHandler">
                    Cancel
                </v-btn>
                <v-btn depressed :disabled="copyInProgress" color="primary" class="v-btn--flat inline w-40 mr-4" @click="submitCopyHandler">
                    <fa-icon v-if="copyInProgress" icon="spinner" spin class="mr-2 text-primary-light" />
                    Copy Episode
                </v-btn>
                <v-btn depressed :disabled="moveInProgress" color="primary" class="v-btn--flat inline w-40" @click="submitMoveHandle">
                    <fa-icon v-if="moveInProgress" icon="spinner" spin class="mr-2 text-primary-light" />
                    Move Episode
                </v-btn>
            </div>
        </v-form>
    </div>
</template>

<script>
import {mapState} from 'vuex'
import {FormRules} from '@/components/shared/mixins/formMixins'
import sortItems from '@/components/shared/mixins/sortItems'
import {EpisodeHelpers} from '@/components/shared/mixins/episodeMixins'
import GET_EPISODE_DATA_FOR_CLONING from '@/graphql/queries/getEpisodeDataForCloning.gql'
import INSERT_EPISODE_DATA_BY_CLONING from '@/graphql/mutations/insertEpisodeDataByCloning.gql'
import MOVE_EPISODE_TO_NEW_CLIENT  from '@/graphql/mutations/moveEpisodeToNewClient.gql'
import COPY_FORM_ANSWER_AND_SCORE from '@/graphql/actions/copyFormAnswerAndScore.gql'
import MessageDialog from '@/components/shared/mixins/messageDialog'
import camelcaseKeys from 'camelcase-keys'
import snakecaseKeys from 'snakecase-keys'

export default {
    name: 'ModalMoveOrCopyEpisode',
    mixins: [FormRules, EpisodeHelpers, sortItems, MessageDialog],
    data() {
        return {
            isValid: true,
            episodeData: {},
            message: '',
            type: '',
            copyInProgress: false,
            moveInProgress: false,
            moveEpisodeData: {},
            typesByLocationProgram: []
        }
    },
    apollo: {
        cloningEpisode: {
            query: GET_EPISODE_DATA_FOR_CLONING,
            variables() {
                return {
                    episodeId: Number(this.episode.episodeId)
                }
            },
            skip() {
                if (!this.episode || !this.episode.episodeId) {
                    this.episodeData = {}
                    return true
                }
                return false
            },
            result({ data: { cloningEpisode } }) {
                let convertedEpisodeData = camelcaseKeys(cloningEpisode[0], {deep: true})
                this.episodeData = {...convertedEpisodeData}
                let keys = [
                    'actionPlans',
                    'alerts',
                    'analysisResults',
                    'caseNotes',
                    'documents',
                    'forms',
                    'levelDetails',
                    'medicationDispenseds',
                    'medications',
                    'phoneLogs',
                    'telephoneContacts',
                    'vocationalEds'
                ]
                keys?.forEach((key) => {
                    delete this.episodeData[key]
                    this.episodeData[key] = {}
                    this.episodeData[key]['data'] = []
                    this.episodeData[key]['data'] = [...convertedEpisodeData[key]]
                    this.episodeData[key]['data']?.forEach((element) => {
                        delete element.typename
                    })
                })
            },
            watchLoading (isLoading, countModifier) {
                // this.loading = isLoading
            }
        }
    },
    mounted() {
        this.$apollo.queries.cloningEpisode.refetch()
        this.locationProgramChangeHandler()
    },
    methods: {
        validate() {
            return this.$refs.moveOrCopyEpisodeForm.validate()
        },
        closeModal() {
            this.$store.commit('MODAL_IS_VISIBLE', false)
        },
        cancelHandler() {
            this.closeModal()
        },
        async submitCopyHandler() {
            this.episodeData = {...this.episodeData, ...this.moveOrCopyEpisodeForm}
            let keys = [
                'typename',
                'actionPlans',
                'alerts',
                'analysisResults',
                'caseNotes',
                'documents',
                'levelDetails',
                'medicationDispenseds',
                'medications',
                'phoneLogs',
                'telephoneContacts',
                'vocationalEds',
                'tenantId',
                'endDate'
            ]
            keys.forEach((key) => {
                delete this.episodeData[key]
            })
            this.isValid = this.validate()
            if (this.isValid) {
                try {
                    this.copyInProgress = true
                    // below is the 'formAnswerId and tenantId' to be copied and we get a new one back
                    // we first check if we have episode forms loaded and if the episode itself has any
                    // forms to add the formAnswerId to
                    if (this.episodeForms.length && this.episodeData.forms?.data?.length) {
                        for await (const episodeForm of this.episodeForms) {
                            const { formAnswerId, tenantId } = episodeForm
                            // if formAnswerId is null, we don't need to try and copy it
                            if (!formAnswerId) continue

                            const copyFormAnswerResult = await this.$apollo.mutate({
                                mutation: COPY_FORM_ANSWER_AND_SCORE,
                                variables: { formAnswerId, tenantId }
                            })

                            const formIdx = this.episodeData.forms.data.findIndex((d) => d.formAnswerId === formAnswerId)
                            if (formIdx > -1) {
                                // const { data: { copySurveyAnswerAndScores: { data: episodeFormData } } } = copyFormAnswerResult
                                const episodeFormData = copyFormAnswerResult?.data?.copySurveyAnswerAndScores?.data
                                if (episodeFormData) this.episodeData.forms.data[formIdx].formAnswerId = episodeFormData.form_answer_id
                            }
                        }
                    }

                    const convertedData = snakecaseKeys(this.episodeData, {deep: true})
                    const result = await this.$apollo.mutate({
                        mutation: INSERT_EPISODE_DATA_BY_CLONING,
                        variables: {
                            cloningEpisode: convertedData
                        }
                    })
                    this.message = 'Copy Episode successfully.'
                    this.type = 'success'
                    this.closeModal()
                } catch (error) {
                    console.log('error:', error)
                    this.message = 'Copy Episode failed.'
                    this.type = 'error'
                } finally {
                    this.copyInProgress = false
                    this.showMessage({duration: 5000})
                }
                this.$root.$emit('submit-copy-episode')
            }
        },
        async submitMoveHandle() {
            const data = {...this.moveOrCopyEpisodeForm}
            const convertedData = snakecaseKeys(data, {deep: true})
            this.isValid = this.validate()
            if (this.isValid) {
                try {
                    this.moveInProgress = true
                    const moveEpisodeResult = await this.$apollo.mutate({
                        mutation: MOVE_EPISODE_TO_NEW_CLIENT,
                        variables: {
                            episodeId: Number(this.episode.episodeId),
                            moveEpisode: convertedData
                        }
                    })
                    console.log('result move episode', moveEpisodeResult)
                    this.message = 'Move Episode successfully.'
                    this.type = 'success'
                    this.moveEpisodeData = {...moveEpisodeResult.data.episode}
                    this.closeModal()
                } catch (error) {
                    console.log('error:', error)
                    this.message = 'Move Episode failed.'
                    this.type = 'error'
                } finally {
                    this.moveInProgress = false
                    this.showMessage({duration: 5000})
                }
                this.$root.$emit('submit-move-episode', {
                    messageText: 'Move Episode.',
                    episode: {...this.moveEpisodeData}
                })
            }
        },
        locationProgramChangeHandler() {
            const {types} = this.siteSections.find(
                (siteSection) => this.moveOrCopyEpisodeForm.locationProgramId === siteSection.value
            )
            if (!types) return
            const episodeTypes = this.episodeTypes.filter((episodeType) =>
                types.includes(episodeType.code)
            )
            const checkEpisodeType =  episodeTypes.find(episodeType => episodeType.code === this.moveOrCopyEpisodeForm.type)
            if(!checkEpisodeType) {
                this.moveOrCopyEpisodeForm.type = ''
            }
            this.typesByLocationProgram = episodeTypes.map((episodeType) => {
                return {
                    value: episodeType.code,
                    text: episodeType.name
                }
            })
        },
        clientChangeHandler() {}
    },
    computed: {
        ...mapState({
            locationItems: (state) => state.app.locationItems,
            siteSections: (state) => state.app.siteSections,
            episodeTypes: (state) => state.app.episodeTypes,
            data: (state) => state.app.modal.componentData
        }),
        episode() {
            return this.data?.episode
        },
        clients() {
            return this.data?.clients
        },
        episodeForms() {
            return this.data?.episodeForms
        },
        currSiteSection() {
            const siteSection = this.siteSections.filter(
                (siteSection) => siteSection.value === this.episode.locationProgramId
            )[0]
            return siteSection
        },
        moveOrCopyEpisodeForm: {
            get() {
                return {
                    clientId: this.episode.clientId,
                    locationProgramId: this.episode.locationProgramId,
                    type: this.episode?.type || ''
                }
            },
            set(moveOrCopyEpisodeForm) {
                console.log('moveOrCopyEpisodeForm', moveOrCopyEpisodeForm)
            }
        },
        siteSectionsByLocation() {
            if (!this.siteSections?.length) return []
            return this.siteSections
        }
    }
}
</script>
