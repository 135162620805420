export default {
    methods: {
        goToAdmin() {
            const url = `${process.env.VUE_APP_DJANGO_BASE_URL}/${this.backendAdminUrl || process.env.VUE_APP_DEFAULT_BACKEND_ADMIN_URL}`
            this.goToBackendUrl(url)
            // console.log('go to backend admin url')
        },
        goToBackendUrl(url, openInNewTab) {
            if (openInNewTab) {
                window.open(url, '_blank')
            } else {
                window.location = url
            }
        },
    }
}
