<template>
    <div>
        <component
            :is="renderForm"
            :model="isValid"
            ref="formInput"
            class="c-editable-cell"
            @submit.prevent=""
        >
            <component
                dense
                single-line
                :is="renderEditableComponent"
                v-show="editActive"
                v-model="formInput.inputValue"
                class="p-0 m-0"
                :items="items"
                hide-details
                :rules="isRequired ? [required] : []"
                :label="label"
                :multiple="multiple"
                :small-chips="smallChips"
                @focus="onCellClick"
            >
                <slot v-for="(_, name) in $slots" :name="name" :slot="name" />
                <template v-for="(_, name) in $scopedSlots" :slot="name" slot-scope="slotData">
                    <slot :name="name" v-bind="slotData" />
                </template>
                <template v-slot:selection="{ item, index }" v-if="multiple && showCondensed">
                    <v-chip v-if="index < 4">
                        <span>{{ item.text }}</span>
                    </v-chip>
                    <span v-if="index === 4" class="grey--text caption"
                        >(+{{ selectedList.length - 4 }} others)</span
                    >
                </template>
            </component>
        </component>
        <a
            :disabled="disabled"
            @click="cellClickHandler"
            v-show="!editActive"
            class="justify-start py-1 m-0 leading-normal border border-transparent border-l-0 border-r-0 border-t-0"
            :class="{
                'w-4/5': !linkText,
                fieldEmpty: !formInput.inputValue,
                requiredField: this.isRequired,
                ...displayValueClasses
            }"
            :title="tooltipText"
        >
            <slot name="link-text">
                <span v-html="linkText"></span>
            </slot>
        </a>
    </div>
</template>

<script>
import { FormRules } from '@/components/shared/mixins/formMixins'

export default {
    name: 'SelectCell',
    props: {
        value: {
            type: [Number, String, Array],
            default: null
        },
        displayValue: {
            type: String,
            default: ''
        },
        displayAndTooltipValue: {
            type: Object,
            default: () => {}
        },
        displayValueClasses: {
            type: Object,
            default: () => {}
        },
        cellId: {
            type: [Number, String],
            default: null
        },
        editableComponent: {
            type: String,
            default: 'v-select'
        },
        editActive: {
            type: Boolean,
            default: null
        },
        isRequired: {
            type: Boolean,
            default: false
        },
        defaultValue: {
            type: [String, Array, Number],
            default: null
        },
        items: {
            type: Array,
            default: () => []
        },
        disabled: {
            type: Boolean,
            default: false
        },
        label: {
            type: String,
            default: 'Enter value'
        },
        multiple: {
            type: Boolean,
            default: false
        },
        smallChips: {
            type: Boolean,
            default: false
        },
        emitChange: {
            type: Boolean,
            default: false
        },
        showCondensed: {
            type: Boolean,
            default: false
        }
    },
    mixins: [FormRules],
    data() {
        return {
            isValid: true,
            focused: false,
            formInput: { inputValue: '' }
        }
    },
    mounted() {
        this.formInput.inputValue = this.value || this.defaultValue
    },
    methods: {
        cellClickHandler() {
            if (this.disabled) return false

            this.focused = true
            console.log('this.cellId', this.cellId)
            this.$emit('edit-row', this.cellId)
        },
        onCellClick(e) {
            console.log('onCellClick', e)
        },
        validate() {
            this.isValid = this.$refs.formInput.validate()
            return this.isValid
        },
        clearValidate() {
            this.isValid = true
            return this.isValid
        }
    },
    computed: {
        linkText() {
            const displayValue = this.displayAndTooltipValue
                ? this.displayAndTooltipValue.displayValue
                : ''
            let text = displayValue || this.displayValue || this.value
            return text || ''
        },
        tooltipText() {
            let text = this.displayAndTooltipValue ? this.displayAndTooltipValue.tooltipText : ''
            return text || ''
        },
        renderEditableComponent() {
            if (this.editActive) return this.editableComponent
            return ''
        },
        renderForm() {
            if (this.editActive) return 'v-form'
            return ''
        },
        selectedList() {
            return this.multiple ? this.formInput.inputValue : []
        }
    },
    watch: {
        value() {
            // console.log('value passed in', this.value)
            this.formInput.inputValue = this.value
        },
        'formInput.inputValue': function() {
            if (this.editActive) {
                this.$emit('input', this.formInput.inputValue)
                if (this.emitChange) {
                    this.$emit('change', true)
                }
            }
        },
    }
}
</script>
