<template>
    <div class="m-auto w-screen-sm">
        <h1 class="text-black mb-2 text-xl lg:text-2xl text-center">Open Program</h1>
        
        <v-form
            ref="openEpisodeForm"
            class="px-4"
            v-model="isValid"
        >
            <div class="text-center mb-2">
                This changes the episode status to <span class="text-green font-bold">Program Open</span> 
            </div>
            
            <div class="w-200 m-auto">
                <EditableDatePicker
                    v-model="startDate"
                    :label="'Program Start Date'"
                    min="2010-01-01"
                    :default-date="new Date()"
                    :rules="[futureDateNotAllowed, validProgramStart]"
                />
            </div>

            <div class="flex flex-row justify-center mt-4">
                <v-btn
                    depressed
                    class="mr-4 w-40"
                    @click="cancelHandler"
                    data-cy="cancelHandler"
                >Cancel</v-btn>
                <v-btn
                    depressed
                    color="primary"
                    @click="submitHandler"
                    class="v-btn--flat inline w-200"
                >Open Program</v-btn>
            </div>
        </v-form>
    </div>
</template>

<script>
import { mapState } from 'vuex'
import EditableDatePicker from '@/components/shared/EditableDatePicker'
import MessageDialog from '@/components/shared/mixins/messageDialog'
import { FormRules } from '@/components/shared/mixins/formMixins'
import { format } from 'date-fns'

export default {
    name: 'ModalOpenEpisode',
    components: { EditableDatePicker },
    mixins: [ MessageDialog, FormRules ],
    data() {
        return {
            defaultOpenEpisode: {
                startDate: format(new Date(), 'yyyy-MM-dd')
            },
            isValid: true,
            startDate: null,
        }
    },
    mounted() {
        this.openEpisodeForm.startDate = format(new Date(), 'yyyy-MM-dd')
    },
    methods: {
        validate() {
            return this.$refs.openEpisodeForm.validate()
        },
        closeModal() {
            this.$store.commit('MODAL_IS_VISIBLE', false)
        },
        cancelHandler() {
            this.closeModal()
        },
        submitHandler() {
            // commit mutation
            this.isValid = this.validate()
            if (this.isValid) {
                this.openEpisodeForm.status = 'OPEN'
                this.$root.$emit('submit-reopen-episode', { messageText: 'Opening program', episode: { episodeId: this.episode.episodeId, ...this.openEpisodeForm } })
                this.closeModal()
            }
        },
    },
    computed: {
        ...mapState({
            staticChoices: state => state.app.staticChoices,
            data: state => state.app.modal.componentData
        }),
        openEpisodeForm: {
            get() {
                return this.defaultOpenEpisode
            },
            set(openEpisodeForm) {
                console.log(openEpisodeForm)
            }
        },
        episode() {
            return this.data && this.data.episode ? this.data.episode : {}
        }
    },
    watch: {
        startDate() {
            console.log('startDate', this.startDate)
            this.openEpisodeForm.startDate = this.startDate
        },
    }
}
</script>
