import { reactive, toRefs } from '@vue/composition-api'
import { defineStore } from 'pinia'

export const useModalStore = defineStore('modal', () => {
    const state = reactive({
        isVisible: false,
        data: {},
        componentName: ''
    })

    const openModal = ({ payload, modalName }) => {
        state.isVisible = true
        state.data = payload
        state.componentName = modalName
    }

    const closeModal = () => {
        state.isVisible = false
        state.data = {}
        state.componentName = ''
    }

    return {
        ...toRefs(state),
        openModal,
        closeModal
    }
})
