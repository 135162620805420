<template>
    <div class="m-auto w-screen-xs p-4 px-20">
        <h1 class="text-black mb-2 text-xl lg:text-2xl text-center">Delete Stuni Checkin</h1>
        <div class="text-black text-sm my-5 m-auto flex flex-row w-240 justify-between">
            <div>{{ checkinDate }}</div>
            <div>{{ checkinTime }}</div>
            <div>{{ checkinName}}</div>
        </div>
        <div class="text-center mb-8">Are you sure you would like to delete this Checkin?</div>

        <div class="flex flex-row justify-center mt-4">
            <v-btn
                depressed
                class="mr-4
                w-40"
                data-cy="cancelHandler"
                @click="cancelHandler"
            >Cancel</v-btn>
            <v-btn
                depressed
                color="primary"
                class="v-btn--flat inline w-40"
                :disabled="deletingCheckin"
                @click="submitHandler"
            >{{ confirmBtnTxt }}</v-btn>
        </div>
    </div>
</template>

<script>
import { ref, onMounted, computed } from '@vue/composition-api'
import MessageDialog from '@/components/shared/mixins/messageDialog'
import { useMessageStore } from '@/stores/useMessageStore'
import { storeToRefs } from 'pinia'
import { useModalStore } from '@/stores/useModalStore'
import { useCampusCheckinsStore } from '@/stores/useCampusCheckinsStore'
import { checkinService } from '@/services/checkin.service'


export default {
    components: {},
    mixins: [MessageDialog],
    name: 'ModalConfirmDeleteCheckin',
    setup(_, { root }) {
        const $message = root.$message
        const modalStore = useModalStore()
        const { closeModal } = modalStore
        const { data } = storeToRefs(modalStore)
        const campusCheckinsStore = useCampusCheckinsStore()
        const { updateCampusCheckinsDeleted } = campusCheckinsStore
        const messageStore = useMessageStore()
        const { message, type } = storeToRefs(messageStore)
        const { showMessage, resetMessage } = messageStore
        const deletingCheckin = ref(false)

        const checkinDate = computed(() => data.value?.checkin?.date)
        const checkinTime = computed(() => data.value?.checkin?.time)
        const checkinName = computed(() => data.value?.checkin?.name)

        const confirmBtnTxt = computed(() => deletingCheckin.value ? 'Please wait...' : 'Confirm')
        const cancelHandler = () => {
            closeModal()
        }

        const submitHandler = async() => {
            try {
                deletingCheckin.value = true
                await checkinService.deleteCheckin(data.value.checkin.checkinId)
                message.value = 'Checkin deleted!'
                type.value = 'success'
                updateCampusCheckinsDeleted(true)
                setTimeout(() => {
                    closeModal()
                }, 1000)
            } catch (error) {
                message.value = 'Delete checkin failed.'
                type.value = 'error'
                updateCampusCheckinsDeleted(false)
            } finally {
                deletingCheckin.value = false
                showMessage({duration: 5000}, $message)
            }
        }

        onMounted(() => {
            resetMessage()
        })

        return {
            checkinDate,
            checkinTime,
            checkinName,
            confirmBtnTxt,
            deletingCheckin,
            cancelHandler,
            submitHandler
        }
    }
}
</script>
