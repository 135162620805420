import gql from 'graphql-tag'

const GET_STAFF_USERS = gql`
    query getUsers {
        users: ted2_users(where: {
            account_type: {_neq: "student"},
            status: {_neq: "DEACTIVATED"}
        }) {
            user_id
            last_seen
            name
            first_name
            last_name
            auth0_id
        }
    }
`

const GET_YOUNG_PEOPLE = gql`
    query getYoungPeople {
        clients: ted2_client (
            where: {
                _or: [
                    { user: {account_type: {_eq: "student"}}},
                    { user_id: {_is_null: true}}
                ],
            }, order_by: { last_name: asc }
        ) {
            client_id
            first_name
            last_name
        }
    }
`

export { GET_STAFF_USERS, GET_YOUNG_PEOPLE }
