<template>
    <div
        class="bg-white-pure l-box-shadow rounded hidden"
        :class="{'browserTooSmallMsg': show}"
    >
        <img src="@/assets/images/image_browserresize.png" alt="Resize browser" class="block m-auto resizeBrowserImage"/>
        <h1 class="text-xl mb-3 text-center">Your browser or device is too small</h1>
        <div class="text-center">
            TED2 works best on tablet devices (at minimum Ipad Mini) and desktops. Change device/or resize your browser to be at least 768px wide to get back in.
        </div>
    </div>
</template>

<script>
export default {
    name: 'BrowserToSmall',
    props: {
        show: {
            type: Boolean,
            default: true
        }
    }
}
</script>
