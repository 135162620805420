import { toRefs, reactive, ref } from '@vue/composition-api'
import { defineStore } from 'pinia'

export const useWorkshopStore = defineStore('workshop', () => {
    const state = reactive({
        workshopDeleted: false
    })

    const updateWorkshopDeleted = (val) => {
        state.workshopDeleted = val
    }

    return {
        ...toRefs(state),
        updateWorkshopDeleted
    }
})