import {getInstance} from '@/auth'
import {CS_AUTH0_CLAIM_ID} from '@/utils/constants'

export default {
    userIdToLink: (state) => state.userIdToLink,
    staticChoices: (state) => state.staticChoices,
    componentData: (state) => state.modal.componentData,
    componentName: (state) => state.modal.componentName,
    episodeFormToUpdate: (state) => state.episodeFormToUpdate,
    episodeFormIdToDelete: (state) => state.episodeFormIdToDelete,
    episodeFormsForCopy: (state) => state.episodeFormsForCopy,
    tenantId: () => {
        const authService = getInstance()
        console.log('claim id text', CS_AUTH0_CLAIM_ID)

        console.log('authService and user', authService, authService?.user)
        const csClaims = authService?.user[CS_AUTH0_CLAIM_ID]
        return csClaims?.tenant
    },
    genderOptions: (state) => state.staticChoices['clientDetail.gender'],
    alertTypes: (state) => state.staticChoices['alert.types'],
    atsiStatuses: (state) => state.staticChoices['user.atsiStatuses'],
    modalIsVisible: (state) => state.modal.isVisible,
}
