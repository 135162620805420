<template>
    <div class="m-auto w-screen-sm">
        <h1 class="text-black mb-2 text-xl lg:text-2xl text-center">Delete {{ formSetName }}</h1>
        <v-form
            ref="editEpisodeForm"
            class="px-4"
            @submit.prevent="submitHandler"
        >
            <div class="text-center py-4">
                Are you sure you want to delete this form?
            </div>
            <div class="flex flex-row justify-center mt-4">
                <v-btn
                    depressed
                    class="mr-4 w-40"
                    data-cy="cancelHandler"
                    @click="cancelHandler"
                >Cancel</v-btn>
                <v-btn
                    depressed
                    type="submit"
                    color="error"
                    class="v-btn--flat inline w-40"
                >Yes, I'm sure</v-btn>
            </div>
        </v-form>
    </div>
</template>

<script>
import EditableDatePicker from '@/components/shared/EditableDatePicker'
import {FormRules} from '@/components/shared/mixins/formMixins'
import { addYears, endOfDay, format, parse, parseISO, isValid as isValidDate } from 'date-fns'
import { computed, onMounted, ref, watch } from '@vue/composition-api'
import { createNamespacedHelpers } from 'vuex-composition-helpers'
import { useLazyQuery } from '@vue/apollo-composable'
import camelcaseKeys from 'camelcase-keys'
import { GET_STAFF_USERS } from '@/graphql/queries/userQueries.gql'

const { useActions, useGetters } = createNamespacedHelpers('')

export default {
    name: 'ModalDeleteEpisodeForm',
    components: {EditableDatePicker},
    mixins: [FormRules],
    setup() {
        const editEpisodeForm = ref({})
        const { closeModal, setEpisodeFormToDelete } = useActions(['closeModal', 'setEpisodeFormToDelete'])
        const { componentData: data } = useGetters(['componentData'])

        const formSetId = computed(() => data.value?.episodeForm?.formSetId)
        const formSetName = computed(() => data.value?.episodeForm?.name)

        const cancelHandler = () => {
            closeModal()
        }

        const submitHandler = () => {
            const { episodeFormId } = data.value?.episodeForm
            setEpisodeFormToDelete(episodeFormId)
            closeModal()
        }

        return {
            editEpisodeForm,
            formSetId,
            formSetName,
            submitHandler,
            cancelHandler
        }
    }
}
</script>
