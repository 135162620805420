<template lang="pug">
    div(class="m-auto w-full md:max-w-screen-sm lg:max-w-960 xl:max-w-1250")
        h1(class="text-black.mb-2.text-xl.lg:text-2xl.text-center.capitalize")
            | Invite User
        v-form(ref="form")
            UserForm(ref="userForm" v-model="userForm")

            Permissions(ref="permissions" class="my-5" v-model="userLocationPrograms")
            v-alert(
                v-if="showAlert"
                :closable="false"
                :type="type"
                class="text-sm") {{ message }}
            .flex.flex-row.justify-center.mt-4
                v-btn(
                    depressed
                    class="mr-4 w-40"
                    data-cy="cancelHandler"
                    :class="{'pointer-events-none': saving}"
                    @click="cancelHandler"
                ) Cancel
                v-btn(
                    depressed
                    class="v-btn--flat inline w-40"
                    type="success"
                    color="success"
                    :class="{'pointer-events-none': saving}"
                    @click.prevent="submitHandler"
                ) {{btnText}}
</template>

<script>
import { mapState } from 'vuex'
import UserForm from '@/components/user-management/partials/UserForm'
import Permissions from '@/components/user-management/partials/Permissions'
import INVITE_STAFF from '@/graphql/mutations/inviteStaff.gql'
import INSERT_USER_LOCATION_PROGRAMS from '@/graphql/mutations/insertUserLocationPrograms.gql'
import snakecaseKeys from 'snakecase-keys'
import camelcaseKeys from 'camelcase-keys'
import MessageDialog from '@/components/shared/mixins/messageDialog'
import { CheckUserHelper } from '@/components/shared/mixins/userMixin'

const DEFAULT_USER = {
    firstName: '',
    lastName: '',
    role: '',
    email: '',
    phone: ''
}

export default {
    name: 'ModalInviteUser',
    components: {
        UserForm,
        Permissions
    },
    mixins: [MessageDialog, CheckUserHelper],
    data() {
        return {
            userForm: { ...DEFAULT_USER },
            userLocationPrograms: [],
            saving: false,
            isValid: true,
            message: '',
            type: '',
            newUserId: null,
            showAlert: false
        }
    },
    methods: {
        validate() {
            this.isValid = this.$refs.form.validate() && this.$refs.userForm.validate()
            return this.isValid
        },
        closeModal() {
            this.$store.commit('MODAL_IS_VISIBLE', false)
        },
        cancelHandler() {
            this.closeModal()
        },
        resetMessage() {
            this.message = ''
            this.type = ''
        },
        submitHandler() {
            if (!this.validate()) return
            this.resetMessage()
            this.saveUser()
        },
        async saveUser() {
            const { email, firstName, lastName, phone, role } = this.userForm
            this.showAlert = false
            this.saving = true

            const emailExists = await this.checkUserEmailExists(email)
            if (emailExists) {
                this.saving = false
                return
            }

            const userInfo = {
                email,
                firstName,
                lastName,
                role,
            }

            this.$apollo.mutate({
                mutation: INVITE_STAFF,
                variables: {...userInfo}
            }).then(({data: {user}}) => {
                // this.$apollo.provider.defaultClient.resetStore()
                user = camelcaseKeys(user, { deep: true })
                const userLocationPrograms = this.userLocationPrograms.map(({ locationProgramId }) => ({
                    locationProgramId,
                    userId: user.userId
                }))

                this.newUserId = user.userId

                return this.$apollo.mutate({
                    mutation: INSERT_USER_LOCATION_PROGRAMS,
                    variables: {
                        userLocationPrograms: snakecaseKeys(userLocationPrograms, { deep: true }),
                    }
                })
            })
            .then(() => {
                this.message = 'The staff member has been invited.'
                this.type = 'success'
                this.$root.$emit('staff-invited', this.newUserId)
            }).catch(err => {
                // need to fix this later but will do for now
                let message = err?.graphQLErrors[0]?.extensions?.internal?.response?.body?.message
                if (!message) message = err?.graphQLErrors[0].message
                if (!message) message = 'Error inviting the staff member'
                this.message = message
                this.type = 'error'
                this.showAlert = true
            }).finally(() => {
                this.saving = false
                if (!this.showAlert) this.showMessage({duration: 5000})
            })
        },
    },
    computed: {
        ...mapState({
            userId: state => state.app.modal.componentData,
            isVisible: state => state.app.modal.isVisible
        }),
        btnText() {
            return this.saving ? 'Saving...' : 'Save'
        }
    }
}
</script>
