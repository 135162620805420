const ModalHelper = {
    methods: {
        closeModal() {
            this.$store.commit('MODAL_IS_VISIBLE', false)
        },
        cancelHandler() {
            this.closeModal()
        },
    },
}

export default ModalHelper
