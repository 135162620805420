var render = function () {var _vm=this;var _h=_vm.$createElement;var _c=_vm._self._c||_h;return _c('div',{staticClass:"flex flex-col w-full"},[_c('v-data-table',{ref:"filterTable",staticClass:"a-table w-full",attrs:{"single-select":"","item-key":"id","headers":_vm.headers,"items":_vm.tableData,"items-per-page":_vm.itemsPerPage,"footer-props":_vm.footerProps,"data-cy":"clientList","sort-by":_vm.sortBy,"sort-desc":_vm.sortDesc},on:{"update:itemsPerPage":function($event){_vm.itemsPerPage=$event},"update:items-per-page":function($event){_vm.itemsPerPage=$event},"update:sortBy":function($event){_vm.sortBy=$event},"update:sort-by":function($event){_vm.sortBy=$event},"update:sortDesc":function($event){_vm.sortDesc=$event},"update:sort-desc":function($event){_vm.sortDesc=$event},"click:row":_vm.rowClickHandler},scopedSlots:_vm._u([{key:"item.interactionDate",fn:function(ref){
var item = ref.item;
return [_vm._v(" "+_vm._s(_vm.getFormattedDate(item.interactionDate))+" ")]}},{key:"item.interactionTime",fn:function(ref){
var item = ref.item;
return [_vm._v(" "+_vm._s(item.interactionTime)+" ")]}},{key:"item.type",fn:function(ref){
var item = ref.item;
return [_vm._v(" "+_vm._s(_vm.getText(item.type, 'types'))+" ")]}},{key:"item.method",fn:function(ref){
var item = ref.item;
return [_vm._v(" "+_vm._s(_vm.getText(item.method, 'methods'))+" ")]}},{key:"item.duration",fn:function(ref){
var item = ref.item;
return [_vm._v(" "+_vm._s(_vm.getText(item.duration, 'durations'))+" ")]}},{key:"item.createdById",fn:function(ref){
var item = ref.item;
return [_c('span',{staticClass:"capitalize"},[_vm._v(_vm._s(_vm.getNameById(item.createdById)))])]}},{key:"item.created",fn:function(ref){
var item = ref.item;
return [_vm._v(" "+_vm._s(_vm.getFormattedDate(item.created))+" ")]}},{key:"item.editRecord",fn:function(ref){
var item = ref.item;
return [_c('div',{staticClass:"flex items-center"},[(item.treatmentEvents && item.treatmentEvents.length)?_c('v-tooltip',{attrs:{"bottom":""},scopedSlots:_vm._u([{key:"activator",fn:function(ref){
var on = ref.on;
var attrs = ref.attrs;
return [_c('div',_vm._g(_vm._b({staticClass:"w-20 text-right"},'div',attrs,false),on),[_vm._v(_vm._s(item.treatmentEvents.length)+" "),_c('fa-icon',{staticClass:"text-yellow-bright ml-1",attrs:{"icon":"star"}})],1)]}}],null,true)},[_c('span',[_vm._v(_vm._s(item.treatmentEvents.length)+" Treatment Event(s)")])]):_c('div',{staticClass:"w-20"},[_vm._v(" ")]),(item.behaviourIncidents && item.behaviourIncidents.length)?_c('v-tooltip',{attrs:{"bottom":""},scopedSlots:_vm._u([{key:"activator",fn:function(ref){
var on = ref.on;
var attrs = ref.attrs;
return [_c('div',_vm._g(_vm._b({staticClass:"w-20 text-right"},'div',attrs,false),on),[_vm._v(_vm._s(item.behaviourIncidents.length)+" "),_c('fa-icon',{staticClass:"text-orange ml-1",attrs:{"icon":"exclamation-triangle"}})],1)]}}],null,true)},[_c('span',[_vm._v(_vm._s(item.behaviourIncidents.length)+" Behaviour incident(s)")])]):_c('div',{staticClass:"w-20"},[_vm._v(" ")]),_c('div',{staticClass:"flex w-full justify-end"},[_c('fa-icon',{staticClass:"text-grey-2 mx-2",attrs:{"icon":"chevron-right"}})],1)],1)]}}])})],1)}
var staticRenderFns = []

export { render, staticRenderFns }