import GET_USER_BY_EMAIL from '@/graphql/queries/getUserByEmail.gql'
import GET_CLIENT_BY_EMAIL from '@/graphql/queries/getClientByEmail.gql'
import ModalHelper from '@/components/shared/mixins/modalMixin'
import { mapActions } from 'vuex'

const UserStatus = {
    Invited: 'INVITED',
    Active: 'ACTIVE',
    Deactivated: 'DEACTIVATED',
    Blocked: 'BLOCKED'
}

const UserAction = {
    Reactivate: 'REACTIVATE',
    Deactivate: 'DEACTIVATE',
    ReInvite: 'REINVITE',
    UpdateInfo: 'UPDATE_INFO',
    Unlock: 'UNLOCK',
    ChangePassword: 'CHANGE_PASSWORD'
}

const USER_STATUS_ACTIONS = [
    [UserStatus.Invited, [UserAction.ReInvite, UserAction.UpdateInfo]],
    [
        UserStatus.Active,
        [UserAction.Deactivate, UserAction.UpdateInfo, UserAction.ChangePassword]
    ],
    [UserStatus.Deactivated, [UserAction.Reactivate, UserAction.UpdateInfo]],
    [UserStatus.Blocked, [UserAction.Unlock]]
]

const UserHelper = {
    mixins: [ ModalHelper ],
    methods: {
        ...mapActions(['openModal', 'closeModal']),
        getStatusTitle(status) {
            const statusObj = this.userStatuses.find(({value}) => value === status)

            return statusObj?.text || ''
        },
        getStatusClass(status) {
            const statusClassObj = this.userStatusClasses.find(({value}) => value === status)

            return statusClassObj?.text || ''
        },
        getRoleTitle(role) {
            const roleObj = this.userRoles.find(({value}) => value === role)

            return roleObj?.text || ''
        },
        getValidUserActions(status) {
            if (!status) return []
    
            return this.userStatusActionsMap[status] ?? []
        },
        activateUser(userId, action) {
            this.openModal({
                payload: {
                    variables: { userId },
                    action,
                    keepOpen: true
                },
                modalName: 'ModalConfirmUserActivate'
            })
        },
        deactivateUser(userId, action) {
            this.openModal({
                payload: {
                    variables: { userId },
                    action,
                    keepOpen: true
                }, modalName: 'ModalConfirmUserDeactivate'
            })
        },
        reinviteUser(userId, action) {
            this.openModal({
                payload: {
                    variables: { userId },
                    action,
                    keepOpen: true
                },
                modalName: 'ModalConfirmReinvite'
            })
        },
        linkToStuni(user) {
            this.openModal({
                payload: {
                    user,
                    keepOpen: true
                },
                modalName: 'ModalConfirmLinkToStuni'
            })
        },
        cancelHandler() {
            this.closeModal()
        },
        submitHandler() {
            this.$root.$emit('change-user-status', this.data)
            this.closeModal()
        }
    },
    computed: {
        userStatuses() {
            return this.staticChoices['user.statuses']
        },
        userStatusClasses() {
            return this.staticChoices['user.statusClasses']
        },
        userRoles() {
            return this.staticChoices['user.roles']
        },
        UserStatus() {
            return UserStatus
        },
        UserAction() {
            return UserAction
        },
        userStatusActionsMap() {
            return USER_STATUS_ACTIONS.reduce((map, [status, actions]) => {
                map[status] = actions
                return map
            }, {})
        }
    }
}

const CheckUserHelper = {
    methods: {
        async checkUserEmailExists(email) {
            if (!email) return false

            try {
                let resp = null
                resp = await this.$apollo.query({
                    query: GET_USER_BY_EMAIL,
                    variables: { email }
                })
                const users = resp?.data?.users

                if (users && users.length) {
                    this.showAlert = true
                    this.message = 'A user with the same email already exists. Please try a different one.'
                    this.type = 'error'
                    return true
                }

                resp = await this.$apollo.query({
                    query: GET_CLIENT_BY_EMAIL,
                    variables: { email }
                })
                const count = resp?.data?.total?.aggregate.count

                if (count) {
                    this.showAlert = true
                    this.message = 'A user with the same email already exists. Please try a different one.'
                    this.type = 'error'
                    return true
                }
            } catch (e) {
                // just in case graphql comes back with an error, we want to catch it and continue
                console.info('call errored with exception', e)
                this.showAlert = true
                this.message = 'something went wrong'
                this.type = 'error'
                return true
            }
            
            return false
        }
    }
}

export {UserHelper, UserStatus, UserAction, CheckUserHelper}
