<template>
    <div class="m-auto w-screen-sm">
        <h1 class="text-black mb-2 text-xl lg:text-2xl text-center">Delete Episode</h1>
        <div class="text-center">Are you sure you want to delete this Episode ?</div>
        <div class="w-full flex justify-center flex-wrap text-sm font-normal text-grey-1 text-center font-bold">
            <div class="mr-2">{{ getDateRange(episode) }}</div>
            <div class="uppercase font-bold" :class="getStatusColour(episode)">
                {{ this.episode.status }}
            </div>
            <div class="mr-1">
                <span>{{ getSiteSection(episode) }}</span>
            </div>
            <div>{{ getWorker(episode) }}</div>
        </div>
        <div class="flex flex-row justify-center mt-4">
            <v-btn depressed class="mr-4 w-40" data-cy="cancelHandler" @click="cancelHandler"
                >Cancel</v-btn
            >
            <v-btn depressed color="primary" class="v-btn--flat inline w-40" @click="submitHandler"
                >Confirm</v-btn
            >
        </div>
    </div>
</template>

<script>
import {mapState} from 'vuex'
import DELETE_EPISODE from '@/graphql/mutations/deleteEpisode.gql'
import MessageDialog from '@/components/shared/mixins/messageDialog'
import {EpisodeHelpers} from '@/components/shared/mixins/episodeMixins'

export default {
    components: {},
    mixins: [MessageDialog, EpisodeHelpers],
    name: 'ModalConfirmDeleteEpisode',
    data() {
        return {
            isValid: true
        }
    },
    methods: {
        cancelHandler() {
            this.closeModal()
        },
        closeModal() {
            this.$store.commit('MODAL_IS_VISIBLE', false)
        },
        async submitHandler() {
            if (this.isValid) {
                try {
                    this.moveInProgress = true
                    const deletedEpisode = await this.$apollo.mutate({
                        mutation: DELETE_EPISODE,
                        variables: {
                            episodeId: Number(this.episode.episodeId)
                        }
                    })
                    console.log('result delete episode', deletedEpisode)
                    this.message = 'Delete Episode successfully.'
                    this.type = 'success'
                    this.closeModal()
                } catch (error) {
                    console.log('error:', error)
                    this.message = 'Delete Episode failed.'
                    this.type = 'error'
                } finally {
                    this.moveInProgress = false
                    this.showMessage({duration: 5000})
                }
                this.$root.$emit('submit-delete-episode')
            }
        }
    },
    computed: {
        ...mapState({
            data: (state) => state.app.modal.componentData
        }),
        episode() {
            console.log('this.data?.episode:', this.data?.episode)
            return this.data?.episode
        },
        episodeName() {
            const firstName = this.episode?.worker?.firstName
            const lastName = this.episode?.worker?.lastName
            return `${firstName} ${lastName}`
        }
    }
}
</script>
