<template>
    <v-form
        :model="isValid"
        ref="form"
    >
        <div class="address">
            <div class="grid grid-cols-6 gap-x-2">
                <div class="col-span-6">
                    <div class="v-text-field v-input with-search-icon">
                        <fa-icon icon="search" />
                        <gmap-autocomplete
                            :id="id"
                            class="w-full"
                            label="Enter an address"
                            @place_changed="getAddressData"
                            @keyup="autoCompleteKeyupHandler($event)"
                            :options="autocompleteOptions"
                            :select-first-on-enter="true"
                            :readonly="readOnly"
                        >
                        </gmap-autocomplete>
                    </div>
                </div>
                <div class="col-span-1">
                    <v-text-field v-model="addressForm.unit" label="Unit" :readonly="readOnly"></v-text-field>
                </div>
                <div class="col-span-5">
                    <v-text-field v-model="addressForm.addressLine1" label="Address line 1" :readonly="readOnly"></v-text-field>
                </div>
                <div class="col-span-6">
                    <v-text-field v-model="addressForm.addressLine2" label="Address line 2" :readonly="readOnly"></v-text-field>
                </div>
                <div class="col-span-2">
                    <v-text-field v-model="addressForm.suburb" label="Suburb" :readonly="readOnly"></v-text-field>
                </div>
                <div class="col-span-2">
                    <v-text-field
                        v-model="addressForm.postcode"
                        label="Postcode"
                        :rules="readOnly ? [] : [required, numbersOnly]"
                        :readonly="readOnly"
                    ></v-text-field>
                </div>
                <div class="col-span-2">
                    <v-select
                        :items="states"
                        ref="state"
                        v-model="addressForm.state"
                        label="State"
                        data-cy="addressState"
                        :readonly="readOnly"
                    ></v-select>
                </div>
            </div>
        </div>
    </v-form>
</template>

<script>
import { mapState } from 'vuex'
import { gmapApi } from 'gmap-vue'
import { FormRules } from '@/components/shared/mixins/formMixins'

export default {
    name: 'Address',
    mixins: [ FormRules ],
    props: {
        id: {
            type: String,
            default: 'address'
        },
        value: {
            type: Object,
            required: true,
            default: () => {
                return {
                    unit: '',
                    addressLine1: '',
                    addressLine2: '',
                    suburb: '',
                    postcode: '',
                    state: '',
                    lat: null,
                    lng: null,
                    archived: false,
                }
            }
        },
        title: {
            type: String,
            default: ''
        },
        readOnly: {
            type: Boolean,
            default: false
        }
    },
    data() {
        return {
            isValid: true,
            googleAddress: '',
            defaultAddress: {
                unit: '',
                addressLine1: '',
                addressLine2: '',
                suburb: '',
                postcode: '',
                state: '',
                lat: null,
                lng: null,
                archived: false,
            },
            autocompleteOptions: {
                componentRestrictions: {
                    country: [
                        'au'
                    ]
                }
            },
            geoCodedAddressTimer: null,
            addressFound: true,
            initialPageLoad: true,
        }
    },
    mounted() {
        // setting the addressFound value on first load
        this.addressFound = false
    },
    methods: {
        validate() {
            this.isValid = this.$refs.form.validate()
            return this.isValid
        },
        getAddressData(addressData, placeResultData, id) {
            // this.$refs.form.reset()
            this.localAddress = addressData

            this.validate()
        },
        autoCompleteKeyupHandler(event) {
            // we check the target value to set this so we know when to get the geocode
            this.addressFound = !!event.target.value
        },
        manualAddressChange() {
            this.localAddress.lat = null
            this.localAddress.lng = null
        },
        mapAddressData(addressList) {
            const mappedObj = {...this.defaultAddress}
            if (!addressList) return null

            for (let i = 0; i < addressList.length; i++) {
                let key = ''
                switch (addressList[i].types[0]) {
                    case 'street_number':
                        key = 'streetNumber'
                        break
                    case 'route':
                        key = 'street'
                        break
                    case 'locality':
                        key = 'suburb'
                        break
                    case 'administrative_area_level_1':
                        key = 'state'
                        break
                    case 'postal_code':
                        key = 'postcode'
                        break
                }
                mappedObj[key] = addressList[i].short_name
            }
            return mappedObj
        },
        geocodedAddress() {
            let address = `${this.localAddress.street || ''} ${this.localAddress.address2 || ''}, ${this.localAddress.suburb || ''} ${this.localAddress.state || ''}, ${this.localAddress.postcode || ''}`
            let geocoder = new this.google.maps.Geocoder()

            const that = this
            geocoder.geocode({
                'address': address
            }, (results, status) => {
                if (status == that.google?.maps.GeocoderStatus.OK) {
                    const latitude = results[0].geometry.location.lat();
                    const longitude = results[0].geometry.location.lng();

                    that.localAddress.lat = that.addressForm.lat = latitude
                    that.localAddress.lng = that.addressForm.lng = longitude
                }
            });
        },
    },
    computed: {
        ...mapState({
            staticChoices: state => state.app.staticChoices
        }),
        google: gmapApi,
        localAddress: {
            get() {
                console.log('getting localAddress so sending addressForm')
                return this.addressForm
            },
            set(addressData) {
                let addressObject = this.mapAddressData(addressData.address_components)
                if (addressData) {
                    if (addressObject.addressLine1) this.addressForm.addressLine1 = addressObject.addressLine1
                    if (addressObject.addressLine2) this.addressForm.addressLine2 = addressObject.addressLine2

                    if (addressObject.streetNumber != null && addressObject.street != null) {
                        this.addressForm.addressLine1 = addressObject.streetNumber + ' ' + addressObject.street
                    }
                    this.addressForm.suburb = addressObject.suburb
                    this.addressForm.state = addressObject.state
                    this.addressForm.postcode = addressObject.postcode
                    this.addressForm.lng = addressData.geometry.location.lng()
                    this.addressForm.lat = addressData.geometry.location.lat()

                    this.addressFound = true
                } else {
                    this.addressFound = false
                }
                const address = {...this.addressForm}
                this.$emit('input', address)
            }
        },
        addressForm: {
            get() {
                console.log('value passed', this.value)
                return this.value || this.defaultAddress
            },
            set(addressForm) {
                this.$emit('input', addressForm)
            }
        },
        states() {
            return this.staticChoices['address.state'] || []
        }
    },
    watch: {
        addressForm: {
            deep: true,
            handler() {
                // if it's the initial page load then we stop what we're doing cause we don't need the geocoded stuff
                if (this.initialPageLoad) {
                    this.initialPageLoad = false
                    return
                }
                // we only do this if we didn't get the value from google maps
                if (this.geoCodedAddressTimer) clearTimeout(this.geoCodedAddressTimer)
                if (!this.addressFound) {
                    this.geoCodedAddressTimer = setTimeout(() => {
                        this.geocodedAddress()
                    }, 3000)
                }

                if (this.addressForm && this.addressForm.street !== '') this.$emit('input', this.addressForm)
            }
        }
    },
}
</script>
