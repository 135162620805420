<template>
    <div class="m-auto w-screen-sm">
        <h1 class="text-black mb-2 text-xl lg:text-2xl text-center">Set to Referred</h1>
        <v-form
            ref="openEpisodeForm"
            class="px-4"
            v-model="isValid"
        >
            <div class="text-center mb-2">
                This changes the episode status to <span class="text-light-green font-bold">Referred</span>
            </div>
            <div class="flex flex-row justify-center mt-4">
                <v-btn
                    depressed
                    class="mr-4 w-40"
                    @click="cancelHandler"
                    data-cy="cancelHandler"
                >Cancel</v-btn>
                <v-btn
                    depressed
                    color="primary"
                    @click="submitHandler"
                    class="v-btn--flat inline w-200"
                >Set to Referred</v-btn>
            </div>
        </v-form>
    </div>
</template>

<script>
import { mapState } from 'vuex'
import EditableDatePicker from '@/components/shared/EditableDatePicker'
import MessageDialog from '@/components/shared/mixins/messageDialog'
import { FormRules } from '@/components/shared/mixins/formMixins'
import { formatISO } from 'date-fns'

export default {
    name: 'ModalOpenEpisode',
    components: { EditableDatePicker },
    mixins: [ MessageDialog, FormRules ],
    data() {
        return {
            defaultOpenEpisode: {},
            isValid: true,
            date: null,
        }
    },
    methods: {
        validate() {
            return this.$refs.openEpisodeForm.validate()
        },
        closeModal() {
            this.$store.commit('MODAL_IS_VISIBLE', false)
        },
        cancelHandler() {
            this.closeModal()
        },
        submitHandler() {
            // commit mutation
            this.isValid = this.validate()
            if (this.isValid) {
                this.openEpisodeForm.status = 'REFERRED'
                this.openEpisodeForm.dateReferred = formatISO(new Date())
                this.openEpisodeForm.startDate = null
                this.$root.$emit('submit-set-to-referred', {
                    messageText: 'Set to Referred',
                    episode: { episodeId: this.episode.episodeId, ...this.openEpisodeForm }
                })
                this.closeModal()
            }
        },
    },
    computed: {
        ...mapState({
            staticChoices: state => state.app.staticChoices,
            data: state => state.app.modal.componentData
        }),
        openEpisodeForm: {
            get() {
                return this.defaultOpenEpisode
            },
            set(openEpisodeForm) {
                console.log(openEpisodeForm)
            }
        },
        episode() {
            return this.data && this.data.episode ? this.data.episode : {}
        }
    },
}
</script>
