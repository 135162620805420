import gql from 'graphql-tag'

const UPDATE_EPISODE_FORM_DUE_DATE = gql`
    mutation updateEpisodeForm($episodeFormId: uuid = "", $dueDate: date = "") {
        episodeForm: update_ted2_episode_form(where: {episode_form_id: {_eq: $episodeFormId}}, _set: {due_date: $dueDate}) {
            returning {
                episode_form_id
                due_date
                form_title
                tenant_id
                form_answer_id
            }
        }
    }
`

const DELETE_EPISODE_FORM_BY_PK = gql`
    mutation deleteEpisodeForm($episodeFormId: uuid = "") {
        deletedEpisode: delete_ted2_episode_form_by_pk(episode_form_id: $episodeFormId) {
            episode_form_id
        }
    }

`

export { UPDATE_EPISODE_FORM_DUE_DATE, DELETE_EPISODE_FORM_BY_PK }
