export default {
    methods: {
        sortItems(listItems) {
            const list = []
            const result = []
            listItems.map(e => {
                list.push(e.text)
            })
            list.sort().map(e => {
                listItems.map(i => {
                    if (e === i.text) {
                        result.push(i)
                    }
                })
            })
            return result
        }
    }
}
