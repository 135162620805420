<template>
    <div class="m-auto w-screen-sm">
        <h1 class="text-black mb-2 text-xl lg:text-2xl text-center">Re-open Program</h1>
        
        <v-form
            ref="reopenEpisodeForm"
            class="px-4"
            v-model="isValid"
        >
            <div class="text-center">
                This changes the episode status to <span class="text-green font-bold">Program Open</span> and
                <span class="block mb-2">unlock all forms &amp; records for this episode.
                </span>
                <span class="block mb-2">This will also remove the value for the program end date.
                </span>
                <p class="mb-2">Are you sure you would like to re-open the program?</p>
            </div>
            
            <!--I need to check with noffs if itll make the Program End date NULL or not - though think its best if we dont that set in stone logic like that -->

            <div class="flex flex-row justify-center mt-4">
                <v-btn
                    depressed
                    class="mr-4 w-40"
                    @click="cancelHandler"
                    data-cy="cancelHandler"
                >Cancel</v-btn>
                <v-btn
                    depressed
                    color="primary"
                    @click="submitHandler"
                    class="v-btn--flat inline w-200"
                >Yes, Re-open Program</v-btn>
            </div>
        </v-form>
    </div>
</template>

<script>
import { mapState } from 'vuex'
import EditableDatePicker from '@/components/shared/EditableDatePicker'
import MessageDialog from '@/components/shared/mixins/messageDialog'
import { FormRules } from '@/components/shared/mixins/formMixins'
import { format } from 'date-fns'

export default {
    name: 'ModalReopenEpisode',
    components: { EditableDatePicker },
    mixins: [ MessageDialog, FormRules ],
    data() {
        return {
            defaultReopenEpisode: {},
            isValid: true,
            date: null,
        }
    },
    methods: {
        validate() {
            return this.$refs.reopenEpisodeForm.validate()
        },
        closeModal() {
            this.$store.commit('MODAL_IS_VISIBLE', false)
        },
        cancelHandler() {
            this.closeModal()
        },
        submitHandler() {
            // commit mutation
            this.isValid = this.validate()
            if (this.isValid) {
                this.reopenEpisodeForm.status = 'OPEN'
                this.episode.startDate = format(new Date(this.episode.startDate), 'yyyy-MM-dd')
                if (this.episode.endDate) this.episode.endDate = null
                this.$root.$emit('submit-reopen-episode', { messageText: 'Episode status has been set to PROGRAM OPEN', episode: { episodeId: this.episode.episodeId, startDate: this.episode.startDate, endDate: this.episode.endDate, ...this.reopenEpisodeForm } })
                this.closeModal()
            }
        },
    },
    computed: {
        ...mapState({
            staticChoices: state => state.app.staticChoices,
            data: state => state.app.modal.componentData
        }),
        reopenEpisodeForm: {
            get() {
                return this.defaultReopenEpisode
            },
            set(reopenEpisodeForm) {
                console.log(reopenEpisodeForm)
            }
        },
        episode() {
            return this.data && this.data.episode ? this.data.episode : {}
        }
    },
}
</script>
