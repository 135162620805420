<template>
    <div class="m-auto w-screen-sm">
        <Loader class="my-12 w-16 h-16" />

        <div class="text-center text-sm">{{ pleaseWaitText }}</div>
    </div>
</template>

<script>
import Loader from '@/assets/loader.svg'
import { computed } from '@vue/composition-api'
import { createNamespacedHelpers } from 'vuex-composition-helpers'

const { useState } = createNamespacedHelpers('app')

export default {
    name: 'ModalPleaseWait',
    components: {Loader},
    setup() {
        const { modal } = useState(['modal'])
        const defaultText = 'Please wait...'
        //#region computed properties
        const pleaseWaitText = computed(() => modal.value?.componentData?.text || defaultText)
        //#endregion

        return {
            pleaseWaitText
        }
    }
}
</script>
