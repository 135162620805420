<template>
    <div class="m-auto w-screen-sm">
        <h1 class="text-black mb-2 text-xl lg:text-2xl text-center">Create New Episode</h1>
        <v-form
            ref="newEpisodeForm"
            class="px-4"
            v-model="isValid"
        >
            <div>
                <!--Below is just a display of client's name so the user can confirm they're creating an episode on the right client-->
                <v-text-field hide-details :value="data.name" placeholder="Full name here" disabled label="Client"></v-text-field>
            </div>

            <!--Below should be the fields in client-details/partials/Episode.vue  >_>sorry i didnt know how to get it working -->
            <Episode ref="episodeDetail" v-model="newEpisodeForm" :users="users" />
            <div class="flex flex-row justify-center mt-4">
                <v-btn
                    depressed
                    class="mr-4 w-40"
                    @click="cancelHandler"
                    data-cy="cancelHandler"
                >Cancel</v-btn>
                <v-btn
                    depressed
                    color="primary"
                    @click="submitHandler"
                    class="v-btn--flat inline w-40"
                >Create New Episode</v-btn>
            </div>
        </v-form>
    </div>
</template>

<script>
import Episode from '@/components/client-details/partials/Episode'
import { mapState } from 'vuex'
import GET_USERS from '@/graphql/queries/getUsers.gql'
import camelcaseKeys from 'camelcase-keys'
import { EpisodeSaveHelpers } from '@/components/shared/mixins/episodeMixins'
import { DEFAULT_TREATMENT_TYPES } from '@/utils/constants'

export default {
    components: {
        Episode
    },
    mixins: [ EpisodeSaveHelpers ],
    name: 'ModalNewEpisode',
    data() {
        return {
            defaultNewEpisode: {
                status: '',
                type: '',
                userId: '',
                locationProgramId: '',
                treatmentTypes: { ...DEFAULT_TREATMENT_TYPES }
            },
            isValid: true,
            users: []
        }
    },
    apollo: {
        users: {
            query: GET_USERS,
            update: ({ users }) => {
                return camelcaseKeys(users, {deep: true})
            },
        },
    },
    mounted() {
        this.defaultNewEpisode.userId = this.loggedInUser.userId
    },
    methods: {
        validate() {
            this.isValid = this.$refs.newEpisodeForm.validate() && this.$refs.episodeDetail.validate()
            return this.isValid
        },
        closeModal() {
            this.$store.commit('MODAL_IS_VISIBLE', false)
        },
        cancelHandler() {
            this.closeModal()
        },
        submitHandler() {
            if (this.validate()) {
                const episode = { ...this.newEpisodeForm }

                this.setEpisodeStatus(episode)
                //this.setEpisodeStartDate(episode)
                // this.setForms(episode)

                this.$root.$emit('submit-new-episode', episode)
                this.closeModal()
            }
        },
    },
    computed: {
        ...mapState({
            loggedInUser: state => state.app.loggedInUser,
            data: state => state.app.modal.componentData,
            isVisible: state => state.app.modal.isVisible,
        }),
        newEpisodeForm: {
            get() {
                return this.defaultNewEpisode
            },
            set(newEpisodeForm) {
                console.log(newEpisodeForm)
            }
        }
    }
}
</script>
