import axios from 'axios'
import { getInstance } from '@/auth'
import { SERVICE_API, API_KEY_SECRET } from '@/utils/constants'

const axiosRequestInterceptor = async config => {
  config.baseURL = SERVICE_API
  const token = await getInstance().getTokenSilently()
  if (token) {
      config.headers['Authorization'] = `Bearer ${token}`
      config.headers['X-API-KEY'] = API_KEY_SECRET
  }
  return config
}

const axiosInstance = axios.create()
axiosInstance.interceptors.request.use(axiosRequestInterceptor, e => Promise.reject(e))

export default axiosInstance