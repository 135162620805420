const closeModal = (store) => {
    store.commit('MODAL_IS_VISIBLE', false)
}

const cancelHandler = (store) => {
    closeModal(store)
}

// const submitHandler = (emit, store, data, eventName) => {
//     emit(eventName, data)
//     closeModal(store)
// }

export {
    closeModal,
    cancelHandler,
    // submitHandler
}
