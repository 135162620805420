<template>
    <div class="search">
        <v-autocomplete
            :key="'search-input'"
            outlined dense single-line 
            ref="searchComboBox"
            data-cy="searchClients"
            prepend-inner-icon="search"
            v-model="selection"
            item-text="name"
            item-value="clientId"
            hide-details
            :loading="loading"
            :items="computedClients"
            :search-input.sync="searchText"
            :hide-no-data="hideNoData"
            :menu-props="searchMenuProps"
            :filter="v => v"
            :rules="rules"
            @change="selectClientHandler"
        >
            <template v-slot:no-data>
                <v-list-item class="flex items-center">
                    <div class="text-xs text-grey-1">Client not found.</div>
                </v-list-item>
            </template>
            <template v-slot:item="{ item }">
                <v-list-item
                    :disabled="enableToConnect(item)"
                    :class="{'bg-grey-6': enableToConnect(item)}"
                    @click="selectClient(item)"
                >
                    <v-list-item-content>
                        <v-list-item-title class="flex items-center">
                            <div class="mr-3 text-grey-2 font-bold text-xs">ID {{item.clientId}}</div>
                            <div class="mr-3 text-grey-1 font-normal">{{getNameForClient(item)}}</div>
                            <div class="mr-3 text-grey-2 font-bold text-xs">YR OF BIRTH {{getYear(item.dob)}}</div>
                            <div class="text-grey-1 font-normal" v-if="item.hasStudent && hasStudent">YP Linked: {{item.user.status}}</div>
                        </v-list-item-title>
                    </v-list-item-content>
                </v-list-item>
            </template>
            <template v-slot:append-item>
                <slot name="append-item"></slot>
            </template>
            <template v-slot:label>
                <slot name="label-inner"></slot>
            </template>
        </v-autocomplete>
    </div>
</template>

<script>
import camelcaseKeys from 'camelcase-keys'
import SEARCH_CLIENTS from '@/graphql/queries/searchClients.gql'
import SEARCH_CLIENTS_BY_ID from '@/graphql/queries/searchClientsById.gql'
import {parseISO, format, isValid} from 'date-fns'
import { mapState } from 'vuex'
import snakecaseKeys from 'snakecase-keys'
import { ClientHelpers } from '@/components/shared/mixins/clientMixins'
import { StuniStatus } from '@/components/shared/mixins/stuniMixin'

let searchMutation = SEARCH_CLIENTS

export default {
    name: 'Search',
    mixins: [ClientHelpers],
    props: {
        search: {
            type: String,
            default: ''
        },
        showMore: {
            type: Boolean,
            default: false
        },
        rules: {
            type: Array,
            default: () => []
        },
        clearText: {
            type: Boolean, 
            default: true
        },
        hasStudent: {
            type: Boolean,
            default: false
        }
    },
    data() {
        return {
            selection: null,
            currentClient: null,
            skipFiltered: false,
            clientList: [],
            loading: false,
            searchText: '',
            showMoreVal: false,
            searchTimeout: null,
            nonce: 1,
            searchMenuProps: {
                'closeOnClick': false,
                'closeOnContentClick': true,
            },
        }
    },
    mounted() {
        this.searchText = this.search
        this.showMoreVal = this.showMore
    },
    apollo: {
        filteredClients: {
            query: SEARCH_CLIENTS,
            variables() {
                let searchId = Number(this.searchText)
                // need to set it to -1 so the other search params can work, setting to -1 means searchId is ignored
                if (isNaN(searchId)) searchId = null
                return {
                    limit: 10,
                    searchCompare: this.searchText ? {search: this.searchText} : {},
                }
            },
            skip() {
                return this.skipFiltered || !this.searchText
            },
            result({ data: {filteredClients, total} }) {
                this.showMoreVal = total.aggregate.count > 5
                const mappedClients = camelcaseKeys(filteredClients || [], { deep: true })
                mappedClients.forEach(client => {
                    client.name = this.getNameForClient(client)
                    client.hasStudent = client.user ? true : false
                })
                this.clientList = [...mappedClients]
                this.$emit('update:showMore', this.showMoreVal)
            },
            watchLoading (isLoading, countModifier) {
                this.loading = isLoading
            }
        },
        filteredClientsById: {
            query: SEARCH_CLIENTS_BY_ID,
            variables() {
                let searchId = Number(this.searchText)
                // need to set it to -1 so the other search params can work, setting to -1 means searchId is ignored
                if (isNaN(searchId)) searchId = null

                let userIdCompare = this.getUserIdCompareExp(searchId, this.loggedInUser.userId)

                userIdCompare = snakecaseKeys(userIdCompare, {deep: true, exclude: ['_eq', '_or']})
                return {
                    limit: 5,
                    userIdCompare,
                }
            },
            skip() {
                return !this.skipFiltered
            },
            result({ data: {filteredClientsById, total} }) {
                this.showMoreVal = total.aggregate.count > 5
                const mappedClients = camelcaseKeys(filteredClientsById || [], { deep: true })
                mappedClients.forEach(client => {
                    client.name = this.getNameForClient(client)
                })
                this.clientList = [...mappedClients]
                this.$emit('update:showMore', this.showMoreVal)
            },
            watchLoading (isLoading, countModifier) {
                this.loading = isLoading
            }
        },
    },
    methods: {
        selectClient(item) {
            this.$refs.searchComboBox.setValue(item.clientId)
        },
        enableToConnect(item) {
            if (!item.hasStudent || item.user.status === StuniStatus.INVITED || !this.hasStudent) return false

            return true
        },
        selectClientHandler(clientId) {
            this.$emit('client-id-changed', clientId)
            this.$nextTick(() => {
                this.clearSearch(this.clearText)
            })
        },
        clearSearch(clearText) {
            if (clearText) {
                this.selection = null
                this.searchText = null
                this.textChangedHandler()
            }
        },
        getYear(date) {
            const parsedDate = parseISO(date, 'yyyy-MM-dd', new Date())
            if (!isValid(parsedDate)) return 'None recorded'

            return format(parsedDate, 'yyyy')
        },
        textChangedHandler() {
            this.$emit('update:search', this.searchText)
        }
    },
    computed: {
        ...mapState({
            clientId: state => state.app.clientId,
            loggedInUser: state => state.app.loggedInUser
        }),
        hideNoData() {
            return this.searchText == null || this.searchText === ''
        },
        computedClients() {
            return this.clientList
        },
    },
    watch: {
        searchText() {
            let searchId = this.searchText ?  Number(this.searchText)  : null
            // need to set it to -1 so the other search params can work, setting to -1 means searchId is ignored
            this.skipFiltered = searchId != null &&  !isNaN(searchId) && searchId > 0
            if (this.searchTimeout) clearTimeout(this.searchTimeout)
            this.textChangedHandler()
        },
    }
}
</script>
