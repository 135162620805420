<template>
    <div class="a-modal__add-case-note m-auto w-full md:max-w-screen-sm lg:max-w-960 xl:max-w-1250">
        <h1 class="text-black mb-2 text-xl lg:text-2xl text-center capitalize">
            {{ clientName }} - {{ getAddOrEditTitle() }} Case Note
        </h1>
        <v-form ref="addCaseNoteForm" v-model="isValid">
            <div class="max-h-modal overflow-y-auto">
                <div class="grid-cols-none block lg:grid lg:grid-cols-5 gap-x-4 mb-0 w-full px-4">
                    <div class="lg:col-span-3">
                        <div class="grid grid-cols-12 gap-x-4 mb-0 w-full">
                            <div class="col-span-3">
                                <EditableDatePicker
                                    v-model="interactionDate"
                                    :label="'Date of Interaction'"
                                    min="2010-01-01"
                                    :default-date="new Date()"
                                    :disabled="isViewMode"
                                    :disableFutureDate="true"
                                    :rules="[futureDateNotAllowed]"
                                />
                            </div>
                            <div class="col-span-3">
                                <EditableDatetimePicker
                                    label="Time of Interaction"
                                    type="time"
                                    v-model="caseNoteForm.interactionTime"
                                    :disabled="isViewMode"
                                    :isRequired="true"
                                    :format="formatTime"
                                />
                            </div>
                            <div class="col-span-6">
                                <!--Prefill to latest episode-->
                                <EpisodeDropdown
                                    ref="episodeDropdownForm"
                                    v-model="caseNoteForm.episodeId"
                                    :episodes="episodes"
                                    :is-view-mode="isViewMode"
                                />
                            </div>
                            <div class="col-span-5">
                                <v-select
                                    v-model="caseNoteForm.type"
                                    label="Type"
                                    :items="types"
                                    :disabled="isViewMode"
                                    :rules="[required]"
                                ></v-select>
                            </div>
                            <div class="col-span-4">
                                <v-select
                                    v-model="caseNoteForm.method"
                                    label="Method"
                                    :items="methods"
                                    :disabled="isViewMode"
                                    :rules="[required]"
                                ></v-select>
                            </div>
                            <div class="col-span-3">
                                <v-select
                                    label="Duration"
                                    v-model="caseNoteForm.duration"
                                    :items="durations"
                                    :disabled="isViewMode"
                                    :rules="[required]"
                                ></v-select>
                            </div>
                        </div>
                        <div class="grid grid-cols-12 gap-x-4 mb-0 w-full">
                            <div class="col-span-5">
                                <v-select
                                    v-model="caseNoteForm.venue"
                                    label="Venue"
                                    :items="venues"
                                    :disabled="isViewMode"
                                ></v-select>
                            </div>
                            <div class="col-span-5">
                                <v-checkbox
                                    hide-details
                                    :ripple="false"
                                    v-model="caseNoteForm.afterHours"
                                    label="After hours"
                                    :disabled="isViewMode"
                                ></v-checkbox>
                            </div>
                            <div class="col-span-5">
                                <v-select
                                    v-model="caseNoteForm.participants"
                                    label="Participants"
                                    :items="participants"
                                    :disabled="isViewMode"
                                ></v-select>
                            </div>
                            <div class="col-span-7">
                                <v-select
                                    class="col-span-2"
                                    v-model="caseNoteForm.nextStep"
                                    label="Next Step"
                                    :items="nextSteps"
                                    :disabled="isViewMode"
                                ></v-select>
                            </div>
                        </div>
                    </div>
                    <div class="lg:col-span-2">
                        <div class="v-application--is-ltr mt-2">
                            <v-textarea
                                outlined
                                v-model="caseNoteForm.caseNote"
                                class="col-span-4"
                                rows="8"
                                label="Enter case note"
                                :disabled="isViewMode"
                                :rules="[required]"
                            ></v-textarea>
                        </div>
                    </div>
                    <div class="lg:col-span-3">
                        <v-checkbox
                            hide-details
                            class="mt-0"
                            :ripple="false"
                            v-model="caseNoteForm.hasTreatmentEvent"
                            label="Treatment event occurred"
                            :disabled="isViewMode"
                            @change="hasTreatmentEventChanged"
                        ></v-checkbox>
                        <!--If checked-->
                        <div v-if="caseNoteForm.hasTreatmentEvent">
                            <v-select
                                v-model="caseNoteForm.treatmentEvents"
                                :items="events"
                                :disabled="isViewMode || this.caseNote.caseNoteId"
                                small-chips
                                deletable-chips
                                clearable
                                label="Event"
                                multiple
                                flat
                                :rules="caseNoteForm.hasTreatmentEvent ? [required] : []"
                            ></v-select>
                        </div>
                    </div>
                    <div class="col-span-5">
                        <v-checkbox
                            hide-details
                            :ripple="false"
                            v-model="caseNoteForm.hasBehaviourIncident"
                            label="Behaviour incident occurred"
                            :disabled="isViewMode"
                            @change="hasBehaviourIncidentChanged"
                        ></v-checkbox>
                        <!--If checked-->
                        <div v-if="caseNoteForm.hasBehaviourIncident">
                            <BehaviourIncidentTable
                                :disabled="isViewMode"
                                :table-data="caseNoteForm.behaviourIncidents"
                                :is-view="isViewMode"
                                :isEditMode="isEditMode"
                                @table-data-change="behaviourIncidentsChanged"
                            />
                        </div>
                    </div>
                </div>
            </div>
            <br />
            <v-alert
                v-if="!isValid && message || showMessageAlert"
                :closable="false"
                :type="type"
                class="text-sm"
                border="top"
                dark
                transition="scale-transition"
                :value="showMessageAlert"
            >
                {{ message }}
                <div v-if="type === 'error'">{{ errorReason }}</div>
            </v-alert>
            <div class="flex flex-row justify-center mt-4">
                <v-btn depressed class="mr-4 w-40" data-cy="cancelHandler" @click="cancelHandler">{{
                    saveComplete ? 'Close' : 'Cancel'
                }}</v-btn>
                <v-btn
                    v-if="!isViewMode"
                    depressed
                    color="primary"
                    class="v-btn--flat inline w-40"
                    :disabled="saving || saveComplete"
                    @click="submitHandler"
                >{{ getAddOrEditTitle(true) }} Case Note</v-btn>
            </div>
        </v-form>
    </div>
</template>

<script>
import { mapStores } from 'pinia'
import { mapState } from 'vuex'
import CaseNoteTable from '@/components/case-notes/partials/CaseNoteTable'
import EditableDatePicker from '@/components/shared/EditableDatePicker'
import EditableDatetimePicker from '@/components/partials/EditableDatetimePicker'
import BehaviourIncidentTable from '@/components/case-notes/partials/BehaviourIncidentTable'
import { format, parseISO } from 'date-fns'
import snakecaseKeys from 'snakecase-keys'
import INSERT_CASE_NOTE from '@/graphql/mutations/insertCaseNote.gql'
import camelcaseKeys from 'camelcase-keys'
import MessageDialog from '@/components/shared/mixins/messageDialog'
import EpisodeDropdown from '@/components/shared/EpisodeDropdown'
import { FormRules } from '@/components/shared/mixins/formMixins'
import UPDATE_CASE_NOTE from '@/graphql/mutations/updateCaseNote.gql'
import { useModalStore } from '@/stores/useModalStore'

export default {
    name: 'ModalAddCaseNote',
    components: {
        BehaviourIncidentTable,
        CaseNoteTable,
        EpisodeDropdown,
        EditableDatePicker,
        EditableDatetimePicker
    },
    mixins: [MessageDialog, FormRules],
    data() {
        return {
            message: '',
            type: '',
            errorReason: '',
            isAdmin: false,
            isValid: true,
            dateMenu: false,
            interactionDate: null,
            interactionTime: null,
            saving: false,
            saveComplete: false,
            showMessageAlert: false,
            defaultAddCaseNote: {
                interactionDate: null,
                interactionTime: null,
                episode: '',
                type: '',
                method: '',
                venue: '',
                duration: '',
                afterHours: false,
                participants: '',
                nextStep: '',
                caseNote: '',
                hasTreatmentEvent: false,
                hasBehaviourIncident: false,
                treatmentEvents: [],
                behaviourIncidents: []
            },
            formatTime: 'HH:mm'
        }
    },
    mounted() {
        this.interactionDate = this.caseNoteForm.interactionDate
        this.interactionTime = this.caseNoteForm?.interactionTime
        if (!this.interactionDate) this.interactionDate = format(new Date(), 'yyyy-MM-dd')
        if (!this.interactionTime) this.interactionTime = format(new Date(), 'HH:mm')
    },
    methods: {
        cancelHandler() {
            this.modalStore.closeModal()
        },
        submitHandler() {
            this.type = 'warning'
            this.message = ''

            if (this.behaviourIncidentsActive()) {
                this.message =
                    'There are behaviour incidents still active, please save or cancel before continuing'
            }
            if (this.message) {
                this.isValid = false
                // this.showMessage({duration: 3000})
                return
            }
            const formsToValidate = [
                this.$refs.episodeDropdownForm.validate(),
                this.$refs.addCaseNoteForm.validate()
            ]
            Promise.all(formsToValidate)
                .then((resolved) => {
                    if (resolved.indexOf(false) !== -1) return Promise.reject('invalid data')
                    this.saving = true
                    const behaviourIncidents = []
                    // commit mutation
                    const caseNote = { ...this.caseNoteForm }
                    delete caseNote.typename

                    if (caseNote.behaviourIncidents && caseNote.behaviourIncidents.length) {
                        caseNote.behaviourIncidents.forEach((bi) => {
                            if (bi.isNew) {
                                delete bi.behaviourIncidentId
                            }
                            delete bi.active
                            delete bi.isNew
                            const behaviourIncident = snakecaseKeys(bi, { deep: true })
                            behaviourIncidents.push(behaviourIncident)
                        })
                        // const behaviourIncidents = snakecaseKeys(caseNote.behaviourIncidents, {deep: true})
                        delete caseNote.behaviourIncidents
                    } else {
                        caseNote.hasBehaviourIncident = false
                    }

                    const treatmentEvents =
                        caseNote.treatmentEvents?.map((event) => ({ event })) ?? []
                    caseNote.hasTreatmentEvent = caseNote.treatmentEvents?.length > 0
                    delete caseNote.treatmentEvents
                    delete caseNote.created

                    const convertedCaseNote = snakecaseKeys(caseNote || {}, {
                        deep: true,
                        exclude: ['__typename']
                    })

                    convertedCaseNote.treatment_events = {
                        data: snakecaseKeys(treatmentEvents, { deep: true }),
                        on_conflict: {
                            constraint: 'treatment_event_pkey',
                            update_columns: ['event']
                        }
                    }
                    convertedCaseNote.behaviour_incidents = {
                        data: behaviourIncidents,
                        on_conflict: {
                            constraint: 'behaviour_incident_pkey',
                            update_columns: [
                                'behaviour',
                                'incident_code',
                                'incident_report',
                                'outcome',
                                'response_code',
                                'time'
                            ]
                        }
                    }

                    let queryObject = {}
                    if (this.$can('edit', 'case_note') && this?.caseNoteForm?.caseNoteId) {
                        convertedCaseNote.case_note_id = this.caseNoteForm.caseNoteId
                        convertedCaseNote.behaviour_incidents.data.forEach(
                            (behaviourIncidents) => delete behaviourIncidents.typename
                        )
                        delete convertedCaseNote.treatment_events
                        delete convertedCaseNote.created_by_id
                        queryObject = {
                            mutation: UPDATE_CASE_NOTE,
                            variables: {
                                caseNotes: [convertedCaseNote]
                            }
                        }
                    } else {
                        queryObject = {
                            mutation: INSERT_CASE_NOTE,
                            variables: {
                                caseNotes: [convertedCaseNote]
                            }
                        }
                    }
                    return this.$apollo
                        .mutate({
                            ...queryObject
                        })
                        .then(async ({ data: { caseNote } }) => {
                            // console.log('APOLLO mutation indicatorResponse, response:', data)
                            const caseNoteCamelCase = camelcaseKeys(caseNote, { deep: true })
                            const mappedCaseNoteReturned = caseNoteCamelCase.returning[0]
                            console.log('caseNote', mappedCaseNoteReturned)
                            this.saving = false
                            this.message =
                                'Saved case note successfully, you can now close the modal'
                            this.type = 'success'

                            // clearing cache
                            await this.$apollo.provider.defaultClient.resetStore()

                            this.saveComplete = true
                            // // this.cancelHandler()
                            // setTimeout(() => {
                            //     this.cancelHandler()
                            // }, 5000)
                        })
                        .catch((error) => {
                            this.message = `Add/Edit failed`
                            this.errorReason = error.message
                            console.log('===== error', error)
                            this.type = 'error'
                        })
                        .finally(() => {
                            this.saving = false
                            this.showMessageAlert = true
                            // this.showMessage({ duration: 5000 })
                        })
                })
                .catch(() => {
                    // this should always return false since it is checking if the form is valid
                    this.isValid = false
                    this.message =
                        'Please check you have completed all required fields. Required fields will be highlighted in red above'
                })
        },
        save(date) {
            this.$refs.dateMenu.save(date)
        },
        getAddOrEditTitle(isSaving) {
            if (this.caseNoteForm.caseNoteId) {
                if (isSaving) return 'Save'
                if (this.isViewMode) {
                    return 'View'
                } else {
                    return 'Edit'
                }
            }
            return 'Add'
        },
        behaviourIncidentsChanged(behaviourIncidents) {
            console.log(behaviourIncidents)
            this.caseNoteForm.behaviourIncidents = behaviourIncidents
            this.message = ''
        },
        behaviourIncidentsActive() {
            if (
                this.caseNoteForm.behaviourIncidents &&
                this.caseNoteForm.behaviourIncidents.length
            ) {
                for (let behaviourIncident of this.caseNoteForm.behaviourIncidents) {
                    if (behaviourIncident.active) return true
                }
            }
            return false
        },
        treatmentEventsActive() {
            if (this.caseNoteForm.treatmentEvents && this.caseNoteForm.treatmentEvents.length) {
                for (let treatmentEvent of this.caseNoteForm.treatmentEvents) {
                    if (treatmentEvent.active) return true
                }
            }

            return false
        },
        hasTreatmentEventChanged() {
            if (!this.caseNoteForm.hasTreatmentEvent) {
                this.caseNoteForm.treatmentEvents = []
            }
        },
        hasBehaviourIncidentChanged() {
            if (!this.caseNoteForm.hasBehaviourIncident) {
                this.caseNoteForm.behaviourIncidents = []
            }
        }
    },
    computed: {
        ...mapStores(useModalStore),
        ...mapState({
            staticChoices: (state) => state.app.staticChoices
        }),
        computedInteractionDate() {
            if (!this.interactionDate) return ''
            return format(parseISO(this.interactionDate), 'dd/MM/yyyy')
        },
        durations() {
            return this.staticChoices['caseNote.duration'] || []
        },
        types() {
            return this.staticChoices['caseNote.type'] || []
        },
        methods() {
            return this.staticChoices['caseNote.method'] || []
        },
        venues() {
            return this.staticChoices['caseNote.venue'] || []
        },
        nextSteps() {
            return this.staticChoices['caseNote.nextStep'] || []
        },
        participants() {
            return this.staticChoices['caseNote.participant'] || []
        },
        episodes() {
            return this.modalStore?.data ? this.modalStore.data.episodes : []
        },
        episodeNumber() {
            //this should total number of episodes with status PROGRAM OPEN
            return this.episodes.length
        },
        caseNote() {
            return this.modalStore.data ? this.modalStore.data.caseNote : {}
        },
        isViewMode() {
            if (this.$can('edit', 'case_note') && this?.caseNoteForm?.caseNoteId) return false
            return (this.caseNoteForm && !!this.caseNoteForm.caseNoteId) || this.isAdmin
        },
        isEditMode() {
            if (this.$can('edit', 'case_note') && this?.caseNoteForm?.caseNoteId) return true
            return false
        },
        caseNoteForm: {
            get() {
                return this.caseNote || this.defaultAddCaseNote
            },
            set(caseNoteForm) {
                console.log(caseNoteForm)
            }
        },
        clientName() {
            return this.modalStore.data ? this.modalStore.data.name : ''
        },
        events() {
            return this.staticChoices['treatmentEvent.events']
        }
    },
    watch: {
        interactionDate() {
            this.caseNoteForm.interactionDate = this.interactionDate
        },
        caseNoteForm: {
            handler() {
                this.interactionDate = this.caseNoteForm.interactionDate
            },
            deep: true
        },
        interactionTime() {
            this.caseNoteForm.interactionTime = this.interactionTime
        }
    }
}
</script>
