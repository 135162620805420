<template>
    <component
        :is="renderForm"
        :model="isValid"
        ref="formInput"
        class="c-editable-cell"
        @submit.prevent=""
    >
        <component
            :is="renderEditableComponent"
            v-model="formInput.inputValue"
            row
            wrap
            @change="radioValueChanged"
        >
            <template v-slot:label>
                <div class="text-grey-1 text-sm">{{label}}</div>
            </template>
            <component
                v-for="(sub, index) of subComponents"
                :key="`sub-${index}`"
                :is="subComponent"
                v-bind="sub"
                :ripple="false"
            ></component>
        </component>
    </component>
</template>

<script>
export default {
    name: "RadioGroup",
    props: {
        value: {
            type: Boolean,
            default: null
        },
        subComponents: {
            type: Array,
            default: () => []
        },
        editableComponent: {
            type: String,
            default: 'v-radio-group'
        },
        subComponent: {
            type: String,
            default: 'v-radio'
        },
        defaultValue: {
            type: Boolean,
            default: null
        },
        label: {
            type: String,
            default: ''
        }
    },
    data() {
        return {
            isValid: true,
            focused: false,
            formInput: {inputValue: null},
        }
    },
    mounted() {
        this.formInput.inputValue = this.value || this.defaultValue
    },
    methods: {
        validate() {
            this.isValid = this.$refs.formInput.validate()
            return this.isValid
        },
        clearValidate(){
            this.isValid = true
            return this.isValid
        },
        radioValueChanged() {
            this.$emit('input', this.formInput.inputValue)
            this.$emit('value-changed', true)
        }
    },
    computed: {
        renderEditableComponent() {
            return this.editableComponent
        },
        renderForm() {
            return 'v-form'
        },
    },
    watch: {
        value() {
            this.formInput.inputValue = this.value
        },
        'formInput.inputValue': function() {
            this.$emit('input', this.formInput.inputValue)
        },
    }
}
</script>
