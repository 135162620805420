import { format, parse, isValid, differenceInCalendarYears } from 'date-fns'

const FormBuilderMethods = {
    methods: {
        isComplete(item) {
            let hasSubCompleteSvg
            if (item.mappedQuestionSets) {
                for (let subQuestion of item.mappedQuestionSets) {
                    hasSubCompleteSvg = this.isComplete(subQuestion)
                    if (!hasSubCompleteSvg) return hasSubCompleteSvg
                }
            }
            if (!hasSubCompleteSvg && (!item.questions || item.questions.length === 0)) return true
        
            console.log('questions checking', item.questions)
        
            for (let question of item.questions) {
                if (!question.value) return false
            }
            return true
        },
        getTotalQuestions(questionSets) {
            let total = 0
            for (let questionSet of questionSets) {
                if (!questionSet.mappedQuestionSets) total += questionSet.questions ? questionSet.questions.length : 0
                else total += this.getTotalQuestions(questionSet.mappedQuestionSets)
            }
            console.log('total number of qusetions', total)
            return total
        },
        getNumberComplete(questionSets) {
            let total = 0
            for (let questionSet of questionSets) {
                if (!questionSet.mappedQuestionSets) {
                    total += questionSet.questions ? questionSet.questions.filter(q => q.value != null && q.value !== '').length : 0
                }
                else total += this.getNumberComplete(questionSet.mappedQuestionSets)
            }
            console.log('number of completed qusetions', total)
            return total
        },
    },
}

const FormRules = {
    methods: {
        required: value => !!value || 'Required.',
        requiredNoText: value => !!value || '',
        validDob: (value) => {
            if (!value) return false
            const parsed = parse(value, 'dd/MM/yyyy', new Date())
            if (!isValid(parsed)) return 'Must be a valid date'
            const dateDiff = differenceInCalendarYears(new Date(), parsed)
            return dateDiff < 50 || 'DOB must be valid'
        },
        validProgramStart: (value) => {
            if (!value) return false
            const parsed = parse(value, 'dd/MM/yyyy', new Date())
            if (!isValid(parsed)) return 'Must be a valid date'
            const dateDiff = differenceInCalendarYears(new Date(), parsed)
            return dateDiff < 50 || 'Invalid Program Start date'
        },
        min: v => v.length >= 8 || 'Min 8 characters',
        max: v => v.length <= 16 || 'Max 16 characters',
        numbersOnly: v => {
            if (!v || !v.trim() || !isNaN(parseInt(v)) && v >= 0) return true
            return 'Value has to be a number'
        },
        timeOnly: v => {
            const timeRegex = /((1[0-2]|0?[1-9]):([0-5][0-9]) ?([AaPp][Mm]))/
            
            if (!v || !v.trim() || timeRegex.test(v)) return true
            return 'Value should be in form hh:mm am|pm'
        },
        dateFormat: v => {
            if (!v) return true

            const today = new Date()
            const dateObj = parse(v, 'dd/MM/yyyy', today)
            if (isValid(dateObj)) return true

            return 'Invalid date. (Format: dd/mm/yyyy)'
        },
        futureDateNotAllowed: v => {
            if (!v) return true

            const today = new Date()
            const dateObj = parse(v, 'dd/MM/yyyy', today)
            if (!isValid(dateObj)) return 'Invalid date. (Format: dd/mm/yyyy)'

            const date = format(dateObj, 'yyyy-MM-dd', today)
            const currentDate = format(today, 'yyyy-MM-dd', today)

            if (currentDate >= date) return true

            return 'Future date is not allowed.'
        }
    }
}

const ALLOWED_KEYCODE = [8, 37, 39, 46]

const FormEvents = {
    methods: {
        timeOnlyEvent(e) {
            const val = e.key
            console.log('key pressed', e)
            if (!/[0-9:AaPpMm ]/.test(val) && ALLOWED_KEYCODE.indexOf(e.keyCode) === -1) {
                e.preventDefault()
            }
        },
        numbersOnlyEvent(e) {
            // we try and convert it with the '+' operator
            const val = +e.key
            // if it's not NaN, then we have a number key press
            console.log('key pressed', e)
            if (isNaN(val) && ALLOWED_KEYCODE.indexOf(e.keyCode) === -1) {
                e.preventDefault()
            }
        }
    }
}

export {
    FormBuilderMethods,
    FormRules,
    FormEvents
}
