<template>
    <v-navigation-drawer
        v-model="localDrawer"
        absolute
        temporary
        :width="280"
    >
        <v-list-item class="px-2 menuArea">
            <v-list-item-icon class="menuButton ml-0 mr-0">
                <v-btn
                    icon
                    @click.stop="localDrawer = !localDrawer"
                >
                    <fa-icon icon="bars" class="text-black" />
                </v-btn>
            </v-list-item-icon>
                <img src="@/assets/images/logo.png" alt="" class="navLogo block" />
        </v-list-item>

        <v-list dense nav>
            <v-list-item :class="{'active': isActive('Dashboard')}" id="dashboard" :to="{name: 'dashboard'}">
                <v-list-item-icon>
                    <DashboardSvg class="text-primary-light" />
                </v-list-item-icon>
                <v-list-item-content>
                    <v-list-item-title class="px-2 text-left font-bold">Dashboard</v-list-item-title>
                </v-list-item-content>
            </v-list-item>
            <v-list-item
                v-if="!isNewUser"
                :class="{'active': isActive('Client/List')}"
                id="practices"
                :to="{name: 'client-list'}"
                data-cy="goToClientListMenuItem"
            >
                <v-list-item-icon>
                    <ClientsSvg class="text-primary-light" />
                </v-list-item-icon>
                <v-list-item-content>
                    <v-list-item-title class="px-2 text-left font-bold">Young People</v-list-item-title>
                </v-list-item-content>
            </v-list-item>
            <v-list-item :class="{'active': isActive('Episode/List')}" id="episodes" :to="{name: 'episode-list'}">
                <v-list-item-icon>
                    <EpisodesSvg class="text-primary-light" />
                </v-list-item-icon>
                <v-list-item-content>
                    <v-list-item-title class="px-2 text-left font-bold">Episodes</v-list-item-title>
                </v-list-item-content>
            </v-list-item>
            <v-list-item :class="{'active': isActive('Groups')}" id="episodes" :to="{name: 'groups'}">
                <v-list-item-icon>
                    <EpisodesSvg class="text-primary-light" />
                </v-list-item-icon>
                <v-list-item-content>
                    <v-list-item-title class="px-2 text-left font-bold">Groups</v-list-item-title>
                </v-list-item-content>
            </v-list-item>
            <!-- TODO: need to show/hide based on the users access - need to have at least 1 stuni site/section -->
            <v-list-item :class="{'active': isActive('Admin/StuniWorkshops')}" id="stuni-workshops" :to="{name: 'workshops'}">
                <v-list-item-icon>
                    <EpisodesSvg class="text-primary-light" />
                </v-list-item-icon>
                <v-list-item-content>
                    <v-list-item-title class="px-2 text-left font-bold">Stuni Workshops</v-list-item-title>
                </v-list-item-content>
            </v-list-item>
            <v-list-item :class="{'active': isActive('Admin/StuniCampusCheckins')}" id="stuni-campus-checkins" :to="{name: 'campus-checkins'}">
                <v-list-item-icon>
                    <EpisodesSvg class="text-primary-light" />
                </v-list-item-icon>
                <v-list-item-content>
                    <v-list-item-title class="px-2 text-left font-bold">Stuni Campus Checkins</v-list-item-title>
                </v-list-item-content>
            </v-list-item>
            <v-list-item
                id="reports"
                v-if="$can('view', 'reports')"
                :class="{'active': isActive('Reports')}"
                :to="{name: 'reports'}"
            >
                <v-list-item-icon>
                    <ReportSvg class="text-primary-light" />
                </v-list-item-icon>
                <v-list-item-content>
                    <v-list-item-title class="px-2 text-left font-bold">Reports</v-list-item-title>
                </v-list-item-content>
            </v-list-item>

            <!--<v-divider></v-divider>-->

            <!--- Below is only for super admin (god) user -->
            <v-list-item :class="{'active': isActive('Clinical')}" id="clinical" :to="{name: 'clinical'}"
                         v-if="$can('view', 'dasha')"
            >
                <v-list-item-icon>
                    <ClinicalSvg class="text-primary-light" />
                </v-list-item-icon>
                <v-list-item-content>
                    <v-list-item-title class="px-2 text-left font-bold">Clinical</v-list-item-title>
                </v-list-item-content>
            </v-list-item>
            <v-list-item
                id="admin"
                v-if="$can('view', 'staff_management')"
                :class="{'active': isActive('Admin')}"
                :to="{name: 'user-list'}"
            >
                <v-list-item-icon>
                    <CogSvg class="text-primary-light" />
                </v-list-item-icon>
                <v-list-item-content>
                    <v-list-item-title class="px-2 text-left font-bold">Staff Management</v-list-item-title>
                </v-list-item-content>
            </v-list-item>
            <v-list-item
                id="stuni-management"
                v-if="$can('view', 'stuni_user_management')"
                :class="{'active': isActive('StuniManagement')}"
                :to="{name: 'stuni-management'}"
            >
                <v-list-item-icon>
                    <CogSvg class="text-primary-light" />
                </v-list-item-icon>
                <v-list-item-content>
                    <v-list-item-title class="px-2 text-left font-bold">TSU App Management</v-list-item-title>
                </v-list-item-content>
            </v-list-item>
        </v-list>
    </v-navigation-drawer>
</template>

<script>
import { computed, ref } from '@vue/composition-api'
import DashboardSvg from '@/assets/icons/nav/icons_dashboard.svg'
import ClientsSvg from '@/assets/icons/nav/icons_clients.svg'
import EpisodesSvg from '@/assets/icons/nav/icons_episodes.svg'
import ReportSvg from '@/assets/icons/nav/icons_reports.svg'
import ClinicalSvg from '@/assets/icons/nav/icons_clinicaldata.svg'
import FinanceSvg from '@/assets/icons/nav/icons_finance.svg'
import CogSvg from '@/assets/icons/nav/icons_cog.svg'
import { AuthMixin } from '@/components/shared/mixins/authHelpers'

export default {
    name: "NavigationMenu",
    components: {
        DashboardSvg, ClientsSvg, EpisodesSvg, ReportSvg, ClinicalSvg, FinanceSvg, CogSvg
    },
    mixins: [ AuthMixin ],
    props: {
        value: {
            type: Boolean,
            default: null
        },
    },
    setup(props, { root, emit }) {
        const route = root.$route

        const isActive = (url) => route.path.includes(url)
        const hasAccessToStuni = computed(() => true)
        const items = ref([
            { title: 'Home', icon: 'mdi-home-city' },
            { title: 'My Account', icon: 'mdi-account' },
            { title: 'Users', icon: 'mdi-account-group-outline' },
        ])
        const localDrawer = computed({
            get() {
                return props.value
            },
            set(drawer) {
                emit('input', drawer)
            }
        })


        return { hasAccessToStuni, items, localDrawer, isActive }
    }
}
</script>
