import { format, parse, isValid, differenceInCalendarYears } from 'date-fns'

export const required = value => !!value || 'Required.'
export const requiredNoText = value => !!value || ''
export const validDob = (value) => {
    if (!value) return false
    const parsed = parse(value, 'dd/MM/yyyy', new Date())
    if (!isValid(parsed)) return 'Must be a valid date'
    const dateDiff = differenceInCalendarYears(new Date(), parsed)
    return dateDiff < 50 || 'DOB must be valid'
}

export const validProgramStart = (value) => {
    if (!value) return false
    const parsed = parse(value, 'dd/MM/yyyy', new Date())
    if (!isValid(parsed)) return 'Must be a valid date'
    const dateDiff = differenceInCalendarYears(new Date(), parsed)
    return dateDiff < 50 || 'Invalid Program Start date'
}
export const min = v => v.length >= 8 || 'Min 8 characters'
export const max = v => v.length <= 16 || 'Max 16 characters'
export const max100Textarea = v => v.length <= 100 || 'Max 100 characters'
export const numbersOnly = v => {
    if (!v || !v.trim() || !isNaN(parseInt(v)) && v >= 0) return true
    return 'Value has to be a number'
}
export const timeOnly = v => {
    const timeRegex = /((1[0-2]|0?[1-9]):([0-5][0-9]) ?([AaPp][Mm]))/

    if (!v || !v.trim() || timeRegex.test(v)) return true
    return 'Value should be in form hh:mm am|pm'
}
export const dateFormat = v => {
    if (!v) return true

    const today = new Date()
    const dateObj = parse(v, 'dd/MM/yyyy', today)
    if (isValid(dateObj)) return true

    return 'Invalid date. (Format: dd/mm/yyyy)'
}
export const futureDateNotAllowed = v => {
    if (!v) return true

    const today = new Date()
    const dateObj = parse(v, 'dd/MM/yyyy', today)
    if (!isValid(dateObj)) return 'Invalid date. (Format: dd/mm/yyyy)'

    const date = format(dateObj, 'yyyy-MM-dd', today)
    const currentDate = format(today, 'yyyy-MM-dd', today)

    if (currentDate >= date) return true

    return 'Future date is not allowed.'
}