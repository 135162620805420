<template>
    <div class="m-auto w-screen-sm">
        <h1 class="text-black mb-2 text-xl lg:text-2xl text-center">Approve Stuni User as a Client</h1>
        <div>
            <div class="text-center">
                Would you like to approve TSU user as a client?
            </div>
            <v-form v-model="isValid" ref="form" class="search">
                <Search
                    ref="selectClient"
                    class="my-8 mx-4"
                    :rules="[required]"
                    :clear-text="false"
                    :has-student="true"
                    @client-id-changed="selectClient"
                >
                    <template v-slot:label-inner>
                        <span :class="computedValidate ? '' : 'error--text'">{{"Enter client name or ID"}}</span>
                    </template>
                </Search>
                <v-textarea
                    outlined
                    v-model="comment"
                    class="mt-8 mx-4"
                    rows="4"
                    label="Tell us why approval was granted"
                    :rules="[required]"
                ></v-textarea>
            </v-form>
            <div class="flex flex-row justify-center mt-4">
                <v-btn
                    depressed
                    class="mr-4 w-40"
                    data-cy="cancelHandler"
                    :class="{'pointer-events-none': loading}"
                    @click="cancelHandler"
                >Cancel</v-btn>
                <v-btn
                    depressed
                    color="primary"
                    class="v-btn--flat inline w-200"
                    :class="{'pointer-events-none': loading}"
                    @click="submitHandler"
                >{{approveText}}</v-btn>
            </div>
        </div>
    </div>
</template>

<script>
import {mapState} from 'vuex'
import {FormRules} from '@/components/shared/mixins/formMixins'
import Search from '@/components/clients/partials/Search'
import { UserHelper } from '@/components/shared/mixins/userMixin'

export default {
    name: 'ModalConfirmStuniApproveAsClient',
    mixins: [FormRules, UserHelper],
    components: {Search},
    data() {
        return {
            clientId: null,
            isValid: true,
            comment: '',
            loading: false
        }
    },
    mounted() {
        console.log('user found =============', this.data?.user)
        this.clientId = this.data?.user?.userId
        this.$root.$on('modal-loading', this.loadingModalHandler)
    },
    methods: {
        loadingModalHandler(loading) {
            this.loading = loading
        },
        submitHandler() {
            if (!this.$refs.form?.validate()) return

            this.loading = true
            let data = this.data
            data.variables.clientId = this.clientId
            data.variables.comment = this.comment

            console.log(`ClientId: ${this.clientId}`)
            this.$root.$emit('change-user-status', data)
            this.closeModal()
        },
        selectClient(id) {
            this.clientId = id
        },
        validate() {
            return this.$refs.form?.validate()
        }
    },
    computed: {
        ...mapState({
            data: state => state.app.modal.componentData
        }),
        fullName() {
            const firstName = this.user.firstName
            const lastName = this.user.lastName
            return lastName && firstName ? `${firstName} ${lastName}` : 'Stuni user'
        },
        user() {
            return this.data?.user?.user || {}
        },
        computedValidate() {
            return this.validate()
        },
        approveText() {
            return this.loading ? 'Please wait...' : 'Approve as client'
        }
    },
    destroyed() {
        this.$root.$off('modal-loading')
    }
}
</script>
