const AuthMixin =  {
    computed: {
        isStaff() {
            return this.$auth.user && this.$auth.user['staff']
        },
        isSurveyor() {
            return this.$auth.user && this.$auth.user['surveyor']
        },
        isAdmin() {
            return this.$auth.user && this.$auth.user['admin']
        },
        hasSinglePractice() {
            console.log('has single practice', this.$auth.user['singlePractice'])
            return this.$auth.user ? this.$auth.user['singlePractice'] || false : false
        },
        isNewUser() {
            return this.$auth.user ? this.$auth.user['newUser'] || false : false
        },
        userId() {
            return this.$auth.user ? this.$auth.user.userId : null
        },
    }
}

const AuthUtilities = {
    setAuthCookies(responseData) {
        // TODO: maybe remove cookies if this is null
        if (!responseData) return

        let csrfToken = responseData.csrftoken,
            connect2SessionId = responseData.sessionid,
            _cookies = this.$cookies || this.Vue.$cookies

        if (connect2SessionId) _cookies.set(process.env.VUE_APP_COOKIE_ID, connect2SessionId, process.env.VUE_APP_COOKIE_TIMEOUT, '', process.env.VUE_APP_COOKIE_DOMAIN)
        if (csrfToken) _cookies.set(process.env.VUE_APP_COOKIE_CSRF, csrfToken, process.env.VUE_APP_COOKIE_TIMEOUT, '', process.env.VUE_APP_COOKIE_DOMAIN)
    },
    clearAuthCookies() {
        const _cookies = this.$cookies || this.Vue.$cookies
        _cookies.remove(process.env.VUE_APP_COOKIE_ID, '', process.env.VUE_APP_COOKIE_DOMAIN)
        _cookies.remove(process.env.VUE_APP_COOKIE_CSRF, '', process.env.VUE_APP_COOKIE_DOMAIN)
    }
}

export {
    AuthMixin,
    AuthUtilities
}
