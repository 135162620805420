<template>
    <v-form v-model="isValid" ref="form">
        <div class="grid grid-cols-3 gap-x-4 w-full">
            <div class="col-span-1">
                <v-text-field
                    label="First name"
                    v-model="form.firstName"
                    :rules="[required]"
                    :readonly="readOnly"
                ></v-text-field>
            </div>
            <div class="col-span-1">
                <v-text-field
                    label="Last name"
                    v-model="form.lastName"
                    :rules="[required]"
                    :readonly="readOnly"
                ></v-text-field>
            </div>
            <div class="col-span-1">
                <v-select
                    :items="userRoles"
                    v-model="form.role"
                    label="Role"
                    :rules="[required]"
                    :readonly="readOnly"
                ></v-select>
            </div>
        </div>
        <div class="grid grid-cols-3 gap-x-4 w-full">
            <div class="col-span-1">
                <v-text-field
                    label="Email"
                    v-model="form.email"
                    :rules="[required]"
                    :readonly="readOnly"
                ></v-text-field>
            </div>
            <div class="col-span-1">
                <PhoneNumber
                    name="phone"
                    v-model="phone"
                    ref="phone"
                    :readOnly="readOnly"
                />
            </div>
        </div>
    </v-form>
</template>

<script>
import {mapState} from 'vuex'
import {FormRules} from '@/components/shared/mixins/formMixins'
import PhoneHelper from '@/components/shared/mixins/phoneHelper'

export default {
    name: 'UserForm',
    mixins: [FormRules],
    props: {
        value: {
            type: Object,
            required: true
        },
        readOnly: {
            type: Boolean,
            default: false
        }
    },
    data() {
        return {
            isValid: true,
            phone: {
                countryCode: 'AU',
                phone: ''
            }
        }
    },
    methods: {
        validate() {
            this.isValid = this.$refs.form.validate()
            return this.isValid
        }
    },
    computed: {
        ...mapState({
            staticChoices: state => state.app.staticChoices,
            siteSections: state => state.app.siteSections
        }),
        userStatuses() {
            return this.staticChoices['user.statuses']
        },
        userRoles() {
            return this.staticChoices['user.roles']
        },
        form() {
            return this.value ?? {}
        }
    },
    watch: {
        form() {
            this.phone = PhoneHelper.parse(this.form.phone)
        },
        phone: {
            handler() {
                this.form.phone = this.phone?.phone || ''
            },
            deep: true
        }
    }
}
</script>
