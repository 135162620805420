<template>
    <div class="global-header l-box-shadow w-full flex justify-between items-center">
        <div>
            <div class="menuButton inline-block">
                <v-btn
                    icon
                    v-if="!hideMenu"
                    @click.stop="toggleDrawer"
                    data-cy="menuButton"
                >
                    <fa-icon icon="bars" class="text-black" />
                </v-btn>
            </div>

            <div class="global-header__logo inline-block align-middle">
                <img src="@/assets/images/logo.png" alt="" class="block" />
            </div>
        </div>
        <Search
            v-if="!hideMenu"
            class="pr-4"
            label="Enter client name or ID"
            :search.sync="search"
            :showMore.sync="showMore"
            :show-more-changed="showMoreHandler"
            @client-id-changed="goToManageClient"
        >
            <template v-slot:label-inner>
                <span>Enter client name or ID</span>
            </template>
            <template v-slot:append-item>
                <div class="flex items-center w-full px-4 my-2">
                    <v-btn
                        depressed
                        small
                        color="success"
                        class="mr-4 v-btn--flat min-w-0"
                        @click.prevent="confirmAddClient"
                    >
                        <fa-icon icon="plus" class="mr-1" />new client
                    </v-btn>
                    <div v-if="showMore">
                        <v-btn
                            depressed
                            small
                            color="primary"
                            class="v-btn--flat min-w-0"
                            @click.prevent="showMoreResults"
                        >See All results <fa-icon icon="chevron-right" class="ml-1" /></v-btn>
                    </div>
                </div>
            </template>
        </Search>
        <div class="flex flex-row">
            <ProfileDisplay v-if="!hideProfile" :hide-name="hideMenu" />
        </div>
    </div>
</template>

<script>
import ProfileDisplay from './ProfileDisplay'
import Search from '@/components/clients/partials/Search'
import { mapState } from 'vuex'

export default {
    name: 'GlobalHeader',
    components: {
        Search,
        ProfileDisplay
    },
    props: {
        value: {
            type: Boolean,
            default: null
        },
        hideMenu: {
            type: Boolean,
            default: false
        },
        hideProfile: {
            type: Boolean,
            default: false
        }
    },
    data() {
        return  {
            drawer: null,
            search: '',
            showMore: false,
        }
    },
    methods: {
        showMoreHandler(showMore) {
            this.showMore = showMore
            console.log('show more changed to this============ ', this.showMore)
        },
        toggleDrawer() {
            this.$emit('input', !this.value)
        },
        goToManageClient(id) {
            // first we check if the current route, if we aren't on 'manage-client', then we go there, otherwise we emit the client id
            this.$store.commit('SET_SEARCH_TEXT', '')
            this.clearSearch()
            // check to see if the ID we have is the same as the current page, if it is, then ignore
            if (Number(this.clientId) !== Number(id)) {
                this.$store.commit('SET_CURRENT_EPISODE', null)
                this.$store.commit('SET_ASSIGNED_TO_ME', false)
            }
            this.$router.push({name: 'episodes', params: {clientId: id}}).catch(() => {})
        },
        clearSearch() {
            this.search = ''
        },
        showMoreResults() {
            const url = window.location.href.toLowerCase()
            this.$store.commit('SET_SEARCH_TEXT', this.search)
            if (url.indexOf('search') !== -1) {
                this.$router.replace({name: 'search-result', query: {search: this.search}})
                return
            }
            this.$router.push({name: 'search-result', query: {search: this.search}})
            this.clearSearch()
        },
        confirmAddClient() {
            this.$store.commit('MODAL_IS_VISIBLE', true)
            this.$store.commit('MODAL_DATA', {
                name: 'test',
                keepOpen: true,
            })
            this.$store.commit('MODAL_COMPONENT_NAME', 'ModalConfirmAddClient')
        },
    },
    computed: {
        ...mapState({
            clientId: state => state.app.clientId,
            loggedInUser: state => state.app.loggedInUser
        }),
    },
}
</script>

