<template>
    <div class="m-auto w-screen-sm">
        <h1 class="text-black mb-2 text-xl lg:text-2xl text-center">Delete Document</h1>
        <div class="text-center">
            Do you want to delete {{fileName}}?
        </div>
        <div class="flex flex-row justify-center mt-4">
            <v-btn
                depressed
                class="mr-4 w-40"
                data-cy="cancelHandler"
                @click="cancelHandler"
            >No</v-btn>
            <v-btn
                depressed
                color="primary"
                class="v-btn--flat inline w-40"
                @click="submitHandler"
            >Yes</v-btn>
        </div>
    </div>
</template>

<script>
import { mapState } from 'vuex'

export default {
    components: {},
    name: 'ModalConfirmDeleteDocument',
    data() {
        return {
            isValid: true
        }
    },
    methods: {
        cancelHandler() {
            this.$store.commit('MODAL_IS_VISIBLE', false)
        },
        submitHandler() {
            this.$store.commit('MODAL_IS_VISIBLE', false)
            this.$root.$emit('confirm-delete-document', this.data)
        },
    },
    computed: {
        ...mapState({
            data: state => state.app.modal.componentData
        }),
        fileName() {
            return this.data.name
        }
    }
}
</script>
