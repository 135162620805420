<template>
    <div class="m-auto w-screen-sm">
        <h1 class="text-black mb-2 text-xl lg:text-2xl text-center">Export Case Notes</h1>
        <div class="text-center py-3">
            Select case note type:
        </div>
        <div class="flex flex-row justify-center w-full px-4">
            <div class="py-5">
                <v-select
                    class="w-200"
                    :items="caseNoteTypes"
                    v-model="caseNoteType"
                    label="Type"
                ></v-select>
            </div>
        </div>
        <div class="flex flex-row justify-center mt-4">
            <v-btn
                depressed
                class="mr-4 w-20"
                :disabled="downloading"
                @click="cancelHandler"
            >Cancel</v-btn>
            <v-btn
                depressed
                color="primary"
                :disabled="downloading"
                class="inline w-30"
                @click="submitHandler"
            >{{ downloadButtonText }}</v-btn>
        </div>
    </div>
</template>

<script>
import { ref, computed } from '@vue/composition-api'
import { createNamespacedHelpers } from 'vuex-composition-helpers'
import { storeToRefs } from 'pinia'
import axiosInstance from '@/utils/axios'
import { downloadFile } from '@/utils/file.helpers'
import { useMessageStore } from '@/stores/useMessageStore'

const { useState } = createNamespacedHelpers('app')
const { useActions } = createNamespacedHelpers('')

export default {
    components: {},
    name: 'ModalExportCaseNotes',
    setup(_, { root }) {
        const $message = root.$message

        const { staticChoices, modal } = useState(['staticChoices', 'modal'])
        const { closeModal } = useActions(['closeModal'])
        const endpoint = '/reports/episode/:episodeId/generate_case_note_report_pdf/'
        const caseNoteType = ref('ALL')
        const downloading = ref(false)
        const messageStore = useMessageStore()
        const episodeId = computed(() => modal.value?.componentData?.episodeId)

        const { message, type } = storeToRefs(messageStore)
        const { showMessage, resetMessage } = messageStore

        const downloadButtonText = computed(() => {
            return !downloading.value ? `Download PDF` : 'Please wait...'
        })
        const caseNoteTypes = computed(() => [
            {label: 'All', text: 'All', value: 'ALL'},
            ...(staticChoices.value?.['caseNote.type'] || [])
        ])

        const cancelHandler = () => {
            closeModal()
        }

        const submitHandler = async () => {
            let closeModalAfter = true
            try {
                downloading.value = true
                const reportType = caseNoteType.value === 'ALL' ? null : caseNoteType.value
                const data = await axiosInstance.post(
                    endpoint.replace(':episodeId', episodeId.value),
                    { type: reportType },
                    {responseType: 'blob'}
                )
                downloadFile(data)
                message.value = 'Downloaded case note csv successfully.'
                type.value = 'success'
                closeModalAfter = true
            } catch (error) {
                message.value = 'Failed to download report, please wait a few moments and try again.'
                type.value = 'error'
                closeModalAfter = false
            } finally {
                downloading.value = false
                showMessage({duration: 5000}, $message)
            }

            if (closeModalAfter) closeModal()
        }

        return {
            caseNoteType,
            caseNoteTypes,
            downloading,
            downloadButtonText,
            cancelHandler,
            submitHandler
        }
    }
}
</script>
