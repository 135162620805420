<template>
    <div class="m-auto w-screen-sm">
        <h1 class="text-black mb-2 text-xl lg:text-2xl text-center">Add New Client</h1>
        <div class="text-center">
            Have you checked if this client already exists?
        </div>
        <div class="flex flex-row justify-center mt-4">
            <v-btn
                depressed
                class="mr-4 w-40"
                @click="cancelHandler"
                data-cy="cancelHandler"
            >No</v-btn>
            <v-btn
                depressed
                color="primary"
                @click="submitHandler"
                class="v-btn--flat inline w-40"
            >Yes, I've checked</v-btn>
        </div>
    </div>
</template>

<script>
export default {
    components: {},
    name: 'ModalConfirmAddClient',
    data() {
        return {
            isValid: true
        }
    },
    methods: {
        cancelHandler() {
            this.$store.commit('MODAL_IS_VISIBLE', false)
        },
        submitHandler() {
            this.$store.commit('MODAL_IS_VISIBLE', false)
            this.$router.push({name: 'add-client'})
        },
        save(date) {
            // this.$refs.dateMenu.save(date)
        }
    },
    computed: {
    }
}
</script>
