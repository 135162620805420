<template>
    <div class="c-permissions__table editable_table flex flex-col w-full">
        <v-data-table
            ref="filterTable"
            single-select
            :hide-default-footer="true"
            item-key="id"
            :headers="headers"
            :items="tableDataArr"
            v-model="selected"
            class="a-table w-full"
            data-cy="permissionList"
            @click:row="rowClickHandler"
            @item-selected="itemSelected"
        >
            <template v-slot:item.location="{item}">
                <SelectCell
                    :ref="`location-${item.id}`"
                    :edit-active="item.active"
                    :cell-id="item.id"
                    v-model="item.locationId"
                    label="Site"
                    :items="computedLocations"
                    :display-value="locationDisplay(item.locationId)"
                    :emit-change="true"
                    :disabled="item.locationId && currentNewId != item.id"
                    @change="locationChanged(item)"
                    @edit-row="toggleEdit"
                />
            </template>
            <template class="" v-slot:item.program="{item}">
                <SelectCell
                    :ref="`program-${item.id}`"
                    :edit-active="item.active"
                    :cell-id="item.id"
                    v-model="item.programs"
                    label="Section"
                    :items="item.programList"
                    :multiple="true"
                    :small-chips="true"
                    :display-and-tooltip-value="programDisplay(item.programs)"
                    :default-value="[]"
                    :show-condensed="true"
                    @edit-row="toggleEdit"
                >
                    <template
                        v-if="item.programList && item.programList.length > 1"
                        v-slot:prepend-item
                    >
                        <v-list-item @click="toggle(item)">
                            <v-list-item-action>
                                <v-checkbox
                                    :ripple="false"
                                    v-model="item.selectAll"
                                    label=""
                                    :indeterminate="someProgramsSelected(item)"
                                ></v-checkbox>
                            </v-list-item-action>
                            <v-list-item-content>
                                <v-list-item-title>
                                    Select All
                                </v-list-item-title>
                            </v-list-item-content>
                        </v-list-item>
                        <v-divider class="mt-2"></v-divider>
                    </template>
                </SelectCell>
            </template>
            <!-- TODO: when upgrading, need to change below to this: [`item.editRecord`] -->
            <template class="" v-slot:item.editRecord="{item}" v-if="!disabled">
                <div
                    class="editable_table__edit-record ml-2 absolute text-center"
                    :class="{'active-tr': item.active}"
                >
                    <a
                        href="#"
                        @click.prevent="deleteHandler(item)"
                        v-if="!item.active"
                        class="mx-1 bg-red-light"
                    >
                        <fa-icon icon="trash-alt" class="text-red" data-cy="deleteRecord" />
                    </a>
                    <a
                        href="#"
                        v-if="item.active"
                        @click.prevent="cancelHandler"
                        class="mr-1 bg-orange-light"
                    >
                        <fa-icon icon="times" class="text-orange" />
                    </a>
                    <a
                        href="#"
                        v-if="item.active"
                        @click.prevent="saveHandler"
                        class="hover:text-primary bg-primary-lightest"
                    >
                        <fa-icon icon="check" class="text-primary" />
                    </a>
                </div>
            </template>
            <template v-slot:footer v-if="!disabled">
                <v-btn depressed class="mt-2 v-btn--flat inline" color="success" @click="addNewRow" :disabled="readOnly">
                    <fa-icon icon="plus" class="mr-1 group-hover:text-primary-light" /> Add another
                </v-btn>
            </template>
        </v-data-table>
    </div>
</template>

<script>
import {mapState} from 'vuex'
import {FormRules} from '@/components/shared/mixins/formMixins'
import MessageDialog from '@/components/shared/mixins/messageDialog'
import {TableMethods} from '@/components/shared/mixins/sharedMixins'
import SelectCell from '@/components/partials/SelectCell'

export default {
    name: 'PermissionTable',
    components: {
        SelectCell
    },
    mixins: [FormRules, MessageDialog, TableMethods],
    props: {
        tableData: {
            type: Array,
            default: () => []
        },
        locationPrograms: {
            type: Array,
            default: () => []
        },
        locations: {
            type: Array,
            default: () => []
        },
        programs: {
            type: Array,
            default: () => []
        },
        disabled: {
            type: Boolean,
            default: false
        },
        isLoaded: {
            type: Boolean,
            default: false
        },
        readOnly: {
            type: Boolean,
            default: false
        }
    },
    data() {
        return {
            emptyText: 'No permissions found',
            duration: 3000,
            type: '',
            message: '',
            tableDataArr: [],
            currentNewId: null,
            activeRowId: null,
            currentRow: null,
            currentTableRow: null,
            activeRowObj: null,
            headers: [
                {text: 'State', align: 'left', value: 'location', class: 'location--header w-1/2', width: '50%'},
                {
                    text: 'Program(s)',
                    align: 'left',
                    value: 'program',
                    sortable: false,
                    class: 'program--header w-1/2',
                    width: '50%'
                },
                {text: '', align: 'left', value: 'editRecord'}
            ],
            defaultNewObject: {
                location: {},
                program: {},
                locationProgram: {}
            },
            requiredFields: ['location', 'program'],
            refsToCheck: ['location', 'program'],
            selected: [],
            editCancelled: false,
            addingNewRow: false,
            currentLocationPrograms: [],
            maxProgramNameLength: 4
        }
    },
    mounted() {
        this.tableDataArr = [...this.tableData]
        if (!this.tableDataArr.length) {
            this.addNewRow()
        }
    },
    methods: {
        rowClickHandler(obj, e) {
            // if we just cancelled the edit of the row then reset and cancel the edit
            if (this.editCancelled || this.currentNewId != null || this.activeRowId != null) {
                this.editCancelled = false
                return
            } else if (this.isActiveRowChanged()) {
                this.showDiscardMessage(true)
                return false
            }
            this.currentRow = obj

            e.select(true)
            this.currentTableRow = e
        },
        async addNewRow() {
            if (this.currentNewId) return

            this.currentNewId = Math.floor(Math.random() * 1000000) + 5000000
            this.activeRowId = this.currentNewId
            const tempPermission = {
                id: this.currentNewId,
                locationId: null,
                programId: null
            }
            this.currentRow = tempPermission
            this.tableDataArr.push(tempPermission)
        },
        getDisplayValue(val, displayList) {
            if (!displayList || displayList.length === 0 || !val) return ''

            const displayValue = displayList.find(t => t.value.toLowerCase() === val.toLowerCase())
            return displayValue != null ? displayValue.label : ''
        },
        toggleEdit(val) {
            if (
                (this.activeRowId && Number(this.activeRowId) === Number(val)) ||
                (this.currentNewId && Number(this.currentNewId) === Number(val)) ||
                this.readOnly
            )
                return

            if (this.currentNewId) {
                this.editCancelled = true
                this.showDiscardMessage(false)
                return false
            } else if (this.activeRowId && this.isActiveRowChanged()) {
                this.editCancelled = true
                this.showDiscardMessage(true)
                return false
            }
            this.activeRowId = val
            this.activeRowObj = this.getActiveRow(this.tableDataArr)
        },
        showDiscardMessage(isEdit) {
            this.type = 'warning'
            const editText = isEdit ? 'edits' : 'new changes'
            this.message = `Please save/discard the ${editText} to the current row before moving to the next.`
            this.showMessage({duration: 1500})
        },
        isActiveRowChanged() {
            const fields = this.refsToCheck
            const changedObj = this.getActiveRow(this.tableDataArr)
            if (!changedObj || !this.activeRowObj) return false

            return fields.filter(field => this.activeRowObj[field] !== changedObj[field]).length > 0
        },
        getActiveRow(data) {
            const activeRow = data.find(item => {
                return item.id === this.activeRowId
            })
            return {...activeRow}
        },
        validateInputs(id) {
            const forms = this.requiredFields.reduce((arr, curr) => {
                arr.push(this.$refs[`${curr}-${id}`].validate())
                return arr
            }, [])

            return Promise.all(forms)
        },
        async saveHandler() {
            if (this.readOnly) return

            await this.validateInputs(this.activeRowId)
                .then(async values => {
                    let isSuccess = true
                    values.forEach(value => {
                        if (!value) isSuccess = false
                    })
                    if (isSuccess) {
                        // [TODO] This should be removed once we implement actual saving
                        this.editCancelled = true
                        this.clearRowSelection()

                        this.mutateTableData()
                    }
                })
                .catch(err => {
                    console.error('validation failed: ', err)
                })
        },
        filterTable(data) {
            return data.map(item => {
                let isActive = item.id === this.activeRowId
                return {
                    ...item,
                    active: isActive
                }
            })
        },
        resetTableRow() {
            // if an existing row is being edited
            if (!this.currentNewId) {
                // update the data on the row to the original values stored in activeRowObj
                this.tableDataArr = this.tableDataArr.map(item => {
                    if (item.id === this.activeRowId) {
                        return this.activeRowObj
                    }
                    return item
                })
            } else {
                // if its a new staff member, cancel the add by filtering them from the table arr
                this.tableDataArr = this.tableDataArr.filter(item => {
                    if (item.id !== this.activeRowId) return item
                })
            }
        },
        deleteHandler(item) {
            if (this.readOnly) return

            this.clearRowSelection()
            this.tableDataArr = this.tableDataArr.filter(td => {
                if (td.id !== item.id) return td
            })

            this.mutateTableData()
        },
        mutateTableData() {
            const tableData = this.tableDataArr.map(
                ({locationId, programs}) => ({
                    locationId,
                    programs: [...programs]
                })
            )
            this.$emit('table-data-change', tableData)
        },
        cancelHandler() {
            if (this.readOnly) return

            this.editCancelled = true

            this.resetTableRow()
            this.clearRowSelection()
        },
        clearRowSelection() {
            if (this.currentTableRow) this.currentTableRow.select(false)
            this.activeRowId = null
            this.currentNewId = null
            this.activeRowObj = null
            this.currentRow = null
            this.currentTableRow = null
            this.selected = []
        },
        itemSelected({item, value}) {
            if (value) {
                this.currentRow = item
                this.currentTableRow = item
                this.selected = [this.currentRow]
            }
        },
        getLocationPrograms(locationId) {
            const filtered = this.locationPrograms.filter(lp => {
                return locationId != null && lp.location && lp.location.locationId === locationId
            })
            return filtered
        },
        containsProgram(program) {
            const filtered =
                this.computedLocationPrograms.find(
                    clp => clp.program.programId === program.programId
                ) != null
            return filtered
        },
        getPrograms(item) {
            if (item == null || !Object.keys(item).length) return []
            if (!this.locationPrograms && !this.locationPrograms.length) return []

            this.currentLocationPrograms = this.getLocationPrograms(item.locationId)

            return this.programs.filter(this.containsProgram).map(program => {
                return {
                    value: program.programId,
                    text: program.name
                }
            })
        },
        locationDisplay(locationId) {
            if (!this.locations || !this.locations.length) return ''

            const location = this.locations.find(l => l.value === locationId)
            return location ? location.text : ''
        },
        programDisplay(programs) {
            if (!programs || !programs.length) return {}

            let programText = ''
            let tooltipText = ''

            for (let i = 0; i < programs.length; i++) {
                const programId = programs[i]
                const program = this.programs.find(p => p.programId === programId)
                if (!program) continue

                if (i === this.maxProgramNameLength) {
                    programText = `${programText} <div class="font-bold">(+${programs.length -
                    this.maxProgramNameLength} others)</div>`
                } else if (i < this.maxProgramNameLength) {
                    programText = `${programText ? programText + ', ' : ''}${program.name}`
                    tooltipText = `${tooltipText ? tooltipText + ', ' : ''}${program.name}`
                }
            }
            return {displayValue: programText, tooltipText: tooltipText}
        },
        toggle(item) {
            this.$nextTick(() => {
                if (this.allProgramsSelected(item)) {
                    item.programs = []
                    if (item.selectAll) item.selectAll = false
                } else {
                    item.programs = this.getPrograms(item).map(p => p.value)
                    if (!item.selectAll) item.selectAll = true
                }
            })
        },
        allProgramsSelected(item) {
            return item.programList.length === item.programs.length
        },
        someProgramsSelected(item) {
            return item.programs && item.programs.length > 0 && !this.allProgramsSelected(item)
        },
        updateProgramList(item) {
            item.programList = this.getPrograms(item)
        },
        locationChanged(item) {
            this.updateProgramList(item)
            this.currentRow = item
        }
    },
    computed: {
        ...mapState({
            staticChoices: state => state.app.staticChoices
        }),
        computedLocationPrograms() {
            return this.currentLocationPrograms || []
        },
        computedLocations() {
            const activeRow = this.getActiveRow(this.tableDataArr)
            return this.locations.filter(({ value }) => !this.tableDataArr.some(
                ({ locationId }) => locationId === value) || (activeRow && activeRow.locationId === value)
            )
        }
    },
    watch: {
        tableData() {
            let emptyRow
            if (this.addingNewRow && this.currentNewId)
                emptyRow = this.tableDataArr.find(tda => tda.locationId === this.currentNewId)
            this.tableDataArr = this.tableData

            // if we have an empty table, then we just clear the row selection since we cant' select or do anything
            // if there is a current row
            if (this.tableDataArr && this.tableDataArr.length === 0 && this.isLoaded)
                this.addNewRow()
            else this.clearRowSelection()

            if (
                emptyRow &&
                !this.tableData.find(tda => tda.id === this.currentNewId)
            ) {
                this.addingNewRow = false
                this.tableDataArr.push(emptyRow)
            }
            this.tableDataArr.forEach(tda => {
                this.updateProgramList(tda)
            })
        },
        activeRowId() {
            this.tableDataArr = this.filterTable(this.tableDataArr)
        },
        programs() {
            if (this.programs && this.programs.length) {
                this.tableDataArr.forEach(tda => {
                    if (!tda.programList || !tda.programList.length) this.updateProgramList(tda)
                })
            }
        }
    }
}
</script>
