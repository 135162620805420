import Vue from 'vue'
import VueRouter from 'vue-router'
import { authGuard } from '@/auth'
import { SHOW_MAINTENANCE } from '@/utils/constants'

Vue.use(VueRouter)
// const isDev = process.env.NODE_ENV === 'development'

let routes = [
    {
        path: '/',
        redirect: '/Dashboard',
    },
    {
        path: '/Client/List',
        name: 'client-list',
        component: () => import(/* webpackChunkName: "client-list" */ '@/views/ClientList.vue'),
        beforeEnter: authGuard
    },
    {
        path: '/Client/Overview/:clientId/',
        component: () => import(/* webpackChunkName: "client-overview" */ '@/views/ClientOverview.vue'),
        beforeEnter: authGuard,
        children: [
            {
                path: '/ClientOverview/:clientId/',
                redirect: '/ClientOverview/:clientId/Episodes',
                name: 'client-overview'
            },
            {
                path: '/ClientOverview/:clientId/Episodes',
                name: 'episodes',
                component: () => import(/* webpackChunkName: "episodes" */ '@/components/episodes/Episodes'),
            },
            {
                path: '/ClientOverview/:clientId/Episodes/:episodeId/Forms/:formSetId',
                name: 'client-form',
                component: () => import(/* webpackChunkName: "Form" */ '@/components/forms/Form')
            },
            {
                path: '/ClientOverview/:clientId/CaseNotes',
                name: 'case-notes',
                component: () => import(/* webpackChunkName: "CaseNotes" */ '@/components/case-notes/CaseNotes')
            },
            {
                path: '/ClientOverview/:clientId/Records',
                name: 'records',
                component: () => import(/* webpackChunkName: "Records" */ '@/components/records/Records')
            },
            {
                path: '/ClientOverview/:clientId/Records/:type',
                name: 'record-view',
                component: () => import(/* webpackChunkName: "RecordView" */ '@/components/records/RecordView')
            },
            {
                path: '/ClientOverview/:clientId/ActionPlan',
                name: 'action-plan',
                component: () => import(/* webpackChunkName: "ActionPlan" */ '@/components/action-plan/ActionPlan')
            },
            {
                path: '/ClientOverview/:clientId/VocEdGoals',
                name: 'voc-ed-goals',
                component: () => import (/* webpackChunkName: "VocEdGoals" */ '@/components/voc-ed-goals/VocEdGoals.vue')
            },
            {
                path: '/ClientOverview/:clientId/Appointments',
                name: 'appointments',
                component: () => import(/* webpackChunkName: "Appointments" */ '@/components/appointments/Appointments')
            },
            {
                path: '/ClientOverview/:clientId/TsuManagement',
                name: 'tsu-manage',
                component: () => import(/* webpackChunkName: "TsuManagement" */ '@/components/tsu-management/TsuManagement')
            }
        ]
    },
    {
        path: '/Client/Details/:clientId',
        name: 'edit-client',
        component: () => import(/* webpackChunkName: "edit-client" */ '@/views/ClientDetails.vue'),
        beforeEnter: authGuard
    },
    {
        path: '/Client/Add',
        name: 'add-client',
        component: () => import(/* webpackChunkName: "add-client" */ '@/views/ClientDetails.vue'),
        beforeEnter: authGuard
    },
    {
        path: '/Episode/List',
        name: 'episode-list',
        component: () => import(/* webpackChunkName: "episode-list" */ '@/views/EpisodeList.vue'),
        beforeEnter: authGuard
    },
    {
        path: '/AccountSettings',
        name: 'account-settings',
        // chunking example, not necessary for
        component: () => import(/* webpackChunkName: "account-settings" */ '@/views/AccountSettings.vue'),
        // meta: {
        //     auth: authOptions
        // },
        beforeEnter: authGuard
    },
    {
        path: '/ChangePassword/',
        name: 'change-password',
        // chunking example, not necessary for
        component: () => import(/* webpackChunkName: "change-password" */ '@/views/ChangePassword.vue'),
        // meta: {
        //     auth: authOptions
        // },
        beforeEnter: authGuard
    },
    {
        path: '/Dashboard',
        name: 'dashboard',
        props: true,
        component: () => import('@/views/Dashboard.vue'),
        beforeEnter: authGuard
    },
    {
        path: '/Reports',
        name: 'reports',
        props: true,
        component: () => import('@/views/Reports.vue'),
        beforeEnter: authGuard
    },
    {
        path: '/Groups',
        name: 'groups',
        component: () => import(/* webpackChunkName: "episode-list" */ '@/views/Groups.vue'),
        beforeEnter: authGuard
    },
    // TODO: change below routes for 'Workshop' and 'Summary' to be children of 'Reports' as it should have been!
    {
        path: '/Reports/Workshop',
        name: 'workshop-report',
        component: () => import('@/components/reports/Workshop.vue'),
        beforeEnter: authGuard
    },
    {
        path: '/Reports/Summary',
        name: 'summary-report',
        component: () => import('@/components/reports/Summary.vue'),
        beforeEnter: authGuard
    },
    {

        path: '/Clinical',
        name: 'clinical',
        props: true,
        component: () => import('@/views/Clinical.vue'),
        beforeEnter: authGuard
    },
    {
        path: '/Search',
        name: 'search-result',
        props: true,
        component: () => import(/* webpackChunkName: "change-password" */ '@/views/SearchResult.vue'),
        beforeEnter: authGuard
    },
    {
        path: '/Admin',
        name: 'admin',
        component: () => import(/* webpackChunkName: "admin" */ '@/views/Admin.vue'),
        beforeEnter: authGuard,
        children: [
            {
                path: '',
                redirect: 'UserManagement'
            },
            {
                path: 'StuniWorkshops',
                name: 'workshops',
                component: () => import(/* webpackChunkName: "stuni-workshops" */ '@/components/stuni/StuniWorkshops.vue'),
            },
            {
                path: 'StuniCampusCheckins',
                name: 'campus-checkins',
                component: () => import(/* webpackChunkName: "stuni-campus-checkins" */ '@/components/stuni/StuniCampusCheckins.vue'),
            },
            {
                path: 'UserManagement',
                name: 'user-list',
                component: () => import(/* webpackChunkName: "user-list" */ '@/components/user-management/UserList'),
            },
            {
                path: 'UserManagement/:userId',
                name: 'user-details',
                component: () => import(/* webpackChunkName: "user-details" */ '@/components/user-management/UserDetails'),
            },
        ]
    },
    {
        path: '/StuniManagement',
        name: 'stuni-management',
        props: true,
        component: () => import('@/components/stuni-management/StuniList.vue'),
        beforeEnter: authGuard
    },
    {
        path: '/StuniManagement/:userId',
        name: 'stuni-details',
        props: true,
        component: () => import('@/components/stuni-management/StuniDetails.vue'),
        beforeEnter: authGuard
    },
    {
        path: '/TsuApp',
        name: 'tsu-app',
        component: () => import('@/views/TsuApp.vue'),
        beforeEnter: authGuard
    },
    {
        path: '/NetworkError',
        name: 'network-error',
        component: () => import('@/views/NetworkError.vue'),
    }
]

if (SHOW_MAINTENANCE) {
    routes = [{
        path: '/',
        component: () => import(/* webpackChunkName: "maintenanceView" */ '@/views/MaintenanceView.vue'),
        meta: {
            title: 'Site Maintenance'
        }
    }]
}

const router = new VueRouter({
    mode: 'history',
    base: process.env.BASE_URL,
    routes
})

export default router
