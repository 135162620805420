import {reactive, toRefs} from '@vue/composition-api'
import {defineStore} from 'pinia'
import {userService} from '@/services/user.service'
import {DEFAULT_POINTS_TYPE} from '@/utils/constants'

// TODO: change to useUserClientStore
export const useUserClientStore = defineStore('userClient', () => {
    const state = reactive({
        userPoints: [],
        inviteUserForm: {
            inviteEmail: ''
        },
        userPointsForm: {
            date: '',
            time: '',
            sign: '',
            type: DEFAULT_POINTS_TYPE,
            points: null,
            description: ''
        },
        savingPoints: false,
        confirmInvite: false,
        currentClient: {},
        reloadPoints: false,
        userPointsLoading: false
    })

    const setUserPointsLoading = (val) => {
        state.userPointsLoading = val
    }

    const updateReloadPoints = (val) => {
        state.reloadPoints = val
    }

    const updateCurrentClient = (user) => {
        state.currentClient = user
    }

    const saveOrUpdate = async () => {
        const result = await userService.saveOrUpdate(state.inviteUserForm)
        console.log('======= udpated object', result)
    }

    const inviteUser = async () => {
        console.log('invite user', state.inviteUserForm?.inviteEmail)
    }

    const updatePoints = async () => {
        state.savingPoints = true
        const result = await userService.updatePoints(
            state.currentClient.clientId,
            state.userPointsForm
        )
        state.savingPoints = false
        state.reloadPoints = true
    }

    const addPoints = async () => {
        state.savingPoints = true
        const result = await userService.addPoints(state.currentClient.clientId, state.userPointsForm)
        state.savingPoints = false
        state.reloadPoints = true
    }

    const getUserPoints = async () => {
        state.userPointsLoading = true
        try {
            const result = await userService.getPointsForUser(state.currentClient.clientId)
            state.userPoints = result
        } catch (e) {
            console.log('error getting user points', e)
        } finally {
            state.userPointsLoading = false
        }

    }

    const getClient = async (clientId) => {
        const result = await userService.getUserByClientId(clientId)
        state.currentClient = result
    }

    return {
        ...toRefs(state),
        getClient,
        saveOrUpdate,
        inviteUser,
        updateCurrentClient,
        addPoints,
        updatePoints,
        getUserPoints,
        updateReloadPoints,
        setUserPointsLoading
    }
})