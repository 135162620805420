export const UPDATE_LOGIN_EMAIL = 'UPDATE_LOGIN_EMAIL'
export const SET_STAFF_TOTAL = 'SET_STAFF_TOTAL'
export const SET_STAFF_FIRST_VIEW = 'SET_STAFF_FIRST_VIEW'
export const SET_DOCUMENTS_FIRST_VIEW = 'SET_DOCUMENTS_FIRST_VIEW'
export const SET_ACCREDITATION_FIRST_VIEW = 'SET_ACCREDITATION_FIRST_VIEW'
export const SET_CLIENT_ID = 'SET_CLIENT_ID'
export const SET_ASSIGNED_TO_ME = 'SET_ASSIGNED_TO_ME'
export const SET_STATIC_OBJECT = 'SET_STATIC_OBJECT'
export const SET_SITE_SECTIONS = 'SET_SITE_SECTIONS'
export const SET_EPISODE_TYPES = 'SET_EPISODE_TYPES'
export const SET_LOCATIONS = 'SET_LOCATIONS'
export const SET_LOCATION_ITEMS = 'SET_LOCATION_ITEMS'

// Modal state mutations
export const MODAL_IS_VISIBLE = 'MODAL_IS_VISIBLE'
export const MODAL_DATA = 'MODAL_DATA'
export const MODAL_COMPONENT_NAME = 'MODAL_COMPONENT_NAME'

export const SET_DOCS_TOTAL = 'SET_DOCS_TOTAL'

export const SET_LIST_FILTER = 'SET_LIST_FILTER'

export const SET_PERMISSIONS = 'SET_PERMISSIONS'

export const SET_ROUTE_TO = 'SET_ROUTE_TO'
export const SET_ROUTE_FROM = 'SET_ROUTE_FROM'

export const SET_LOGGED_IN_USER = 'SET_LOGGED_IN_USER'

export const SET_SEARCH_TEXT = 'SET_SEARCH_TEXT'

export const SET_CURRENT_EPISODE = 'SET_CURRENT_EPISODE'

export const SET_FORM_TO_SAVE = 'SET_FORM_TO_SAVE'

export const SET_ANSWER_SET = 'SET_ANSWER_SET'

export const SET_MESSAGE_RESULT = 'SET_MESSAGE_RESULT'
export const SET_CURRENT_USER = 'SET_CURRENT_USER'

export const SET_FORM_TYPE = 'SET_FORM_TYPE'

export const SET_USER_ID_TO_LINK = 'SET_USER_ID_TO_LINK'

export const SET_EPISODE_FORM_TO_UPDATE = 'SET_EPISODE_FORM_TO_UPDATE'

export const SET_EPISODE_FORM_ID_TO_DELETE = 'SET_EPISODE_FORM_ID_TO_DELETE'
export const SET_EPISODE_FORMS_FOR_COPY = 'SET_EPISODE_FORMS_FOR_COPY'
