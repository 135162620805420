<template>
    <div class="m-auto w-screen-sm">
        <h1 class="text-black mb-2 text-xl lg:text-2xl text-center">Delete Young Person</h1>
        <div class="text-center">Are you sure you want to delete {{ clientName }}?</div>
        <div class="text-center my-5 font-bold">**This will permanently delete the young person from Ted**</div>
        <div class="flex flex-row justify-center mt-4">
            <v-btn
                depressed
                class="mr-4
                w-40"
                data-cy="cancelHandler"
                @click="cancelHandler"
            >Cancel</v-btn>
            <v-btn
                depressed
                color="primary"
                class="v-btn--flat inline w-40"
                @click="submitHandler"
            >Confirm</v-btn>
        </div>
    </div>
</template>

<script>
import { ref, onMounted, computed } from '@vue/composition-api'
import { createNamespacedHelpers } from 'vuex-composition-helpers'
import MessageDialog from '@/components/shared/mixins/messageDialog'
import {EpisodeHelpers} from '@/components/shared/mixins/episodeMixins'
import DELETE_CLIENT_AND_RELATED_DATA from '@/graphql/mutations/deleteClientAndRelatedData.gql'
import { useClientStore } from '@/stores/useClientStore'
import { useMessageStore } from '@/stores/useMessageStore'
import { storeToRefs } from 'pinia'

const { useState } = createNamespacedHelpers('app')
const { useActions } = createNamespacedHelpers('')

export default {
    components: {},
    mixins: [MessageDialog, EpisodeHelpers],
    name: 'ModalConfirmDeleteEpisode',
    setup(_, { root }) {
        const $apollo = root.$apollo
        const $message = root.$message
        const { modal } = useState(['modal'])
        const { closeModal } = useActions(['closeModal'])
        const clientStore = useClientStore()
        const messageStore = useMessageStore()
        const { clientDeleted } = storeToRefs(clientStore)
        const { message, type } = storeToRefs(messageStore)
        const { showMessage, resetMessage } = messageStore

        const clientName = computed(() => modal.value?.componentData?.clientName)
        const clientId = computed(() => modal.value?.componentData?.clientId)

        const cancelHandler = () => {
            closeModal()
        }

        const submitHandler = async() => {
            try {
                const deleletClient = await $apollo.mutate({
                    mutation: DELETE_CLIENT_AND_RELATED_DATA,
                    variables: {
                        clientId: Number(clientId.value)
                    }
                })
                message.value = 'Young person deleted! Redirecting back to Young People.'
                type.value = 'success'
                clientDeleted.value = true
                closeModal()
            } catch (error) {
                message.value = 'Delete Client failed.'
                type.value = 'error'
                clientDeleted.value = false
            } finally {
                showMessage({duration: 5000}, $message)
            }
        }

        onMounted(() => {
            resetMessage()
            if (clientDeleted.value) {
                clientDeleted.value = false
            }
        })

        return { clientName, cancelHandler, submitHandler }
    }
}
</script>
