import snakecaseKeys from 'snakecase-keys'
import camelcaseKeys from 'camelcase-keys'
import { APP_API_V2 } from '@/utils/constants'

import { getInstance } from '@/auth'
import axiosInstance from '@/utils/axios'

export class WorkshopService {
    constructor() {
        this.workshopsUrl = `${APP_API_V2}/workshops`
    }

    async addWorkshop(data) {
        const { data: workshop } = await axiosInstance.post(`${this.workshopsUrl}`, snakecaseKeys(data))
        return camelcaseKeys(workshop)
    }

    async updateWorkshop(data) {
        const { workshopId, ...rest } = data
        const { data: workshop } = await axiosInstance.patch(`${this.workshopsUrl}/${workshopId}`, snakecaseKeys(rest), {
            headers: {
                Authorization: `Bearer ${getInstance().token}`
            }
        })
        return camelcaseKeys(workshop)
    }

    async deleteWorkshop(workshopId) {
        const { data: workshop } = await axiosInstance.delete(`${this.workshopsUrl}/${workshopId}`, {
            headers: {
                Authorization: `Bearer ${getInstance().token}`
            }
        })
        return camelcaseKeys(workshop)
    }

    async getWorkshops(locationProgramId, startDate, endDate) {
        let url = `${this.workshopsUrl}`
        if (locationProgramId) {
            url = `${url}?location_program=${locationProgramId}`
        }
        if (startDate) {
            url = `${url}&start_date=${startDate}`
        }
        if (endDate) {
            url = `${url}&end_date=${endDate}`
        }
        const { data: workshops } = await axiosInstance.get(url, {
            headers: {
                Authorization: `Bearer ${getInstance().token}`
            }
        })
        return camelcaseKeys(workshops)
    }
}

export const workshopService = new WorkshopService()

