import { ref } from '@vue/composition-api'
import { defineStore } from 'pinia'

export const useMessageStore = defineStore('message', () => {
    const currentMessageDialog = ref(null)
    const message = ref('')
    const type = ref('success')

    const resetMessage = () => {
        message.value = ''
        type.value = ''
        if (currentMessageDialog.value) {
            currentMessageDialog.value.close()
            currentMessageDialog.value = null
        }
    }

    const showMessage = (options, $message) => {
        currentMessageDialog.value = $message({
            showClose: true,
            message: message.value,
            type: type.value,
            duration: 0,
            ...(options || {})
        })
    }

    return {
        message,
        type,
        currentMessageDialog,
        showMessage,
        resetMessage,
    }
})