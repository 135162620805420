<template>
    <div class="m-auto w-screen-sm">
        <h1 class="text-black mb-2 text-xl lg:text-2xl text-center">Set to Assessed Suitable</h1>
        
        <v-form
            ref="suitableEpisodeForm"
            class="px-4"
            v-model="isValid"
        >
            <div class="text-center">
                This changes the episode status to <span class="text-yellow-dark font-bold">Assessed Suitable</span>.
                <p class="mb-2">Are you sure you would like to set the episode to Assessed Suitable?</p>
            </div>
            
            <!--I need to check with noffs if itll make the Program End date NULL or not - though think its best if we dont that set in stone logic like that -->

            <div class="flex flex-row justify-center mt-4">
                <v-btn
                    depressed
                    class="mr-4 w-40"
                    @click="cancelHandler"
                    data-cy="cancelHandler"
                >Cancel</v-btn>
                <v-btn
                    depressed
                    color="primary"
                    @click="submitHandler"
                    class="v-btn--flat inline w-200"
                >Yes</v-btn>
            </div>
        </v-form>
    </div>
</template>

<script>
import { mapState } from 'vuex'
import EditableDatePicker from '@/components/shared/EditableDatePicker'
import MessageDialog from '@/components/shared/mixins/messageDialog'
import { FormRules } from '@/components/shared/mixins/formMixins'
import ModalHelper from '@/components/shared/mixins/modalMixin'

export default {
    name: 'ModalAssessedSuitable',
    components: { EditableDatePicker },
    mixins: [ ModalHelper, MessageDialog, FormRules ],
    data() {
        return {
            defaultSuitableEpisode: {},
            isValid: true,
            date: null,
        }
    },
    methods: {
        validate() {
            return this.$refs.suitableEpisodeForm.validate()
        },
        submitHandler() {
            // commit mutation
            this.isValid = this.validate()
            if (this.isValid) {
                this.suitableEpisodeForm.status = 'SUITABLE'
                this.$root.$emit('submit-assess-episode-suitable', { messageText: 'Episode status has been set to ASSESSED SUITABLE', episode: { episodeId: this.episode.episodeId, ...this.suitableEpisodeForm } })
                this.closeModal()
            }
        },
    },
    computed: {
        ...mapState({
            staticChoices: state => state.app.staticChoices,
            data: state => state.app.modal.componentData
        }),
        suitableEpisodeForm: {
            get() {
                return this.defaultSuitableEpisode
            },
            set(suitableEpisodeForm) {
                console.log(suitableEpisodeForm)
            }
        },
        episode() {
            return this.data && this.data.episode ? this.data.episode : {}
        }
    },
}
</script>
