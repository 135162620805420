<template>
    <div class="m-auto w-screen-xs p-4 px-20">
        <h1 class="text-black mb-2 text-xl lg:text-2xl text-center">Delete User Points</h1>
        <div class="text-black text-sm my-5 m-auto flex flex-row w-240 justify-between">
            <div>{{ pointsDate }}</div>
            <div>{{ pointsTime }}</div>
            <div>{{ pointsDescription}}</div>
        </div>
        <div class="text-center mb-8">Are you sure you would like to continue?</div>

        <div class="flex flex-row justify-center mt-4">
            <v-btn
                depressed
                class="mr-4
                w-40"
                @click="cancelHandler"
            >Cancel</v-btn>
            <v-btn
                depressed
                color="primary"
                class="v-btn--flat inline w-40"
                :disabled="deletingPoints"
                @click="submitHandler"
            >{{ confirmBtnTxt }}</v-btn>
        </div>
    </div>
</template>

<script>
import { ref, onMounted, computed } from '@vue/composition-api'
import MessageDialog from '@/components/shared/mixins/messageDialog'
import { useMessageStore } from '@/stores/useMessageStore'
import { storeToRefs } from 'pinia'
import { useModalStore } from '@/stores/useModalStore'
import {userService} from '@/services/user.service'
import { useUserClientStore } from '@/stores/useUserClientStore'


export default {
    components: {},
    mixins: [MessageDialog],
    name: 'ModalConfirmDeletePoints',
    setup(_, { root }) {
        const $message = root.$message
        const modalStore = useModalStore()
        const { closeModal } = modalStore
        const userClientStore = useUserClientStore()
        const {updateReloadPoints} = userClientStore
        const { data } = storeToRefs(modalStore)
        const messageStore = useMessageStore()
        const { message, type } = storeToRefs(messageStore)
        const { showMessage, resetMessage } = messageStore
        const deletingPoints = ref(false)

        const pointsDate = computed(() => data.value?.date)
        const pointsTime = computed(() => data.value?.time)
        const pointsDescription = computed(() => data.value?.description || 'No description')

        const confirmBtnTxt = computed(() => deletingPoints.value ? 'Please wait...' : 'Confirm')
        const cancelHandler = () => {
            closeModal()
        }

        const submitHandler = async() => {
            let doClose = false
            try {
                deletingPoints.value = true
                await userService.deletePointsForUser(data.value.clientId, data.value.userPointId)
                message.value = 'Points deleted!'
                type.value = 'success'
                doClose = true
                updateReloadPoints(true)
            } catch (error) {
                message.value = 'Delete points failed.'
                type.value = 'error'
            } finally {
                deletingPoints.value = false
                showMessage({duration: 5000}, $message)
            }
            if (doClose) {
                closeModal()
            }
        }

        onMounted(() => {
            resetMessage()
        })

        return {
            pointsDate,
            pointsTime,
            pointsDescription,
            confirmBtnTxt,
            deletingPoints,
            cancelHandler,
            submitHandler
        }
    }
}
</script>
