export default {
    loggedIn: true,
    loginEmail: '',
    staffTotal: 0,
    isStaffFirstView: true,
    isDocumentsFirstView: true,
    isAccreditationFirstView: true,
    docsTotal: 0,
    clientId: '',
    assignedToMe: false,
    staticChoices: {},
    siteSections: [],
    episodeTypes: [],
    locations: [],
    locationItems: [],
    listFilter: {
        limit: 25,
        offset: 0,
        pageSize: 25,
        currentPage: 0,
        searchVal: '',
        columnFilters: {}
    },
    modal: {
        isVisible: false,
        componentName: 'ModalDefault',
        componentData: {},
    },
    permissions: {},
    routeTo: {},
    routeFrom: {},
    // TODO: remove below once we're happy with Auth0
    loggedInUser: {},
    testUser: {
        id: 1,
        userId: 1,
        firstName: 'Test',
        lastName: 'Ted',
        name: 'Test Ted',
        password: 'Random12',
        role: 'super-admin',
        email: 'support@codesource.com.au'
    },
    searchText: '',
    formToSave: {},
    currentEpisode: {},
    answerSet: {},
    messageResult: {},
    currentUser: null,
    permissionMatrix: {},
    formType: {},
    userIdToLink: '',
    episodeFormToUpdate: null,
    episodeFormIdToDelete: null,
    episodeFormsForCopy: []
}
