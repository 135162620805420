<template>
    <div class="m-auto w-screen-sm">
        <h1 class="text-black mb-2 text-xl lg:text-2xl text-center">Reject Stuni User</h1>
        <div>
            <div class="text-center">
                Would you like to reject this Stuni user? They will no longer be able to log
                into the mobile app.
            </div>
            <div class="flex flex-row justify-center mt-4">
                <v-btn
                    depressed
                    class="mr-4 w-40"
                    data-cy="cancelHandler"
                    @click="cancelHandler"
                >Cancel</v-btn>
                <v-btn
                    depressed
                    color="primary"
                    class="v-btn--flat inline w-200"
                    @click="submitHandler"
                >Reject</v-btn>
            </div>
        </div>
    </div>
</template>

<script>
import {mapState} from 'vuex'
import {UserHelper} from '@/components/shared/mixins/userMixin'

export default {
    name: 'ModalConfirmStuniReject',
    mixins: [UserHelper],
    computed: {
        ...mapState({
            data: state => state.app.modal.componentData
        })
    }
}
</script>
