<template>
    <div class="m-auto w-screen-sm">
        <h1 class="text-black mb-2 text-xl lg:text-2xl text-center">Edit {{ formSetName }}</h1>
        <v-form
            ref="editEpisodeForm"
            class="px-4"
            v-model="isValid"
            @submit.prevent="submitHandler"
        >
            <div>
                <v-autocomplete
                    dense
                    single-line
                    hide-details
                    outlined
                    clearable
                    label="Completed By"
                    item-text="fullName"
                    item-value="userId"
                    v-model="completedBy"
                    :items="staffUsers"
                ></v-autocomplete>
            </div>
            <div>
                <EditableDatePicker
                    v-model="dueDate"
                    label="Due date"
                    :max="maxDate"
                    :rules="[required]"
                />
            </div>
            <div>
                <EditableDatePicker
                    v-model="dateCompleted"
                    label="Completed Date"
                    :max="maxDate"
                    :rules="[required]"
                />
            </div>
            <div class="flex flex-row justify-center mt-4">
                <v-btn
                    depressed
                    class="mr-4 w-40"
                    data-cy="cancelHandler"
                    @click="cancelHandler"
                >Cancel</v-btn>
                <v-btn
                    depressed
                    type="submit"
                    color="primary"
                    class="v-btn--flat inline w-40"
                >Save</v-btn>
            </div>
        </v-form>
    </div>
</template>

<script>
import EditableDatePicker from '@/components/shared/EditableDatePicker'
import {FormRules} from '@/components/shared/mixins/formMixins'
import { addYears, endOfDay, format, parse, parseISO, isValid as isValidDate } from 'date-fns'
import { computed, onMounted, ref, watch } from '@vue/composition-api'
import { createNamespacedHelpers } from 'vuex-composition-helpers'
import { useLazyQuery } from '@vue/apollo-composable'
import camelcaseKeys from 'camelcase-keys'
import { GET_STAFF_USERS } from '@/graphql/queries/userQueries.gql'

const { useActions, useGetters } = createNamespacedHelpers('')

export default {
    name: 'ModalCloseEpisode',
    components: {EditableDatePicker},
    mixins: [FormRules],
    setup() {
        const isValid = ref(true)
        const editEpisodeForm = ref({})
        const maxDate = ref(format(addYears(new Date(), 2), 'yyyy-MM-dd'))
        const dateCompleted = ref(null)
        const dueDate = ref(null)
        const completedBy = ref('')
        const staffUsers = ref([])
        const { closeModal, setEpisodeFormToUpdate } = useActions(['closeModal', 'setEpisodeFormToUpdate'])
        const { staticChoices, componentData: data } = useGetters(['staticChoices', 'componentData'])

        const episodeform = computed(() => data.value?.episodeForm)
        const episodeFormId = computed(() => data.value?.episodeForm?.episodeFormId)
        const episodeId = computed(() => data.value?.episodeForm?.episodeId)
        const formSetId = computed(() => data.value?.episodeForm?.formSetId)
        const formSetName = computed(() => data.value?.episodeForm?.name)
        const formAnswerId = computed(() => data.value?.episodeForm?.formAnswerId)

        const { onResult, loading, error, refetch, load, onError } = useLazyQuery(GET_STAFF_USERS)

        onError(() => {
            console.error('error getting the list of staff users')
        })

        onResult(({ data, loading: queryLoading }) => {
            if (queryLoading) return
            const users = data?.users
            if (!users?.length) {
                staffUsers.value = []
            }
            users.map((u) => u.fullName = `${u.firstName || ''} ${u.lastName || ''}`.trim() || u.name)

            staffUsers.value = camelcaseKeys(users, {deep: true})
            console.log('staff users found', staffUsers.value)
        })

        const validate = () => {
            return editEpisodeForm.value.validate()
        }

        const cancelHandler = () => {
            closeModal()
        }

        const submitHandler = () => {
            isValid.value = validate()
            if (!isValid.value) return

            const { status, percentDone, typename, updatedAt, progress, name, total, completedBy: completedByValue, ...formToSave } = data.value?.episodeForm
            // const parsedDate = startOfDay(parse(dateCompleted.value, 'yyyy-MM-dd', new Date()))
            const parsedDateCompleted = endOfDay(parse(dateCompleted.value, 'yyyy-MM-dd', new Date()))
            const parsedDueDate = endOfDay(parse(dueDate.value, 'yyyy-MM-dd', new Date()))

            const convertedDateCompleted = format(parsedDateCompleted, 'yyyy-MM-dd\'T\'HH:mm:ssXXX')
            const convertedDueDate = format(parsedDueDate, 'yyyy-MM-dd\'T\'HH:mm:ssXXX')
            const toSave = {
                ...formToSave,
                dateCompleted: convertedDateCompleted,
                dueDate: convertedDueDate,
                completedById: completedBy.value
            }
            setEpisodeFormToUpdate({...toSave})
            closeModal()
        }

        onMounted(async () => {
            await load()
        })

        watch(episodeform, (newValue) => {
            completedBy.value = newValue?.completedById
            const parsedDateCompleted = parseISO(newValue.dateCompleted)
            const parsedDueDate = parseISO(newValue.dueDate)
            if (isValidDate(parsedDateCompleted)) dateCompleted.value = format(parsedDateCompleted, 'yyyy-MM-dd')
            if (isValidDate(parsedDueDate)) dueDate.value = format(parsedDueDate, 'yyyy-MM-dd')
        }, { immediate: true })

        return {
            isValid,
            dateCompleted,
            editEpisodeForm,
            formSetId,
            formSetName,
            staticChoices,
            maxDate,
            dueDate,
            completedBy,
            loading,
            staffUsers,
            validate,
            submitHandler,
            cancelHandler
        }
    }
}
</script>
