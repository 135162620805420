<template>
    <v-form
        class="a-contact-form"
        ref="form"
        v-model="isValid"
    >
        <v-select
            label="Episode"
            :items="episodes"
            v-model="form.episodeId"
            item-value="episodeId"
            :disabled="isViewMode"
            class="episode-v-select"
        >
            <template v-slot:selection="{ item }">
                <div class="pl-2 flex justify-start items-center text-sm font-normal text-grey-1 text-left">
                    <div class="mr-2 whitespace-no-wrap">{{ getDateRange(item) }}</div>
                    <div class="uppercase font-bold whitespace-no-wrap mr-2" :class="getStatusColour(item)">
                        {{ getEpisodeStatus(item) }}
                    </div>
                    <div class="w-20 truncate">{{ getSiteSection(item) }}</div>
                    <!--<div class="w-30">{{getWorker(item)}}</div>-->
                </div>
            </template>
            <template v-slot:item="{ item }">
                <div class="flex justify-start items-center text-sm font-normal text-grey-1 text-left">
                    <div class="min-w-daterange flex-none">{{ getDateRange(item) }}</div>
                    <div class="w-40 uppercase font-bold flex-none" :class="getStatusColour(item)">
                        {{ getEpisodeStatus(item) }}
                    </div>
                    <div class="mr-4 w-sitesection truncate flex-none">{{ getSiteSection(item) }}</div>
                    <div class="mr-2 w-30 flex-none">{{ getWorker(item) }}</div>
                </div>
            </template>
        </v-select>
    </v-form>
</template>

<script>
import { EpisodeHelpers } from '@/components/shared/mixins/episodeMixins'
import { mapState } from 'vuex'

export default {
    name: 'EpisodeDropdown',
    props: {
        value: {
            type: Number,
            default: null
        },
        episodes: {
            type: Array,
            default: () => []
        },
        isViewMode: {
            type: Boolean,
            default: false
        },
        defaultValue: {
            type: Number,
            default: null
        }
    },
    mixins: [EpisodeHelpers],
    data() {
        return {
            isValid: true,
            form: {
                episodeId: ''
            }
        }
    },
    mounted() {
        this.form.episodeId = this.value || this.defaultValue
    },
    methods: {
        validate() {
            this.isValid = this.$refs.form.validate()
            return this.isValid
        },
    },
    computed: {
        ...mapState({
            staticChoices: state => state.app.staticChoices,
            siteSections: state => state.app.siteSections,
        }),
        episodeStatuses() {
            return this.staticChoices['episode.status']
        },
    },
    watch: {
        'form.episodeId': function() {
            this.$emit('input', this.form.episodeId)
        }
    }
}
</script>
