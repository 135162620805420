<template>
    <div class="m-auto w-screen-sm">
        <h1 class="text-black mb-2 text-xl lg:text-2xl text-center">Add or use points</h1>
        <div>
            <v-form ref="addOrUsePointsForm" v-model="isValid">
                <div class="mx-auto max-w-400 max-h-modal overflow-y-auto">
                    <div
                        class="grid-cols-none block lg:grid lg:grid-cols-2 gap-x-4 mb-0 w-full px-4"
                    >
                        <div>
                            <EditableDatePicker
                                v-model="userPointsForm.date"
                                label="Date"
                                min="2010-01-01"
                                :default-date="new Date()"
                                :disableFutureDate="true"
                                :rules="[futureDateNotAllowed]"
                            />
                        </div>
                        <div>
                            <EditableDatetimePicker
                                label="Time"
                                type="time"
                                :clearable="false"
                                v-model="userPointsForm.time"
                                :isRequired="true"
                                :format="formatTime"
                            />
                        </div>
                        <div>
                            <v-select
                                v-model="userPointsForm.sign"
                                label="Add or Subtract"
                                :items="addOrSubtract"
                                :rules="[required]"
                            ></v-select>
                        </div>
                        <div>
                            <v-text-field
                                v-model="userPointsForm.points"
                                label="Points"
                                :rules="[required, numbersOnly]"
                            ></v-text-field>
                        </div>
                        <div class="lg:col-span-2">
                            <div class="v-application--is-ltr mt-2">
                                <v-textarea
                                    outlined
                                    label="Enter description"
                                    v-model="userPointsForm.description"
                                    rows="3"
                                    :rules="[max100Textarea]"
                                ></v-textarea>
                            </div>
                        </div>
                    </div>
                </div>
                <br />
                <v-alert
                    v-if="!isValid && message"
                    :closable="false"
                    type="error"
                    class="text-sm"
                    >{{ message }}</v-alert
                >
            </v-form>
            <div class="flex flex-row justify-center mt-4">
                <v-btn depressed class="mr-4 w-40" @click="cancelHandler">Cancel</v-btn>
                <v-btn
                    depressed
                    color="primary"
                    class="v-btn--flat inline w-200"
                    :disabled="!isValid"
                    @click="submitHandler"
                    >{{ addBtnTxt }}</v-btn
                >
            </div>
        </div>
    </div>
</template>

<script>
import {ref, onMounted, computed, watch} from '@vue/composition-api'
import {storeToRefs} from 'pinia'
import {format} from 'date-fns'
import { createNamespacedHelpers } from 'vuex-composition-helpers'
import EditableDatePicker from '@/components/shared/EditableDatePicker'
import EditableDatetimePicker from '@/components/partials/EditableDatetimePicker'
import SelectCell from '@/components/partials/SelectCell'
import {useUserClientStore} from '@/stores/useUserClientStore'
import {useModalStore} from '@/stores/useModalStore'
import {required, futureDateNotAllowed, numbersOnly, max100Textarea} from '@/utils/validators'

const { useState } = createNamespacedHelpers('app')

export default {
    name: 'ModalAddOrUsePoints',
    components: {
        EditableDatePicker,
        EditableDatetimePicker,
        SelectCell
    },
    setup() {
        const addingPoints = ref(false)
        const message = ref('')
        const formatTime = ref('HH:mm')
        const addOrSubtract = ref([
            {text: 'Add', value: 'ADD'},
            {text: 'Subtract', value: 'SUBTRACT'}
        ])
        const isValid = ref(true)
        const email = ref('')
        const addOrUsePointsForm = ref({})
        const { loggedInUser } = useState(['loggedInUser'])

        const userClientStore = useUserClientStore()
        const {currentClient, userPointsForm} = storeToRefs(userClientStore)
        const {addPoints, updatePoints} = userClientStore
        const modalStore = useModalStore()
        const {closeModal} = modalStore

        const addBtnTxt = computed(() => {
            if (addingPoints.value) return 'Please wait...'
            return userPointsForm.value.userPointsId ? 'Update' : 'Add'
        })

        const submitHandler = async () => {
            console.log('Invite sent')
            try {
                addingPoints.value = true
                if (userPointsForm.value.userPointsId) {
                    await updatePoints()
                } else {
                    await addPoints()
                }
                closeModal()
            } catch (error) {
                console.error(error)
            } finally {
                addingPoints.value = false
            }
        }

        const cancelHandler = () => {
            console.log('Invite cancelled')
            closeModal()
        }

        watch(() => loggedInUser.value, (newUser) => {
            if (!newUser) return
            if (newUser.userId) userPointsForm.value.addedById = newUser.userId
        }, { immediate: true, deep: true })
        onMounted(() => {
            userPointsForm.value.sign = addOrSubtract.value[0].value
            userPointsForm.value.date = format(new Date(), 'yyyy-MM-dd')
            userPointsForm.value.time = format(new Date(), formatTime.value)
        })

        return {
            addBtnTxt,
            isValid,
            email,
            userPointsForm,
            addOrUsePointsForm,
            addOrSubtract,
            required,
            futureDateNotAllowed,
            numbersOnly,
            max100Textarea,
            formatTime,
            message,
            submitHandler,
            cancelHandler
        }
    }
}
</script>
