export default {
    computed: {
        avatar() {
            let names = [this.loggedInUser.firstName, this.loggedInUser.lastName]
            let initials = ''
            for (let name of names) {
                if (name) initials += name.slice(0, 1)
            }
            return initials || '-'
        }
    }
}
