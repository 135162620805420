<template>
    <footer class="main_footer py-4 px-2">
        <div class="px-4 flex flex-row justify-between">
            <div class="text-left mt-6">
                <p class="text-sm">
                    © {{getCurrentYear}} <a href="https://noffs.org.au/" target="_blank" class="text-primary-light font-bold">Ted Noffs Foundation</a>
                </p>
            </div>
            <div class="text-right mt-6">
                <p class="text-sm">
                    Solution by
                    <a href="https://codesource.com.au/" target="_blank" class="text-primary-light font-bold">CodeSource</a>
                </p>
            </div>
        </div>
    </footer>
</template>

<script>
export default {
    name: 'GlobalFooter',
    computed: {
        getCurrentYear() {
            const date = new Date()
            return date.getFullYear()
        }
    }
}
</script>
