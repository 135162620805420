<template>
    <div class="c-phone-number v-text-field">
        <v-form
            v-model="isValid"
            ref="phoneNumberForm"
        >
            <v-phone-input
                ref="localPhone"
                v-model="localPhone.phone"
                default-country-code="AU"
                :translations="translations"
                :readOnly="readOnly"
                @phone-number-blue="onPhoneBlur"
            />
            <div class="v-text-field__details" v-if="error">
                <div class="v-messages theme--light error--text" role="alert">
                    <div class="v-messages__wrapper">
                        <div class="v-messages__message message-transition-enter-to">{{ error }}</div>
                    </div>
                </div>
            </div>
        </v-form>
    </div>
</template>

<script>
export default {
    name: 'PhoneNumber',
    props: {
        value: {
            type: Object,
            required: true,
            default: () =>  {
                return {
                    countryCode: 'AU',
                    phone: '',
                    archived: false
                }
            }
        },
        required: {
            type: Boolean,
            default: false
        },
        validPhone: {
            type: Boolean,
            default: false
        },
        translations: {
            type: Object,
            default: () => {}
        },
        readOnly: {
            type: Boolean,
            default: false
        }
    },
    data() {
        return {
            firstLoad: true,
            phone: '',
            isValid: true,
            defaultCountryCode: 'AU',
            phoneNumberForm: {
                countryCode: 'AU',
                phone: null,
                archived: false
            },
            validationRules: {
                phone: [{ validator: this.validateInput, trigger: 'blur' }]
            },
            phoneRegex: /^\(?(?:\+?61|0)(?:(?:2\)?[ -]?(?:3[ -]?[38]|[46-9][ -]?[0-9]|5[ -]?[0-35-9])|3\)?(?:4[ -]?[0-57-9]|[57-9][ -]?[0-9]|6[ -]?[1-67])|7\)?[ -]?(?:[2-4][ -]?[0-9]|5[ -]?[2-7]|7[ -]?6)|8\)?[ -]?(?:5[ -]?[1-4]|6[ -]?[0-8]|[7-9][ -]?[0-9]))(?:[ -]?[0-9]){6}|4\)?[ -]?(?:(?:[01][ -]?[0-9]|2[ -]?[0-57-9]|3[ -]?[1-9]|4[ -]?[7-9]|5[ -]?[018])[ -]?[0-9]|3[ -]?0[ -]?[0-5])(?:[ -]?[0-9]){5})$/,
            error: '',
            localPhone: {
                countryCode: 'AU',
                phone: '',
            }
        }
    },
    mounted() {
        this.localPhone = this.value
    },
    methods: {
        validate() {
            const isValid = !this.required || !!this.localPhone.phone || 'Required.'

            this.error = isValid === true ? '' : isValid
            this.isValid = isValid === true

            return this.isValid
            // return true
        },
        validateInput(rule, value, cb) {
            // first we check to see if we just loaded and there's no validation on the phone number
            if (!this.isValid) {
                this.isValid = this.phoneRegex.test(this.localPhone.phone)
            }

            // first we check if we are adding or editing
            if (this.required) {
                if (!this.localPhone.phone) {
                    cb('This field is required')
                } else if (this.isValid !== undefined && this.isValid !== true) {
                    // if isValid is undefined, then the value would be from the backend and we don't need to do anything
                    cb('Invalid phone number format')
                } else {
                    cb()
                }
            } else if(this.validPhone) {
                if (this.isValid !== undefined && this.isValid !== true && this.localPhone.phone !==  '') {
                    // if isValid is undefined, then the value would be from the backend and we don't need to do anything
                    cb('Invalid phone number format')
                } else {
                    cb()
                }
            } else {
                cb()
            }
        },
        onPhoneBlur() {
            //TODO: add in the Vuetify way of validating
            // if (this.isValid) {
            //     this.$refs.phoneNumberForm.resetFields()
            // } else {
            //     this.$refs.phoneNumberForm.validate()
            // }
            this.$emit('input', this.localPhone)
            this.$emit('blur', this.localPhone)
        },
        // updatePhone(e) {
        //     this.isValid = e.isValid
        //     this.$emit('input', this.localPhone)
        //     this.$emit('update', this.localPhone)
        // }
    },
    watch: {
        value() {
            this.localPhone = this.value
        },
        'localPhone.phone': function () {
            this.validate()
            this.$refs.phoneNumberForm.phone = this.localPhone.phone
            this.phoneNumberForm.phone = this.localPhone.phone
            this.$emit('input', this.localPhone)
        }
    }
}
</script>
