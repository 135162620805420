<template>
    <div class="m-auto w-screen-sm">
        <h1 class="text-black mb-2 text-xl lg:text-2xl text-center">Approve Stuni User As Guest</h1>
        <v-form v-model="isValid" ref="form">
            <div class="text-center">
                Would you like to approve TSU user as a guest?
            </div>
            <v-textarea
                outlined
                v-model="comment"
                class="mt-8 mx-4"
                rows="4"
                label="Tell us why approval was granted"
                :rules="[required]"
            ></v-textarea>
            <div class="flex flex-row justify-center mt-1">
                <v-btn
                    depressed
                    class="mr-4 w-40"
                    data-cy="cancelHandler"
                    @click="cancelHandler"
                >Cancel</v-btn>
                <v-btn
                    depressed
                    color="primary"
                    class="v-btn--flat inline w-200"
                    @click="submitHandler"
                >Approve as guest</v-btn>
            </div>
        </v-form>
    </div>
</template>

<script>
import { mapState } from 'vuex'
import { UserHelper } from '@/components/shared/mixins/userMixin'
import { FormRules } from '@/components/shared/mixins/formMixins'

export default {
    name: 'ModalConfirmStuniApproveAsGuest',
    mixins: [UserHelper, FormRules],
    data() {
        return {
            comment: '',
            isValid: false
        }
    },
    methods: {
        validate() {
            this.isValid = this.$refs.form.validate()
            return this.isValid
        },
        submitHandler() {
            if(!this.validate())
                return

            let data = this.data
            data.variables.comment = this.comment
            
            this.$root.$emit('change-user-status', data)
            this.closeModal()
        }
    },
    computed: {
        ...mapState({
            data: state => state.app.modal.componentData
        })
    }
}
</script>
