<template>
    <div
        class="c-behaviour-incident__table editable_table flex flex-col w-full"
        :class="{'pointer-events-none': isView}"
    >
        <v-data-table
            ref="behaviourIncidentTable"
            single-select
            item-key="id"
            :headers="headers"
            :items="tableDataArr"
            :items-per-page.sync="itemsPerPage"
            :footer-props="footerProps"
            class="a-table w-full"
            data-cy="behaviourIncidentTable"
            @click:row="rowClickHandler"
            hide-default-footer
        >
            <template v-slot:item.time="{ item }">
                <DatetimePickerCell
                    :ref="`time-${item.behaviourIncidentId}`"
                    :edit-active="item.active"
                    :cell-id="item.behaviourIncidentId"
                    v-model="item.time"
                    :disabled="disabled"
                    label="00:00 AM"
                    :isRequired="true"
                    type="time"
                    :format="timeFormat"
                    :rules="[required]"
                    @edit-row="toggleEdit"
                />
            </template>
            <template class="" v-slot:item.incidentCode="{ item }">
                <SelectCell
                    :ref="`incidentCode-${item.behaviourIncidentId}`"
                    :edit-active="item.active"
                    :cell-id="item.behaviourIncidentId"
                    v-model="item.incidentCode"
                    @edit-row="toggleEdit"
                    :display-value="getDisplayValue(item.incidentCode, incidentCodes)"
                    :items="incidentCodes"
                    :disabled="disabled"
                    :isRequired="true"
                    label="Incident Code"
                />
            </template>
            <template class="" v-slot:item.responseCode="{ item }">
                <SelectCell
                    :ref="`responseCode-${item.behaviourIncidentId}`"
                    :edit-active="item.active"
                    :cell-id="item.behaviourIncidentId"
                    @edit-row="toggleEdit"
                    :display-value="getDisplayValue(item.responseCode, responseCodes)"
                    :items="responseCodes"
                    v-model="item.responseCode"
                    :disabled="disabled"
                    :isRequired="true"
                    label="Response Code"
                />
            </template>
            <template class="" v-slot:item.incidentReport="{ item }">
                <SelectCell
                    :ref="`incidentReport-${item.behaviourIncidentId}`"
                    :edit-active="item.active"
                    :cell-id="item.behaviourIncidentId"
                    @edit-row="toggleEdit"
                    :display-value="getDisplayValue(item.incidentReport, incidentReports)"
                    :items="incidentReports"
                    v-model="item.incidentReport"
                    :disabled="disabled"
                    :isRequired="true"
                    label="Incident Report"
                />
            </template>
            <template class="" v-slot:item.behaviour="{ item }">
                <EditableCell
                    :ref="`behaviour-${item.behaviourIncidentId}`"
                    :edit-active="item.active"
                    :cell-id="item.behaviourIncidentId"
                    @edit-row="toggleEdit"
                    v-model="item.behaviour"
                    :disabled="disabled"
                    label="Behaviour"
                />
            </template>
            <template class="" v-slot:item.outcome="{ item }">
                <EditableCell
                    :ref="`outcome-${item.behaviourIncidentId}`"
                    :edit-active="item.active"
                    :cell-id="item.behaviourIncidentId"
                    @edit-row="toggleEdit"
                    v-model="item.outcome"
                    :disabled="disabled"
                    label="Outcome"
                />
            </template>
            <!-- TODO: when upgrading, need to change below to this: [`item.editRecord`] -->
            <template class="" v-slot:item.editRecord="{ item }" v-if="!disabled">
                <div class="editable_table__edit-record pl-1 absolute text-center" :class="{ 'active-tr': item.active}">
                    <a
                        href="#"
                        @click.prevent="deleteHandler(item)"
                        v-if="!item.active && !isEditMode"
                        class="mx-1 bg-red-light"
                    >
                        <fa-icon icon="trash-alt" class="text-red" data-cy="deleteRecord" />
                    </a>
                    <a
                        href="#"
                        v-if="item.active"
                        @click.prevent="cancelHandler"
                        class="mr-1 bg-orange-light"
                    >
                        <fa-icon icon="times" class="text-orange"/>
                    </a>
                    <a
                        href="#"
                        v-if="item.active"
                        @click.prevent="saveHandler(item)"
                        class="hover:text-primary bg-primary-lightest"
                    >
                        <fa-icon icon="check" class="text-primary" />
                    </a>

                </div>
            </template>
            <template v-slot:footer v-if="!disabled">
                <v-btn
                    depressed
                    class="mt-2 v-btn--flat inline"
                    color="success"
                    @click="addIncident"
                >
                    <fa-icon icon="plus" class="mr-1 group-hover:text-primary-light" /> Add another
                </v-btn>
            </template>
        </v-data-table>
    </div>
</template>

<script>
import SelectCell from '@/components/partials/SelectCell'
import { mapState } from 'vuex'
import EditableCell from '@/components/partials/EditableCell'
import DatetimePickerCell from '@/components/partials/DatetimePickerCell'
import { FormEvents, FormRules } from '@/components/shared/mixins/formMixins'

export default {
    name: 'BehaviourIncidentTable',
    components: { DatetimePickerCell, EditableCell, SelectCell },
    mixins: [ FormRules, FormEvents ],
    props: {
        tableData: {
            type: Array,
            default: () => []
        },
        disabled: {
            type: Boolean,
            default: false
        },
        isView: {
            type: Boolean,
            default: false
        },
        isEditMode: {
            type: Boolean,
            default: false
        }
    },
    data() {
        return {
            refsToCheck: [
                'time',
                'incidentCode',
                'responseCode',
                'incidentReport',
                'behaviour',
                'outcome'
            ],
            menu2: false,
            tableDataArr: [],
            currentNewId: null,
            activeRowId: null,
            currentRow: null,
            emptyText: 'No behaviour incidents found',
            duration: 3000,
            type: '',
            message: '',
            itemsPerPage: 20,
            footerProps: {
                'items-per-page-options': [10, 20, 30, 40]
            },
            headers: [
                { text: 'Time', align: 'left', value: 'time', class: 'time--header w-90' },
                { text: 'Incident code', align: 'left', value: 'incidentCode', class: 'interaction-date--header w-130' },
                { text: 'Response code', align: 'left', value: 'responseCode', class: 'type--header w-130' },
                { text: 'Incident report', align: 'left', value: 'incidentReport', class: 'method--header w-200' },
                { text: 'Behaviour', align: 'left', value: 'behaviour', class: 'method--header' },
                { text: 'Outcome', align: 'left', value: 'outcome', class: 'method--header' },
                { text: '', align: 'left', value: 'editRecord', fixed: true},
            ],
            defaultBehaviourIncident: {
                time: '',
                incidentCode: '',
                responseCode: '',
                incidentReport: '',
                behaviour: '',
                outcome: '',
                active: true,
                isNew: true
            },
            timeFormat: 'h:mm a'
        }
    },
    mounted() {
        this.tableDataArr = [...this.tableData]
        if (!this.tableDataArr.length) {
            this.addIncident()
        }
    },
    methods: {
        rowClickHandler(obj, e) {
            this.currentRow = obj
            e.select(true)
        },
        addIncident() {
            // if we already have a new behaviour Incident, then we stop the function
            if (this.currentNewId) return

            this.currentNewId = Math.floor(Math.random() * 1000000) + 5000000
            this.activeRowId = this.currentNewId
            const tempBehaviourIncident = {
                behaviourIncidentId: this.currentNewId,
                ...this.defaultBehaviourIncident
            }
            this.tableDataArr.push(tempBehaviourIncident)
        },
        getDisplayValue(val, displayList) {
            if (!displayList || displayList.length === 0 || !val) return ''

            const displayValue = displayList.find(t => t.value.toLowerCase() === val.toLowerCase())
            return displayValue != null ? displayValue.label : ''
        },
        toggleEdit(val) {
            if (this.currentNewId) {
                this.showDiscardMessage(false)
                return false
            } else if (this.activeRowId && this.isActiveRowChanged()) {
                this.showDiscardMessage(true)
                return false
            }
            this.activeRowId = val
            this.activeRowObj = this.getActiveRow(this.tableDataArr)
        },
        showDiscardMessage(isEdit) {
            this.type = 'warning'
            this.message = `Please save/discard the ${isEdit ? 'editing' : 'new'} row before moving to other rows.`
            this.showMessage({duration: 1500})
        },
        isActiveRowChanged() {
            const fields = ['incidentCode', 'responseCode', 'incidentReport', 'behaviour', 'outcome']
            const changedObj = this.getActiveRow(this.tableDataArr)
            if (!changedObj) return false

            return fields.filter(field => this.activeRowObj[field] != changedObj[field]).length > 0
        },
        getActiveRow(data) {
            const activeRow = data.find((item) => {
                return item.behaviourIncidentId === this.activeRowId
            })
            return activeRow
        },
        validateInputs(id) {
            const forms = this.refsToCheck.reduce((arr, curr) => {
                arr.push(this.$refs[`${curr}-${id}`].validate())
                return arr
            }, [])
            return Promise.all(forms)
        },
        saveHandler() {
            this.validateInputs(this.activeRowId).then(values => {
                let isSuccess = true
                values.forEach(value => { if(!value) isSuccess = false })
                if(isSuccess) {
                    // Post back to db
                    // this.mutationCall(this.getActiveRow(this.tableDataArr), false)
                    this.activeRowId = null
                    this.currentNewId = null
                    this.activeRowObj = null
                }
            }).catch(err => {
                console.error('validation failed: ', err)
            })
        },
        filterTable(data) {
            return data.map(item => {
                let isActive = item.behaviourIncidentId === this.activeRowId
                return {
                    ...item,
                    active: isActive
                }
            })
        },
        resetTableRow() {
            // if an existing row is being edited
            if(!this.currentNewId) {
                // update the data on the row to the original values stored in activeRowObj
                this.tableDataArr = this.tableDataArr.map(item => {
                    if (item.behaviourIncidentId === this.activeRowId) {
                        return this.activeRowObj
                    }
                    return item
                })
            } else {
                // if its a new staff member, cancel the add by filtering them from the table arr
                this.tableDataArr = this.tableDataArr.filter(item => {
                    if (item.behaviourIncidentId !== this.activeRowId) return item
                })
            }
        },
        deleteHandler(item) {
            this.clearRowSelection()
            this.tableDataArr = this.tableDataArr.filter(td => {
                if (td.behaviourIncidentId !== item.behaviourIncidentId) return td
            })
        },
        cancelHandler() {
            this.resetTableRow()
            this.clearRowSelection()
        },
        clearRowSelection() {
            this.activeRowId = null
            this.currentNewId = null
            this.activeRowObj = null
        }
    },
    computed: {
        ...mapState({
            staticChoices: state => state.app.staticChoices
        }),
        incidentCodes() {
            return this.staticChoices['behaviour_incident.incident_codes']
        },
        responseCodes() {
            return this.staticChoices['behaviour_incident.response_codes']
        },
        incidentReports() {
            return this.staticChoices['behaviour_incident.incident_reports']
        }
    },
    watch: {
        tableData() {
            this.tableDataArr = this.tableData
        },
        activeRowId() {
            this.tableDataArr = this.filterTable(this.tableDataArr)
        },
        tableDataArr: {
            handler() {
                this.$emit('table-data-change', this.tableDataArr)
            },
            deep: true
        }
    }
}
</script>
