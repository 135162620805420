import Vue from 'vue'
import camelcaseKeys from 'camelcase-keys'
import staticChoices from '@/json/staticChoices.json'
import { getInstance } from '@/auth'
import { getOrCreateProvider } from '@/vue-apollo'
import GET_LOCATIONS from '@/graphql/queries/getLocations.gql'
import GET_LOCATION_PROGRAMS from '@/graphql/queries/getLocationPrograms.gql'
import GET_EPISODE_TYPES from '@/graphql/queries/getEpisodeTypes.gql'
import GET_PERMISSIONS from '@/graphql/queries/getPermissions.gql'
import GET_FORM_TYPES from '@/graphql/queries/getFormTypes.gql'
import UPDATE_EPISODE_FORM from '@/graphql/mutations/updateEpisodeForm.gql'
import UPDATE_FORM_ANSWER from '@/graphql/mutations/updateFormAnswer.gql'
import INSERT_EPISODE_FORM from '@/graphql/mutations/insertEpisodeForm.gql'
import { locationToItem, locationProgramToItem } from '@/utils/locationProgramHelper'
import { NOTHING_TO_DO } from '@/utils/constants'
import snakecaseKeys from 'snakecase-keys'
import {
    DELETE_EPISODE_FORM_BY_PK,
    UPDATE_EPISODE_FORM_DUE_DATE
} from '@/graphql/mutations/updateEpisodeFormQueries.gql'

const apolloClient = getOrCreateProvider()
let authService

export default {
    setClientId: ($store, clientId) => $store.commit('SET_CLIENT_ID', clientId),
    setCurrentEpisode: ($store, currentEpisode) => $store.commit('SET_CURRENT_EPISODE', currentEpisode),
    setEpisodeFormsForCopy: ($store, episodeForms) => $store.commit('SET_EPISODE_FORMS_FOR_COPY', episodeForms),
    setEpisodeFormToUpdate: ($store, episodeForm) => $store.commit('SET_EPISODE_FORM_TO_UPDATE', episodeForm),
    setEpisodeFormToDelete: ($store, episodeFormId) => $store.commit('SET_EPISODE_FORM_ID_TO_DELETE', episodeFormId),
    setUserIdToLink($store, userId) {
        return $store.commit('SET_USER_ID_TO_LINK', userId)
    },
    fetchStaticChoices(store) {
        authService = getInstance()
        if (authService.isAuthenticated && Object.keys(store.state.staticChoices).length === 0){
            // if we are not logged in then get the default static choices - which should only have the user title
            const data = staticChoices
            let newChoices = {}
            const keys = Object.keys(data)
            for (let key of keys) {
                newChoices[key] = data[key].map((item) => {
                    return {
                        value: item[0],
                        label: item[1],
                        text: item[1],
                    }
                })
            }
            store.commit('SET_STATIC_OBJECT', newChoices)
        }
    },
    fetchLocations(store) {
        authService = getInstance()
        if (authService.isAuthenticated && Object.keys(store.state.locations).length === 0){
            // if we are not logged in then get the default static choices - which should only have the user title
            apolloClient.defaultClient.query({
                query: GET_LOCATIONS,
            })
                .then(({ data: { locations } }) => {
                    locations = camelcaseKeys(locations || [], {deep: true})

                    store.commit('SET_LOCATIONS', locations)
                    store.commit('SET_LOCATION_ITEMS', locations.map(locationToItem))
                })
                .catch((err) => {
                    console.log('something went wrong', err)
                    // if we didn't get the permissions then it means we can't view this practice
                    Vue.router.push({name: 'client-list'})
                })
        }
    },
    fetchSiteSections(store) {
        authService = getInstance()
        if (authService.isAuthenticated && Object.keys(store.state.siteSections).length === 0){
            // if we are not logged in then get the default static choices - which should only have the user title
            apolloClient.defaultClient.query({
                query: GET_LOCATION_PROGRAMS,
            })
                .then(({ data: { locationPrograms } }) => {
                    locationPrograms = camelcaseKeys(locationPrograms, { deep: true })

                    store.commit('SET_SITE_SECTIONS', locationPrograms.map(locationProgramToItem))
                })
                .catch((err) => {
                    console.log('something went wrong', err)
                    // if we didn't get the permissions then it means we can't view this practice
                    Vue.router.push({name: 'client-list'})
                })
        }
    },
    fetchEpisodeTypes(store) {
        authService = getInstance()
        if (authService.isAuthenticated && Object.keys(store.state.episodeTypes).length === 0){
            // if we are not logged in then get the default static choices - which should only have the user title
            apolloClient.defaultClient.query({
                query: GET_EPISODE_TYPES,
            })
                .then(({ data: { episodeTypes } }) => {
                    episodeTypes = camelcaseKeys(episodeTypes, { deep: true })

                    store.commit('SET_EPISODE_TYPES', episodeTypes)
                })
                .catch((err) => {
                    console.log('something went wrong', err)
                    // if we didn't get the permissions then it means we can't view this practice
                    Vue.router.push({name: 'client-list'})
                })
        }
    },
    clearPermissions(state) {
        state.commit('SET_PERMISSIONS', null)
    },
    setPermissions({ state, commit }, { vm }) {
        // need to put permissions here
        const role = state.loggedInUser?.role || ''
        authService = getInstance()

        return apolloClient.defaultClient.query({
            query: GET_PERMISSIONS,
            variables: {
                tenantId: authService.tenantId
            }
        })
        .then((response) => {
            const permissions = camelcaseKeys(response.data.permissions, { deep: true })
            const filteredPerms = permissions.filter(p => p.role === role) || []
            commit('SET_PERMISSIONS', permissions)
            vm.$ability.update(filteredPerms)
            return
        })
        .catch((err) => {
            console.log('something went wrong', err)
            // if we didn't get the permissions then it means we can't view this practice
            Vue.router.push({name: 'client-list'})
        })
    },
    setFormTypes({ commit }, { tenantId }) {
        // need to put form type here
        console.log('getting form types')
        return apolloClient.defaultClient.query({
            query: GET_FORM_TYPES,
            variables: {
                tenantId
            }
        })
        .then((response) => {
            console.log('we have form types!', response)
            const formTypes = camelcaseKeys(response.data.formTypes, { deep: true })
            const formType = formTypes.filter((formType) => formType.type === 'BRIEF_ASSESSMENT')[0]
            console.log('setting formType to', formType)
            commit('SET_FORM_TYPE', formType)
        })
        .catch((err) => {
            console.log('something went wrong', err)
        })
    },
    setPracticeId($store, { practiceId }) {
        if (practiceId !== $store.state.practiceId) {
            $store.commit('SET_STAFF_TOTAL', 0)
            $store.commit('SET_DOCS_TOTAL', 0)

            $store.commit('SET_STAFF_FIRST_VIEW', true)
            $store.commit('SET_DOCUMENTS_FIRST_VIEW', true)
            $store.commit('SET_ACCREDITATION_FIRST_VIEW', true)
        }

        $store.commit('SET_PRACTICE_ID', practiceId)
    },
    setLoggedInUser($store, loggedInUser) {
        const locations = []
        const locationIds = []
        const locationProgramIds = []
        const locationItems = []
        const locationProgramItems = []

        loggedInUser.locationPrograms = loggedInUser.locationPrograms.map(({locationProgram}) => locationProgram)
        loggedInUser.locationPrograms.map(locationProgram => {
            const {locationProgramId, locationId, location} = locationProgram
            if (locationIds.indexOf(locationId) <= -1) {
                locations.push(location)
                locationIds.push(locationId)
                locationItems.push(locationToItem(location))
            }
            locationProgramIds.push(locationProgramId)
            locationProgramItems.push(locationProgramToItem(locationProgram))
        })

        loggedInUser.locations = locations
        loggedInUser.locationIds = locationIds
        loggedInUser.locationProgramIds = locationProgramIds
        loggedInUser.locationItems = locationItems
        loggedInUser.locationProgramItems = locationProgramItems

        $store.commit('SET_LOGGED_IN_USER', loggedInUser)
    },
    createEpisodeForm({ commit, dispatch, state}) {
        console.log('commit, state, state', commit, dispatch, state)
        let promise = Promise.resolve(NOTHING_TO_DO)
        if (state.formToSave && Object.keys(state.formToSave).length) {
            let message = null, type = null
            // if we are not logged in then get the default static choices - which should only have the user title
            const { episodeFormId, ...formToSave} = state.formToSave
            promise = apolloClient.defaultClient.mutate({
                mutation: INSERT_EPISODE_FORM,
                variables: {
                    episodeForms: [snakecaseKeys(formToSave, {deep: true})]
                }
            }).then(async ({ data: { episodeForm } }) => {
                const convertdEpisodeForm = camelcaseKeys(episodeForm, { deep: true })
                // need to change the below anyway since we have to update the episode form instead

                message = 'Created form answer successfully'
                type = 'success'
                return convertdEpisodeForm
            }).catch(error => {
                message = 'Creating form failed'
                type = 'error'
            }).finally(async () => {
                await commit('SET_MESSAGE_RESULT', {
                    message: message,
                    type: type
                })
            })
        }
        return promise
    },
    saveEpisodeForm({ commit, dispatch, state}) {
        let promise = Promise.resolve(NOTHING_TO_DO)
        if (state.formToSave && Object.keys(state.formToSave).length) {
            let message = null, type = null
            // if we are not logged in then get the default static choices - which should only have the user title
            const { episodeFormId, ...formToSave} = state.formToSave
            promise = apolloClient.defaultClient.mutate({
                mutation: UPDATE_EPISODE_FORM,
                variables: {
                    episodeFormId: episodeFormId,
                    episodeForm: snakecaseKeys(formToSave, {deep: true})
                }
            }).then(async ({ data: { episodeForm } }) => {
                const convertdEpisodeForm = camelcaseKeys(episodeForm, { deep: true })
                // need to change the below anyway since we have to update the episode form instead

                message = 'Saved form answer successfully'
                type = 'success'
                return convertdEpisodeForm
            }).catch(error => {
                message = 'Saving form failed'
                type = 'error'
            }).finally(async () => {
                await commit('SET_MESSAGE_RESULT', {
                    message: message,
                    type: type
                })
            })
        }
        return promise
    },
    async updateFormAnswer({ commit, dispatch, state}) {
        console.log('commit, state, state', commit, dispatch, state)
        let promise = Promise.resolve(NOTHING_TO_DO)
        if (state.formToSave && Object.keys(state.formToSave).length) {
            let message = null, type = null
            // if we are not logged in then get the default static choices - which should only have the user title
            const { episodeFormId, dueDate, ...formToSave} = state.formToSave
            try {
                const { data: { response } } = await apolloClient.defaultClient.mutate({
                    mutation: UPDATE_FORM_ANSWER,
                    variables: {
                        form_answer_id: formToSave.formAnswerId,
                        form_id: formToSave.formId,
                        form_set_id: formToSave.formSetId,
                        form_version: formToSave.formVersion,
                        tenant_id: formToSave.tenantId,
                        completed_by_id: formToSave.completedById,
                        created_at: formToSave.dateCompleted,
                    }
                })

                const { data: { episodeForm } } = await apolloClient.defaultClient.mutate({
                    mutation: UPDATE_EPISODE_FORM_DUE_DATE,
                    variables: { episodeFormId, dueDate }
                })

                if (response?.code === 200) {
                    message = 'Saved form answer successfully'
                    type = 'success'
                } else {
                    message = 'Something went wrong'
                    type = 'error'
                }

                return response?.code
            } catch (error) {
                message = 'Saving form failed'
                type = 'error'
            } finally {
                await commit('SET_MESSAGE_RESULT', {
                    message: message,
                    type: type
                })
            }
        }
        return NOTHING_TO_DO
    },
    async deleteEpisodeForm({ commit, dispatch, state}) {
        if (state.episodeFormIdToDelete ) {
            let message = null, type = null
            // if we are not logged in then get the default static choices - which should only have the user title
            const { episodeFormIdToDelete: episodeFormId } = state
            try {
                const { data: { deletedEpisode } } = await apolloClient.defaultClient.mutate({
                    mutation: DELETE_EPISODE_FORM_BY_PK,
                    variables: { episodeFormId }
                })
                const response = camelcaseKeys(deletedEpisode, { deep: true })

                const hasBeenDeleted = response?.episodeFormId === episodeFormId
                if (hasBeenDeleted) {
                    message = 'Deleted episode form successfully'
                    type = 'success'
                } else {
                    message = 'Something went wrong'
                    type = 'error'
                }

                return hasBeenDeleted
            } catch (error) {
                message = 'Deleting form failed'
                type = 'error'
            } finally {
                await commit('SET_MESSAGE_RESULT', {
                    message: message,
                    type: type
                })
            }
        }
        return NOTHING_TO_DO
    },
    openModal({ commit }, { payload, modalName }) {
        commit('MODAL_IS_VISIBLE', true)
        commit('MODAL_DATA', payload)
        commit('MODAL_COMPONENT_NAME', modalName)
    },
    closeModal({ commit }) {
        commit('MODAL_IS_VISIBLE', false)
        commit('MODAL_DATA', {})
        commit('MODAL_COMPONENT_NAME', '')
    }
}
